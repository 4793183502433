import type {
	InvestmentSummary,
	ReviewTicker,
	UserCompositionColumnOrdering,
	UserCompositionColumnPreference,
	UserCompositionColumnPreferencePreferenceTypeEnum,
	UserEnhancementCompositionColumnOrdering,
	UserEnhancementCompositionColumnPreference,
	UserEnhancementCompositionColumnPreferencePreferenceTypeEnum,
} from "$root/api/api-gen";
import {
	IntegrationsControllerApiFactory,
	InvestmentEnhancementExportControllerApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentExportControllerApiFactory,
	InvestmentExportConverterType,
	InvestmentReportsControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { CustomLabels } from "$root/components/CustomLabels";
import { InfoDelta } from "$root/components/InfoDelta";
import { MarkdownRenderer } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { ColumnMetadata, spawnCustomizeColumnsDialog } from "$root/components/tables-extra/CustomizeColumns";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import type { PortfolioAlert } from "$root/functional-areas/portfolio/alerts";
import { filterMapAlerts } from "$root/functional-areas/portfolio/alerts";
import { useUserValue } from "$root/functional-areas/user";
import useCompositionDownload from "$root/hooks/useCompositionDownload";
import { useLocaleFormatters } from "$root/localization/hooks";
import { platformToast } from "$root/notification-system/toast";
import { useCompositionColumn } from "$root/pages/PortfolioDetails/PortfolioComposition/columns";
import {
	PortfolioQueryWidgetBase,
	WidgetStatus,
	portfolioWidgetMissingDataReason,
} from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { usePortfolioStudioTableSettings } from "$root/pages/PortfoliosStudio/portfolio-studio-table-settings";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { actionsColumn } from "$root/ui-lib/interactive-collections/common-table-actions";
import { builtInSortFnFor } from "$root/utils/collections";
import { downloadContentDisposition } from "$root/utils/files";
import type { ContextContent } from "$root/utils/react-extra";
import { useUpdatedRef, withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { Action, OrderBy, TableColumn } from "@mdotm/mdotui/components";
import {
	AutoTooltip,
	Button,
	DropdownMenu,
	DropdownMenuActionButton,
	Icon,
	Table,
	TableV2,
	TooltipContent,
	sortRows,
} from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

const defaultCompositionOrderBy: OrderBy[] = [{ columnName: "weight", direction: "desc" }];

const Composition = (props: ContextContent<typeof PortfolioContext>) => {
	const { enhanced, reportsExecution, portfolio, reportExcutionCounter } = props;
	const uuid = props.portfolio?.uuid;
	const { t } = useTranslation();

	useWidgetOptions(
		() => ({
			title: t("COMPOSITION.TITLE"),
		}),
		[t],
	);

	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const investmentEnhancementReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const query = useQueryNoRefetch(["compositionProvider", uuid, enhanced, portfolio?.status, reportExcutionCounter], {
		queryFn: async () => {
			if (!uuid) {
				return {
					data: undefined,
					widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "Composition"),
				};
			}

			const data = await axiosExtract(
				props.enhanced
					? investmentEnhancementReportApi.getInvestmentComposition1(uuid)
					: investmentReportApi.getInvestmentComposition(uuid),
			);
			// PROPOSAL: we can either remap the response here or ask the BE to give us the
			// appropriate data status (letting us delete portfolioWidgetMissingDataReason)
			if (data.composition) {
				return {
					data: {
						composition: data.composition,
						userCompositionColumnOrderingPreferences: !enhanced
							? (await axiosExtract(portfolioStudioPreferencesApi.getUserCompositionColumnOrderingPreferences()))
									.userCompositionColumnPreferences
							: [],
						userEnhancementCompositionColumnOrderingPreferences: enhanced
							? (
									await axiosExtract(
										portfolioStudioPreferencesApi.getUserEnhancementCompositionColumnOrderingPreferences(),
									)
							  ).userEnhancementCompositionColumnPreferences
							: [],
					},
					widgetStatus: WidgetStatus.READY,
				};
			}

			return {
				data: undefined,
				widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "Composition"),
			};
		},
	});

	return (
		<PortfolioQueryWidgetBase query={query}>
			{(
				{ composition, userCompositionColumnOrderingPreferences, userEnhancementCompositionColumnOrderingPreferences },
				{ refetch },
			) => (
				<CompositionInner
					ctx={props}
					composition={composition}
					columnPreferences={{
						current: userCompositionColumnOrderingPreferences ?? [],
						enhance: userEnhancementCompositionColumnOrderingPreferences ?? [],
					}}
					onAsyncChangeColumnPreferences={async (columns) => {
						if (enhanced) {
							const payload = {
								userEnhancementCompositionColumnPreferences: columns.map((preference) => ({
									enabled: preference.visible,
									preferenceType: preference.id as UserEnhancementCompositionColumnPreferencePreferenceTypeEnum,
								})),
							} satisfies UserEnhancementCompositionColumnOrdering;
							await portfolioStudioPreferencesApi.setUserEnhancementCompositionColumnOrderingPreferences(payload);
						} else {
							const payload = {
								userCompositionColumnPreferences: columns.map((preference) => ({
									enabled: preference.visible,
									preferenceType: preference.id as UserCompositionColumnPreferencePreferenceTypeEnum,
								})),
							} satisfies UserCompositionColumnOrdering;
							await portfolioStudioPreferencesApi.setUserCompositionColumnOrderingPreferences(payload);
						}

						await refetch();
					}}
				/>
			)}
		</PortfolioQueryWidgetBase>
	);
};

const CompositionInner = (props: {
	ctx: ContextContent<typeof PortfolioContext>;
	composition: ReviewTicker[];
	columnPreferences: {
		current: UserCompositionColumnPreference[];
		enhance: UserEnhancementCompositionColumnPreference[];
	};
	onAsyncChangeColumnPreferences: (
		columns: ColumnMetadata<
			UserEnhancementCompositionColumnPreferencePreferenceTypeEnum | UserCompositionColumnPreferencePreferenceTypeEnum
		>[],
	) => Promise<void>;
}) => {
	const { t } = useTranslation();
	const user = useUserValue();
	const { composition, ctx } = props;
	const { enhanced, portfolio, currentTab, requestEnhance, requestOptimize, alerts } = ctx;

	const exportApi = useApiGen(InvestmentExportControllerApiFactory);
	const exportEnhancedApi = useApiGen(InvestmentEnhancementExportControllerApiFactory);
	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);

	const { downloadInvestmentConversion } = useCompositionDownload();

	const exportPortfolioComposition = async (downloadTarget: "composition" | "trades") => {
		const response = enhanced
			? downloadTarget === "composition"
				? await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "FULL_COMPOSITION", {
						responseType: "blob",
				  })
				: await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "TRADES_ONLY", { responseType: "blob" })
			: await exportApi.exportComposition(portfolio!.uuid!, { responseType: "blob" });

		downloadContentDisposition(response);
		trackMixPanelEvent("Portfolio", {
			Type: "Export",
			Area: downloadTarget,
			ID: portfolio!.uuid!,
		});
	};

	const customExportComposition = async (
		exportFormat: InvestmentExportConverterType,
		uuid: string,
		enhance?: boolean,
	) => {
		if (exportFormat === InvestmentExportConverterType.EasimTemplateConverter) {
			const composition = await axiosExtract(integrationsApi.exportInvestment(uuid, enhance));
			await axiosExtract(integrationsApi.convertTo(exportFormat, [composition], true));
			platformToast({
				children: "Sphere has taken over your request",
				severity: "info",
				icon: "Dowload",
			});
			return;
		}

		await downloadInvestmentConversion(
			exportFormat,
			uuid,
			enhance,
			// exportFormat === InvestmentExportConverterType.EasimTemplateConverter,
		);
		// if (exportFormat !== InvestmentExportConverterType.EasimTemplateConverter) {
		// } else {
		// 	platformToast({
		// 		children: "Sphere has taken over your request",
		// 		severity: "info",
		// 		icon: "Dowload",
		// 	});
		// }
		trackMixPanelEvent("Portfolio", {
			Type: "Export",
			Area: "composition",
			ID: portfolio!.uuid!,
		});
	};

	const exportPortfolioCompositionRef = useUpdatedRef(exportPortfolioComposition);
	const exportCustomCompositionRef = useUpdatedRef(customExportComposition);

	const formatters = useLocaleFormatters();

	useWidgetOptions(
		() => ({
			// alertsActive: currentTab !== 1,
			// alerts: filterMapAlerts({ t, ...formatters, requestOptimize, requestEnhance }, "Composition", alerts),
			actionHeader: function Download() {
				return (
					<div style={{ display: "flex", flexDirection: "row" }} className="space-x-2">
						<DropdownMenu
							trigger={({ innerRef, open, ...forward }) => (
								<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
									<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
								</button>
							)}
							actions={[
								{
									icon: "xls",
									onClickAsync: async () => {
										await exportPortfolioCompositionRef.current("composition");
									},
									label: portfolio?.reference ? "Target portfolio composition" : "Portfolio composition",
								},
								portfolio?.status === "PROPOSAL_READY" &&
									enhanced && {
										icon: "xls",
										onClickAsync: async () => {
											await exportPortfolioCompositionRef.current("trades");
										},
										label: t("COMPOSITION.DOWNLOAD_TRADES_TITLE"),
									},
							]}
						/>
						{/* <InfoTooltip>{t("COMPOSITION.TOOLTIP")}</InfoTooltip> */}
					</div>
				);
			},
			title: t("COMPOSITION.TITLE"),
		}),
		[enhanced, exportPortfolioCompositionRef, portfolio?.reference, portfolio?.status, t],
	);

	return (
		<CompositionTable
			{...ctx}
			rows={composition}
			columnPreferences={props.columnPreferences}
			onAsyncChangeColumnPreferences={props.onAsyncChangeColumnPreferences}
		/>
	);
};

const CompositionTable = (props: {
	portfolio: InvestmentSummary | null;
	enhanced: boolean;
	alerts: PortfolioAlert[];
	rows: ReviewTicker[];
	columnPreferences: {
		current: UserCompositionColumnPreference[];
		enhance: UserEnhancementCompositionColumnPreference[];
	};
	onAsyncChangeColumnPreferences: (
		columns: ColumnMetadata<
			UserEnhancementCompositionColumnPreferencePreferenceTypeEnum | UserCompositionColumnPreferencePreferenceTypeEnum
		>[],
	) => Promise<void>;
}) => {
	const { push } = useTypedNavigation();
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();
	const user = useUserValue();
	const { columnPreferences, enhanced, onAsyncChangeColumnPreferences } = props;

	const {
		portfolioCompositionOrderByName,
		setPortfolioCompositionOrderByName,
		portfolioCompositionEnhanceOrderByName,
		setPortfolioCompositionEnhanceOrderByName,
	} = usePortfolioStudioTableSettings();

	// });

	const edit =
		props.portfolio?.status !== "PROPOSAL_READY" &&
		props.portfolio?.status !== "ERROR" &&
		props.portfolio?.status !== "CALCULATING" &&
		aclByArea.portfolio.canEditComposition(user.id, [
			{ userId: user.id, permissions: props.portfolio?.richAcl?.currentUserPermissions },
		]);

	// const columns = useMemo<Array<TableColumn<ReviewTicker>>>(
	// 	() =>
	// 		props.enhanced
	// 			? [
	// 					{
	// 						relativeWidth: 40,
	// 						header: t("COMPOSITION.INSTRUMENT"),
	// 						sortFn: builtInSortFnFor("instrument"),
	// 						name: "instrument",
	// 						content: ({ instrument, tickerId, commentary, commentaryCreator }) => (
	// 							<AutoTooltip
	// 								align="startToStart"
	// 								disabled={!commentary}
	// 								trigger={({ innerRef }) => (
	// 									<div className="flex items-center flex-nowrap w-full pr-4" ref={innerRef}>
	// 										<div
	// 											className={overrideClassName("whitespace-nowrap text-ellipsis overflow-hidden", {
	// 												underline: Boolean(commentary),
	// 											})}
	// 										>
	// 											{instrument ?? ""}
	// 										</div>
	// 										{props.alerts.some(
	// 											(a) =>
	// 												(a.type === "InstrumentsChangesAlertCheck" ||
	// 													a.type === "InstrumentsChangesChildAlertCheck") &&
	// 												a.value.tickerId === tickerId,
	// 										) && (
	// 											<span
	// 												className="block rounded-full px-1 ml-1 py-0.5 bg-[#00AEEF] text-white uppercase"
	// 												style={{ fontSize: 8 }}
	// 											>
	// 												{t("UPDATED")}
	// 											</span>
	// 										)}
	// 									</div>
	// 								)}
	// 							>
	// 								{commentaryCreator === "SPHERE" ? (
	// 									<TooltipContent>
	// 										<MarkdownRenderer>{commentary ?? ""}</MarkdownRenderer>
	// 									</TooltipContent>
	// 								) : (
	// 									commentary
	// 								)}
	// 							</AutoTooltip>
	// 						),
	// 					},
	// 					{
	// 						relativeWidth: 11,
	// 						header: t("COMPOSITION.IDENTFIER"),
	// 						sortFn: builtInSortFnFor("alias"),
	// 						name: "alias",
	// 						content: ({ identifier }) => identifier,
	// 					},
	// 					{
	// 						relativeWidth: 10,
	// 						header: t("COMPOSITION.ASSET_CLASS"),
	// 						sortFn: builtInSortFnFor("assetClass"),
	// 						name: "assetClass",
	// 						content: ({ assetClass }) => assetClass,
	// 					},
	// 					{
	// 						relativeWidth: 15,
	// 						header: t("COMPOSITION.MICRO_ASSET_CLASS"),
	// 						sortFn: builtInSortFnFor("microAssetClass"),
	// 						name: "microAssetClass",
	// 						content: ({ microAssetClass }) => microAssetClass,
	// 					},
	// 					{
	// 						relativeWidth: 8,
	// 						header: t("COMPOSITION.CURRENT_WEIGHT"),
	// 						sortFn: builtInSortFnFor("previousWeight"),
	// 						name: "previousWeight",
	// 						cellClassList: "tabular-nums",
	// 						content: ({ previousWeight }) => `${formatNumber(previousWeight ?? 0)}%`,
	// 						align: "end",
	// 					},
	// 					{
	// 						relativeWidth: 8,
	// 						header: t("COMPOSITION.ENHANCED_WEIGHT"),
	// 						sortFn: builtInSortFnFor("weight"),
	// 						name: "weight",
	// 						cellClassList: "tabular-nums",
	// 						content: ({ weight }) => `${formatNumber(weight ?? 0)}%`,
	// 						align: "end",
	// 					},
	// 					{
	// 						relativeWidth: 10,
	// 						header: t("COMPOSITION.DIFFERENCE"),
	// 						align: "end",
	// 						cellClassName: "pr-1",
	// 						content: (instrument) => {
	// 							const deltaWeight = Number((instrument.weight ?? 0) - (instrument.previousWeight ?? 0)).toFixed(2);
	// 							return <InfoDelta diff={Number(deltaWeight) ?? 0} enh={instrument.weight ?? 0} />;
	// 						},
	// 						sortFn: (rowa, rowb) => {
	// 							const deltaA = (rowa.weight ?? 0) - (rowa.previousWeight ?? 0);
	// 							const deltaB = (rowb.weight ?? 0) - (rowb.previousWeight ?? 0);

	// 							if (deltaA > deltaB) {
	// 								return 1;
	// 							}

	// 							if (deltaA < deltaB) {
	// 								return -1;
	// 							}

	// 							return 0;
	// 						},
	// 						name: "difference",
	// 					},
	// 					{
	// 						header: props.portfolio?.scoreIdentifier ? (
	// 							<CustomLabels labelKey={props.portfolio?.scoreIdentifier} fallback={t("SCORE")} />
	// 						) : (
	// 							t("SCORE")
	// 						),
	// 						content: ({ score }) => (score ? formatNumber(score) : ""),
	// 						sortFn: builtInSortFnFor("score"),
	// 						name: "score",
	// 						relativeWidth: 10,
	// 						hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
	// 					},
	// 			  ]
	// 			: [
	// 					{
	// 						relativeWidth: 30,
	// 						header: t("COMPOSITION.INSTRUMENT"),
	// 						sortFn: builtInSortFnFor("instrument"),
	// 						name: "instrument",
	// 						content: ({ instrument, tickerId, commentary, commentaryCreator }) => (
	// 							<AutoTooltip
	// 								align="startToStart"
	// 								disabled={!commentary}
	// 								trigger={({ innerRef }) => (
	// 									<div className="flex flex-row items-center flex-nowrap w-full pr-4" ref={innerRef}>
	// 										<div
	// 											className={overrideClassName("whitespace-nowrap text-ellipsis overflow-hidden", {
	// 												underline: Boolean(commentary),
	// 											})}
	// 										>
	// 											{instrument ?? ""}
	// 										</div>
	// 										{props.alerts.some(
	// 											(a) =>
	// 												(a.type === "InstrumentsChangesAlertCheck" ||
	// 													a.type === "InstrumentsChangesChildAlertCheck") &&
	// 												a.value.tickerId === tickerId,
	// 										) && (
	// 											<span
	// 												className="block rounded-full px-1 ml-1 py-0.5 bg-[#00AEEF] text-white uppercase"
	// 												style={{ fontSize: 8 }}
	// 											>
	// 												{t("UPDATED")}
	// 											</span>
	// 										)}
	// 									</div>
	// 								)}
	// 							>
	// 								{commentaryCreator === "SPHERE" ? (
	// 									<TooltipContent>
	// 										<MarkdownRenderer>{commentary ?? ""}</MarkdownRenderer>
	// 									</TooltipContent>
	// 								) : (
	// 									commentary
	// 								)}
	// 							</AutoTooltip>
	// 						),
	// 					},
	// 					{
	// 						relativeWidth: 15,
	// 						header: t("COMPOSITION.IDENTFIER"),
	// 						sortFn: builtInSortFnFor("alias"),
	// 						name: "alias",
	// 						content: ({ identifier }) => identifier,
	// 					},
	// 					{
	// 						relativeWidth: 10,
	// 						header: t("COMPOSITION.ASSET_CLASS"),
	// 						sortFn: builtInSortFnFor("assetClass"),
	// 						name: "assetClass",
	// 						content: ({ assetClass }) => assetClass,
	// 					},
	// 					{
	// 						relativeWidth: 25,
	// 						header: t("COMPOSITION.MICRO_ASSET_CLASS"),
	// 						sortFn: builtInSortFnFor("microAssetClass"),
	// 						name: "microAssetClass",
	// 						content: ({ microAssetClass }) => microAssetClass,
	// 					},
	// 					{
	// 						relativeWidth: 10,
	// 						header: t("COMPOSITION.WEIGHT"),
	// 						sortFn: builtInSortFnFor("weight"),
	// 						name: "weight",
	// 						align: "end",
	// 						cellClassList: "tabular-nums",
	// 						content: ({ weight }) => `${formatNumber(weight ?? 0)}%`,
	// 					},
	// 					{
	// 						header: props.portfolio?.scoreIdentifier ? (
	// 							<CustomLabels labelKey={props.portfolio?.scoreIdentifier} fallback={t("SCORE")} />
	// 						) : (
	// 							t("SCORE")
	// 						),
	// 						content: ({ score }) => (score ? formatNumber(score) : ""),
	// 						sortFn: builtInSortFnFor("score"),
	// 						name: "score",
	// 						relativeWidth: 10,
	// 						hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
	// 					},
	// 			  ],
	// 	[props.enhanced, props.portfolio?.scoreIdentifier, props.alerts, t, user, formatNumber],
	// );

	const customizableColumns = (enhanced ? columnPreferences.enhance : columnPreferences.current ?? []).map((c) => ({
		label: c.preferenceType ? t(`TABLE.HEADERS.${c.preferenceType}`) : "-",
		id: c.preferenceType!,
		visible: c.enabled ?? false,
		disabled: c.preferenceType === "INSTRUMENT_NAME",
		hidden: c.preferenceType === "INSTRUMENT_NAME",
	}));

	const columns = [
		...useCompositionColumn(
			enhanced ? columnPreferences.enhance : columnPreferences.current,
			props.portfolio!,
			props.rows,
			props.alerts,
		),
		actionsColumn({
			onSettingsClick: () =>
				spawnCustomizeColumnsDialog({
					columns: customizableColumns,
					onSubmitAsync: onAsyncChangeColumnPreferences,
				}),
			dropdownActions: [],
		}),
	];

	return (
		<div className="h-full flex flex-col">
			<div className="grow mb-4 h-full">
				{/* <Table
					visibleRows={edit ? 8 : 9}
					columns={columns}
					rows={props.rows}
					noDataText={t("COMPOSITION.NO_COMPOSITION")}
					orderBy={defaultCompositionOrderBy}
				/> */}
				<TableV2.BaseHScrollTable
					columns={columns}
					rows={sortRows({
						rows: props.rows,
						columns,
						orderByArr: enhanced ? portfolioCompositionEnhanceOrderByName : portfolioCompositionOrderByName,
					})}
					pinnedColumns={[
						{ name: "instrument", side: "left" },
						{ name: "settings-action", side: "right" },
					]}
					orderBy={enhanced ? portfolioCompositionEnhanceOrderByName : portfolioCompositionOrderByName}
					onOrderByChange={enhanced ? setPortfolioCompositionEnhanceOrderByName : setPortfolioCompositionOrderByName}
				/>
			</div>
			{edit && (
				<div className="flex justify-end shrink">
					<Button
						size="small"
						palette="primary"
						onClick={() => push("EditPortfolioCompositionPage", { portfolioUid: props.portfolio!.uuid! })}
					>
						{t("EDIT_COMPOSITION.EDIT_COMPOSITION_BUTTON")}
					</Button>
				</div>
			)}
		</div>
	);
};

export default withContext(PortfolioContext)(Composition);
