import type { MutableArrayLike } from "@mdotm/mdotui/utils";
import type { Maybe } from "./types";

/**
 * Return an array containing the unique items found in the input one.
 * @param array An array.
 * @param equalityComparator A function that, given two items, returns if they are equal.
 */
export function dedup<T>(array: T[], equalityComparator: (a: T, b: T) => boolean = (a, b) => a === b): T[] {
	const dedupedArray: T[] = [];
	for (const itemInArray of array) {
		let present = false;
		for (const itemInDedup of dedupedArray) {
			if (equalityComparator(itemInArray, itemInDedup)) {
				present = true;
				break;
			}
		}
		if (!present) {
			dedupedArray.push(itemInArray);
		}
	}

	return dedupedArray;
}

/**
 * Return an array of sub-arrays of the items contained in the input one.
 * @param arr An array.
 * @param chunkLength The maximum size of a chunk.
 */
export function chunk<T>(arr: T[], chunkLength: number): T[][] {
	const chunked = new Array<T[]>(Math.ceil(arr.length / chunkLength));
	for (let i = 0; i < chunked.length; i++) {
		chunked[i] = arr.slice(i * chunkLength, i * chunkLength + chunkLength);
	}

	return chunked;
}

/**
 * Return a key-value object containing a partition of the input array into subgroups.
 * @param arr An array.
 * @param getGroup A function that, given an item, returns a string corresponding to its group.
 */
export function groupBy<TGroupNames extends string, TValue>(
	arr: TValue[],
	getGroup: (v: TValue, i: number, arr: TValue[]) => TGroupNames,
): Partial<{ [K in TGroupNames]: TValue[] }> {
	const groups = {} as Partial<{
		[K in TGroupNames]: TValue[];
	}>;

	for (let i = 0; i < arr.length; i++) {
		const key = getGroup(arr[i], i, arr);
		const appendTarget = (groups[key] || []) as TValue[];
		appendTarget.push(arr[i]);
		groups[key] = appendTarget;
	}

	return groups;
}

/**
 * Maps all the items contained in the arrays of a key-value object.
 *
 * @param groups An object whose values are arrays.
 * @param map A function will be applied to the items in the input arrays.
 */
export function groupMap<TKeys extends string, TInputValue, TOutputValue>(
	groups: { [K in TKeys]: TInputValue[] },
	map: (v: TInputValue, i: number, groupArr: TInputValue[], groupName: TKeys) => TOutputValue,
): { [K in TKeys]: TOutputValue[] } {
	return Object.fromEntries(
		Object.entries(groups).map(([groupName, groupValues]) => [
			groupName,
			(groupValues as TInputValue[] | undefined)?.map((v, i, arr) => map(v, i, arr, groupName as TKeys)),
		]),
	) as { [K in TKeys]: TOutputValue[] };
}

/**
 * Sort an array using a score function.
 * @param array An array.
 * @param scoreFn A function that returns a number indicating a score. Values with the higher scores will
 * appear at the beginning of the output array, and viceversa.
 */
export function sortByScore<TValue>(array: TValue[], scoreFn: (obj: TValue) => number): TValue[] {
	return array
		.map((item) => ({ item, score: scoreFn(item) }))
		.sort((a, b) => b.score - a.score) // it's ok, we are mutating a temporary array.
		.map(({ item }) => item);
}

/**
 * Filter and then sort an array using a score function.
 * @param array An array.
 * @param scoreFn A function that returns a number indicating a score. Values with the higher scores will
 * appear at the beginning of the output array, and viceversa.
 * @param minScore The minimum score (inclusive) an item must have to be included in the output.
 */
export function filterSortByScore<TValue>(
	array: TValue[],
	scoreFn: (obj: TValue) => number,
	minScore: number,
): TValue[] {
	return array
		.map((item) => ({ item, score: scoreFn(item) }))
		.filter((item) => item.score >= minScore)
		.sort((a, b) => b.score - a.score) // it's ok, we are mutating a temporary array.
		.map(({ item }) => item);
}

/**
 * A sort function is a binary function that takes two arguments and
 * returns a number, usually -1, 0 or 1. This number can then be used
 * by a sorting algorithm to rearrange the items in a collection.
 */
export type SortFn<T> = (a: T, b: T) => number;
/**
 * Invert a sort function by swapping the order of the arguments.
 * @param fn a {@link SortFn<T> sort function}.
 */
export function invertSortFn<T>(fn: SortFn<T>): SortFn<T> {
	return (a, b) => fn(b, a);
}

/**
 * Return a number that indicates whether a comes before, after or is equal to b, based on
 * multiple {@link SortFn<T>}s. The provided {@link SortFn<T>}s are applied until one of them
 * returns a number other than 0.
 * @param a first item.
 * @param b second item.
 * @param sortFns an array of {@link SortFn<T>}s.
 */
export function multiSort<T>(a: T, b: T, sortFns: SortFn<T>[]): number {
	let result = 0;
	for (let i = 0; i < sortFns.length && result === 0; i++) {
		result = sortFns[i](a, b);
	}
	return result;
}

/**
 * Return a sorting function that applies the provided {@link SortFn<T>}s until one of them
 * returns a number other than 0.
 * @param sortFns an array of {@link SortFn<T>}s.
 */
export function multiSortFn<T>(sortFns: SortFn<T>[]): SortFn<T> {
	return (a, b) => multiSort(a, b, sortFns);
}

/**
 * Compare two values using the build-in comparison operator.
 *
 * @param a left value.
 * @param b right value.
 * @returns -1, 0, 1 if `a` is less than, equal to or greater than `b`, respectively.
 */
export function builtInSort<T>(a: T, b: T, direction: "asc" | "desc" = "asc"): number {
	const directionMultiplier = direction === "asc" ? 1 : -1;
	if (a < b) {
		return -1 * directionMultiplier;
	}
	if (a > b) {
		return 1 * directionMultiplier;
	}
	return 0;
}

/**
 * Provide a {@link SortFn<T>} that uses the built-in comparison operator
 * to compare two objects of the specified type by the specified field.
 *
 * Example usage:
 * ```ts
 * const users = [{name: 'Samantha'}, {name: 'Johnny'}]
 * 	.sort(builtInSortFnFor('name'));
 * console.log(users); // [{name: 'Johnny'}, {name: 'Samantha'}]
 * ```
 *
 * @param name a field name.
 */
export function builtInSortFnFor<T>(name: keyof T, direction: "asc" | "desc" = "asc"): SortFn<T> {
	return (a, b) => builtInSort(a[name], b[name], direction);
}

export function builtInCaseInsensitiveSort(
	a: Maybe<string>,
	b: Maybe<string>,
	direction: "asc" | "desc" = "asc",
	coalesce: string = "",
): number {
	return builtInSort(a?.toLowerCase() ?? coalesce, b?.toLowerCase() ?? coalesce, direction);
}

export function builtInCaseInsensitiveSortFor<TKey extends string, T extends { [K in TKey]?: Maybe<string> }>(
	name: TKey,
	direction: "asc" | "desc" = "asc",
	coalesce: string = "",
): SortFn<T> {
	return (a, b) => builtInCaseInsensitiveSort(a[name], b[name], direction, coalesce);
}

export function sortByNumericMapping<T>(map: (item: T) => number, direction: "asc" | "desc" = "asc"): SortFn<T> {
	return (a, b) => builtInSort(map(a), map(b), direction);
}

export function replaceById<Arr extends ArrayLike<unknown>, TComparable>(
	cur: Arr,
	newItem: Arr[number],
	identify: (item: Arr[number]) => TComparable,
): Array<Arr[number]> {
	const next = Array.from(cur);
	const newItemId = identify(newItem);
	const index = next.findIndex((prevItem) => identify(prevItem) === newItemId);
	if (index !== -1) {
		next[index] = newItem;
	}
	return next;
}

export function removeById<Arr extends ArrayLike<unknown>, TComparable>(
	cur: Arr,
	identifier: TComparable,
	identify: (item: Arr[number]) => TComparable,
): Array<Arr[number]> {
	const next = Array.from(cur);
	const index = next.findIndex((prevItem) => identify(prevItem) === identifier);
	if (index !== -1) {
		next.splice(index, 1);
	}
	return next;
}

export function removeEmptyItems<TIn>(x: Array<TIn | undefined | null>): Array<TIn> {
	return x.filter((item) => item !== null && item !== undefined) as Array<TIn>;
}

export function filterMap<TIn, TOut>(
	arr: ArrayLike<TIn>,
	filterMapFn: (x: TIn) => { value: TOut } | null,
): Array<TOut> {
	const output = new Array<TOut>();
	for (let i = 0; i < arr.length; i++) {
		const result = filterMapFn(arr[i]);
		if (result) {
			output.push(result.value);
		}
	}
	return output;
}

export function countIf<T>(arr: ArrayLike<T>, countItFn: (x: T) => boolean): number {
	let sum = 0;
	for (let i = 0; i < arr.length; i++) {
		if (countItFn(arr[i])) {
			sum++;
		}
	}
	return sum;
}

export function mapArrayLike<TArr extends ArrayLike<any>, TOut>(
	arr: TArr,
	mapFn: (item: TArr[number], index: number) => TOut,
): Array<TOut> {
	const mapped = new Array(arr.length);
	for (let i = 0; i < arr.length; i++) {
		mapped[i] = mapFn(arr[i], i);
	}
	return mapped;
}

export function mapIterable<TArr extends Iterable<any>, TOut>(
	iter: TArr,
	mapFn: TArr extends Iterable<infer Item> ? (v: Item, i: number) => TOut : never,
): Array<TOut> {
	const mapped = [];
	let i = 0;
	for (const item of iter) {
		mapped.push(mapFn(item, i));
		i++;
	}
	return mapped;
}

export function maxArrayLike<TArr extends ArrayLike<any>, TExtractFn extends (v: TArr[number]) => number>(
	arr: TArr,
	extractFn: TExtractFn,
): number {
	let max = -Infinity;
	for (let i = 0; i < arr.length; i++) {
		max = Math.max(max, extractFn(arr[i]));
	}
	return max;
}

export function maxIterable<TArr extends Iterable<any>>(
	iter: TArr,
	extractFn: TArr extends Iterable<infer Item> ? (v: Item) => number : never,
): number {
	let max = -Infinity;
	for (const item of iter) {
		max = Math.max(max, extractFn(item));
	}
	return max;
}

export function minArrayLike<TArr extends ArrayLike<any>, TExtractFn extends (v: TArr[number]) => number>(
	arr: TArr,
	extractFn: TExtractFn,
): number {
	let min = Infinity;
	for (let i = 0; i < arr.length; i++) {
		min = Math.min(min, extractFn(arr[i]));
	}
	return min;
}

export function minIterable<TArr extends Iterable<any>>(
	iter: TArr,
	extractFn: TArr extends Iterable<infer Item> ? (v: Item) => number : never,
): number {
	let min = Infinity;
	for (const item of iter) {
		min = Math.min(min, extractFn(item));
	}
	return min;
}

export function sumArrayLike<TArr extends ArrayLike<any>, TExtractFn extends (v: TArr[number]) => number>(
	arr: TArr,
	extractFn: TExtractFn,
): number {
	let sum = 0;
	for (let i = 0; i < arr.length; i++) {
		sum = sum + extractFn(arr[i]);
	}
	return sum;
}

export function sumIterable<TArr extends Iterable<any>>(
	iter: TArr,
	extractFn: TArr extends Iterable<infer Item> ? (v: Item) => number : never,
): number {
	let sum = 0;
	for (const item of iter) {
		sum = sum + extractFn(item);
	}
	return sum;
}

export function shuffle<TArr extends MutableArrayLike<any>>(arr: TArr): TArr {
	for (let i = 0; i < arr.length; i++) {
		const j = Math.floor(Math.random() * arr.length);

		if (i !== j) {
			const tmp = arr[i];
			arr[i] = arr[j];
			arr[j] = tmp;
		}
	}

	return arr;
}

export function toUndefinedIfEmpty<T>(arr: T[] | undefined): T[] | undefined {
	return arr?.length ? arr : undefined;
}

export type MapKeys<M> = M extends Map<infer K, any> ? K : never;
export type MapValues<M> = M extends Map<any, infer V> ? V : never;

export function oneOf<T, AllowedValues extends [any, ...any[]]>(
	x: T,
	allowedValues: AllowedValues,
): x is AllowedValues[number] {
	return allowedValues.includes(x);
}
