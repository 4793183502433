import { MarketControllerV2ApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import GraphLegend from "$root/components/GraphLegend";
import DonoutCircle from "$root/components/icons/DonoutCircle";
import InfiniteLoader from "$root/components/InfiniteLoader";
import useOutlookComposition from "$root/hooks/useOutlookComposition";
import { Icon } from "@mdotm/mdotui/components";
import { PaletteColors } from "$root/styles/themePalette";
import { pxToRem } from "$root/utils/pxToRem";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { UniverseFiltersContext } from "$root/widgets-architecture/contexts/filters";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import styled from "styled-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ForecastTable from "./ForecastTable";
import { noop } from "@mdotm/mdotui/utils";
import { useEventBus } from "$root/event-bus";

const GraphLegenda = styled("div")({
	position: "relative",
	"> div": {
		position: "absolute",
	},
	".Line": {
		height: pxToRem(1),
		width: pxToRem(112),
		marginLeft: pxToRem(23),
		borderRadius: pxToRem(10),
		backgroundColor: PaletteColors.BLUEY_GREY,
	},
	".Rectangle": {
		zIndex: 1,
		height: pxToRem(11),
		borderRadius: pxToRem(2),
		width: pxToRem(58),
		backgroundColor: PaletteColors.BLUEY_GREY,
		bottom: `-${pxToRem(6)}`,
		marginLeft: pxToRem(51),
	},
	".Median": {
		zIndex: 2,
		width: pxToRem(2),
		height: pxToRem(11),
		bottom: `-${pxToRem(6)}`,
		backgroundColor: PaletteColors.CHARCOAL_GREY_TWO,
		marginLeft: pxToRem(79),
	},
	".MIN, .MAX": {
		bottom: `-${pxToRem(8)}`,
	},
	".MAX": {
		marginLeft: pxToRem(140),
	},
	".FIRST, .MEDIAN, .THIRD": {
		bottom: pxToRem(8),
	},
	".FIRST": {
		width: pxToRem(53),
	},
	".MEDIAN": {
		marginLeft: pxToRem(61),
	},
	".THIRD": {
		width: pxToRem(55),
		marginLeft: pxToRem(108),
	},
	".DASHED": {
		width: pxToRem(1),
		height: pxToRem(6),
		bottom: pxToRem(5),
		".WHITE": {
			position: "relative",
			width: pxToRem(1),
			height: pxToRem(1),
			backgroundColor: PaletteColors.WHITE,
		},
		".GREY": {
			position: "relative",
			width: pxToRem(1),
			height: pxToRem(1),
			backgroundColor: PaletteColors.BLUEY_GREY,
		},
	},
	".First": {
		marginLeft: pxToRem(51),
	},
	".Second": {
		marginLeft: pxToRem(79.5),
	},
	".Third": {
		marginLeft: pxToRem(108),
	},
});

const ForecastBlock = (props: ContextContent<typeof UniverseFiltersContext>) => {
	const { firstFilter, secondFilter } = props;
	const { assetClass, geography } = useOutlookComposition(firstFilter, secondFilter, "");
	const { t } = useTranslation();
	const marketApi = useApiGen(MarketControllerV2ApiFactory);
	const [timeSelectorValue, setTimeSelectorValue] = useState("");
	const [_timeSelectorOptions, setTimeSelectorOptions] = useState<Array<{ label: string; value: string }>>([]);

	const DASHED = (
		<>
			<div className="GREY" />
			<div className="WHITE" />
			<div className="GREY" />
			<div className="WHITE" />
			<div className="GREY" />
			<div className="WHITE" />
		</>
	);

	const {
		data: forecastData,
		isLoading,
		refetch,
	} = useQueryNoRefetch(["forecastDataApi", assetClass, geography], {
		queryFn: async () => {
			const { data } = await marketApi.retrieveForecastByAssetClass(assetClass, geography);
			return data;
		},
		onSuccess: (data) => {
			const uniqueTimeSelectorOptions = [...Array.from(new Set(data.map((item) => item.timeHorizon ?? "-")))]
				.map((item) => ({
					value: item,
					label: item,
				}))
				.sort((a, b) => (a.label ?? "").localeCompare(b.label ?? ""));
			setTimeSelectorOptions(uniqueTimeSelectorOptions);
			setTimeSelectorValue(uniqueTimeSelectorOptions[0].value);
		},
		onError: (err) => console.warn(err),
	});

	useEventBus("market-update", () => {
		refetch().catch(noop);
	});

	useWidgetOptions(
		() => ({
			title: t("FORECAST.TITLE"),
			actionHeader: <InfoTooltip>{t("FORECAST.TOOLTIP_CONTENT")}</InfoTooltip>,
		}),
		[t],
	);

	return (
		<>
			{isLoading ? (
				<InfiniteLoader />
			) : (
				<>
					{forecastData && (
						<div className="h-[85%]">
							<ForecastTable
								forecasts={forecastData.filter((item) => item.timeHorizon === timeSelectorValue)}
								firstFilter={props.firstFilter}
								secondFilter={props.secondFilter}
								timeSelectorValue={timeSelectorValue}
							/>
						</div>
					)}
				</>
			)}
			<GraphLegend>
				<div className="legend-container light more-space book">
					<Icon icon="driver-arrow-center" color={PaletteColors.AZURE} size={10} /> {t("CURRENT_FORECAST")}
				</div>
				<div className="legend-container light more-space book">
					<DonoutCircle className="2" /> {t("PREVIOUS_FORECAST")}
				</div>
				<div className="legend-container light more-space book">
					<GraphLegenda>
						<div className="MIN">{t("FORECAST.MIN")}</div>
						<div className="FIRST">{t("FORECAST.1QUAR")}</div>
						<div className="DASHED First">{DASHED}</div>
						<div className="Line" />
						<div className="MEDIAN">{t("FORECAST.MEDIAN")}</div>
						<div className="DASHED Second">{DASHED}</div>
						<div className="Median" />
						<div className="Rectangle" />
						<div className="THIRD">{t("FORECAST.3QUAR")}</div>
						<div className="DASHED Third">{DASHED}</div>
						<div className="MAX">{t("FORECAST.MAX")}</div>
					</GraphLegenda>
				</div>
			</GraphLegend>
		</>
	);
};

export default withContext(UniverseFiltersContext)(ForecastBlock);
