import { Card } from "../Card";
import type { TableParams, TableVariants } from "$root/widgets-architecture/widgets/OutlookBlock/OutlookBlockTable";
import OutlookBlockTable from "$root/widgets-architecture/widgets/OutlookBlock/OutlookBlockTable";
import { toClassName } from "@mdotm/mdotui/react-extensions";
import type { PrintableProps } from "../../configuration";
import type { CommonSetUpReportProps, UnionMapData } from "../../configuration/hooks/useExtractReports";

type OutlookTablesProps = CommonSetUpReportProps<UnionMapData["custom-report"]>;

export function getOutlookTableProps(
	data: OutlookTablesProps,
): Array<TableParams & { name: TableVariants; hideHeader: boolean }> {
	const composition = [
		...data.Equity.map((x, i) => ({ ...x, name: "EQUITY" as const, hideHeader: i > 0 })),
		...data.EquityEu.map((x, i) => ({ ...x, name: "EQUITY_EU" as const, hideHeader: i > 0 })),
		...data.EquityUsa.map((x, i) => ({ ...x, name: "EQUITY_USA" as const, hideHeader: i > 0 })),
		...data.FixedIncome.map((x, i) => ({ ...x, name: "FIXED_INCOME" as const, hideHeader: i > 0 })),
		...data.Commodities.map((x, i) => ({ ...x, name: "COMMODITIES" as const, hideHeader: i > 0 })),
	] satisfies Array<TableParams & { name: TableVariants; hideHeader: boolean }>;

	return composition;
}

const OutlookTables = ({
	list,
	firstRender,
}: PrintableProps<
	{ data: OutlookTablesProps; type?: "default" | "doubleColumns" },
	TableParams & { name: TableVariants; hideHeader: boolean }
>): JSX.Element => {
	return (
		<Card title={firstRender ? "Positioning" : undefined}>
			<div
				className={toClassName({
					"columns-2": true,
				})}
				style={{ columnFill: list.length < 14 ? "auto" : "balance" }}
			>
				{list?.map(({ name, ...row }, x) => (
					<div
						key={x}
						className={toClassName({
							"mt-3.5": row.hideHeader === false && x !== 0,
						})}
					>
						<OutlookBlockTable size="small" title={name} data={row ? [row] : []} hideHeader={row.hideHeader} />
					</div>
				))}
			</div>
		</Card>
	);
};

export default OutlookTables;
