import type { FileResponse } from "$root/api/api-gen";
import Footer from "$root/components/EvolvedPrint/components/pdf/Footer";
import Header from "$root/components/EvolvedPrint/components/pdf/Header";
import type { ComponentAndPropsPair } from "$root/components/EvolvedPrint/configuration";
import { Renderer } from "$root/components/EvolvedPrint/configuration";
import {
	firstPageHeaderHeight,
	otherPagesHeaderHeight,
	pageFooterHeight,
} from "$root/components/EvolvedPrint/configuration/shared";
import { mmToPx } from "$root/components/EvolvedPrint/configuration/utils";
import { CircularProgressBar } from "@mdotm/mdotui/components";
import { useState } from "react";

export default function Portrait(props: {
	title: string;
	subtitle: string;
	footer?: {
		logo?: FileResponse;
		description?: string;
	};
	componentAndPropsList: Array<ComponentAndPropsPair<any, any>>;
}): JSX.Element {
	const [isReady, setIsReady] = useState(false);
	const A4PortraitHeight_mm = 297;
	const A4PortraitWidth_mm = 210;

	return (
		<>
			{isReady ? (
				<div key="ready" data-role="start-printing" data-qualifier="pdfReport/promiseId" id="loaded_paperplane" />
			) : (
				<CircularProgressBar
					key="loading"
					value="indeterminate"
					classList="fixed top-2 right-2 z-50"
					outerDiameter={36}
				/>
			)}
			<div data-size="A4portrait" className="page_brake">
				{props.componentAndPropsList[0].component({
					...props.componentAndPropsList[0].commonProps,
					list: props.componentAndPropsList[0].splittableProps,
					firstRender: true,
					availableSpace: mmToPx(A4PortraitHeight_mm),
				})}
			</div>
			<div data-size="A4Template">
				<Renderer
					pageHeader={(forward) => <Header {...forward} title={props.title} subtitle={props.subtitle} />}
					pageFooter={(forward) => (
						<Footer {...forward} customLogo={props.footer?.logo} customDescription={props.footer?.description} />
					)}
					firstPageHeaderHeight={firstPageHeaderHeight}
					otherPagesHeaderHeight={otherPagesHeaderHeight}
					pageFooterHeight={pageFooterHeight}
					pageHeight={mmToPx(A4PortraitHeight_mm)}
					pageWidth={mmToPx(A4PortraitWidth_mm)}
					componentAndPropsList={props.componentAndPropsList.slice(1, -1)}
					onFinish={() => setIsReady(true)}
				/>
			</div>
			<div data-size="A4portrait" className="page_brake">
				{props.componentAndPropsList.at(-1)!.component({
					...props.componentAndPropsList.at(-1)!.commonProps,
					list: props.componentAndPropsList.at(-1)!.splittableProps,
					firstRender: true,
					availableSpace: mmToPx(A4PortraitHeight_mm),
				})}
			</div>
		</>
	);
}
