import { AbortError } from "$root/utils/promise";
import { noop } from "$root/utils/runtime";
import { Icon, ProgressBar, Text } from "@mdotm/mdotui/components";
import { Switch, useUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { sleep, unpromisify } from "@mdotm/mdotui/utils";
import { useEffect, useRef, useState } from "react";
import type { Step } from "./atom";
import { steps } from "./atom";

class CompleteStepAbortError extends AbortError {}

async function* fakeProgressProvider(opts: {
	signal?: AbortSignal;
	expCoeff?: number;
	deltaX?: number;
	sleepMs: number;
	latestProgress?: number;
}) {
	let stop = opts.signal?.aborted ?? false;
	const abortListener = () => (stop = true);
	opts.signal?.addEventListener("abort", abortListener, { once: true });
	const expCoeff = opts.expCoeff ?? 1;
	const deltaX = opts.deltaX ?? 1;
	// let x = 0; // 0 + (latestProgress ?? 0) --> in this case anyway js should not initialize the value every time
	let x = opts.latestProgress ?? 0;
	while (!stop) {
		yield 1 - Math.exp(-x * expCoeff);
		await sleep(opts.sleepMs);
		x += deltaX;
	}
	opts.signal?.removeEventListener("abort", abortListener);
	yield 1; // finally return 1
}

export type FakeAiLoaderProps = {
	signal?: AbortSignal;
	onDone?(): void;
	scrollToBottom?(): void;
	persist?(progress: number, step: Step): void;
	defaultValue?: { progress: number; step: Step };
}

function FakeAiLoader(props: FakeAiLoaderProps): JSX.Element {
	const [latestState, _setLatestState] = useState<Step | null>(props.defaultValue?.step ?? null);
	const latestStateRef = useRef(latestState);
	function setLatestState(step: Step | null) {
		_setLatestState(step);
		latestStateRef.current = step;
	}

	const [progress, _setProgress] = useState(props.defaultValue?.progress ?? 0);
	const latestProgressRef = useRef(progress);
	function setProgress(currentProgress: number) {
		_setProgress(currentProgress);
		latestProgressRef.current = currentProgress;
	}

	const defaultProgressRef = useRef(props.defaultValue?.progress);
	const propsRef = useUpdatedRef(props);
	const persistUpdateRef = useUpdatedRef({ persist: props.persist });

	useEffect(() => {
		let latestLocalAbortController: AbortController | undefined = undefined;
		const abortListener = () => latestLocalAbortController?.abort(propsRef.current.signal?.reason);
		propsRef.current.signal?.addEventListener("abort", abortListener, { once: true });
		unpromisify(async () => {
			const currentStepState = latestStateRef.current;
			const initialIndex = currentStepState ? steps.indexOf(currentStepState) : 0;

			for (let i = initialIndex; i < steps.length; i++) {
				const step = steps[i];
				setLatestState(step);

				const localAbortController = new AbortController();
				latestLocalAbortController = localAbortController;

				if (propsRef.current.signal?.aborted) {
					setLatestState(null);
					setProgress(0);
					localAbortController.abort(new CompleteStepAbortError());
				}

				if (i !== steps.length - 1) {
					sleep(3000).then(() => {
						localAbortController.abort(new CompleteStepAbortError());
					}, noop);
				}
				for await (const p of fakeProgressProvider({
					signal: localAbortController.signal,
					deltaX: i !== steps.length - 1 ? 0.275 : 0.02,
					expCoeff: i !== steps.length - 1 ? 1 : 0.4,
					sleepMs: 400,
					latestProgress: defaultProgressRef.current,
				})) {
					setProgress(p);
					if (defaultProgressRef.current) {
						defaultProgressRef.current = undefined;
					}
				}
				await sleep(400);
			}
			propsRef.current.signal?.removeEventListener("abort", abortListener);
			propsRef.current.onDone?.();
			setLatestState(null);
			setProgress(0);
		})();
		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			latestLocalAbortController?.abort(new AbortError("unmount"));
		};
	}, [propsRef, latestStateRef, defaultProgressRef]);

	// useEffect(() => {
	// 	const handler = persistUpdateRef.current;
	// 	return () => {
	// 		const stepState = latestStateRef.current;
	// 		const progresState = latestProgressRef.current;
	// 		if (stepState) {
	// 			handler.persist?.(progresState ?? 0, stepState);
	// 		}
	// 	};
	// }, [latestProgressRef, latestStateRef, persistUpdateRef]);

	useEffect(() => {
		const handler = persistUpdateRef.current;

		const handleBeforeUnload = (e: BeforeUnloadEvent) => {
			const stepState = latestStateRef.current;
			const progresState = latestProgressRef.current;
			if (stepState) {
				handler.persist?.(progresState ?? 0, stepState);
			}
		};
		window.addEventListener("beforeunload", handleBeforeUnload);
		return () => {
			const stepState = latestStateRef.current;
			const progresState = latestProgressRef.current;
			if (stepState) {
				handler.persist?.(progresState ?? 0, stepState);
			}
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [persistUpdateRef, latestStateRef, latestProgressRef]);

	if (!latestState) {
		return <></>;
	}

	return (
		<div className={`bg-[color:${themeCSSVars.palette_P50}] rounded overflow-hidden`}>
			<div className="w-full pt-2">
				<div className="flex justify-center space-x-2 items-center">
					<Icon icon="calulating" color={themeCSSVars.palette_P400} size={16} classList="animate-spin" />
					<Text type="Body/L/Book" as="p" color={themeCSSVars.palette_N700}>
						<Switch
							case={latestState}
							match={{
								recovering: () => "1/3 - Recovering data",
								analysing: () => "2/3 - Analysing data",
								preparing: () => "3/3 - Preparing and formatting your response",
							}}
						/>
					</Text>
				</div>

				<div className="min-w-0 w-full mt-2">
					<ProgressBar accentColor={themeCSSVars.palette_P400} classList="w-full" value={progress} />
				</div>
			</div>
		</div>
	);
}

export default FakeAiLoader;
