import type { GlobalRisk } from "$root/api/api-gen";
import { HmmControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import type { WorldGridMapSnapshot } from "$root/components/RegimeAnalysisTool/WorldGrid";
import { WorldGrid } from "$root/components/RegimeAnalysisTool/WorldGrid";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { RegionContext } from "$root/widgets-architecture/contexts/region";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { ContextContent } from "@mdotm/mdotui/react-extensions";
import { withContext } from "@mdotm/mdotui/react-extensions";

function generateSnapshotLevel(data: Array<GlobalRisk>): Array<WorldGridMapSnapshot> {
	return data.map(({ dateTime, risks }) => ({
		time: dateTime!,
		snapshot: (risks ?? []).map(({ assetClassess, geo }) => ({
			firstLevel: {
				name: geo!,
				riskLevel: 0,
				weight: 1,
				secondLevels: (assetClassess ?? []).map((ac) => ({
					name: ac.macroName!,
					riskLevel: ac.valueOnMap!,
					weight: ac.value!,
					thirdLevels: (ac.sectors ?? []).map((sector) => ({
						name: sector.assetClassIdentifier!,
						riskLevel: sector.valueOnMap!,
						weight: sector.value!,
						fourthLevels: (sector.subSectors ?? []).map((sub) => ({
							name: sub.assetClassIdentifier!,
							riskLevel: sub.valueOnMap!,
							weight: sub.value!,
						})),
					})),
				})),
			},
		})) satisfies WorldGridMapSnapshot["snapshot"],
	}));
}

const WorldGridMapBlock = (props: ContextContent<typeof RegionContext>) => {
	const hmmApi = useApiGen(HmmControllerApiFactory);
	useWidgetOptions(
		() => ({
			title: "Global risk map",
			alertsActive: false,
			actionHeader: <></>,
		}),
		[],
	);

	return (
		<div className="max-w-[1920px] mx-auto w-full">
			<WorldGrid
				dataProvider={async (valueOnMap) => {
					const dates = await axiosExtract(hmmApi.retrieveGlobalRiskMapDatesIsoFormat(valueOnMap));
					return generateSnapshotLevel(
						await axiosExtract(
							hmmApi.retrieveGlobalRiskMapIsoFormat(valueOnMap, dates.at(0) ?? "", dates.at(-1) ?? ""),
						),
					);
				}}
			/>
		</div>
	);
};

export default withContext(RegionContext)(WorldGridMapBlock);
