import type { EditorSaveEditOrReviewRequestEditPolicyEnum, InvestmentSummary, ReviewTicker } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory, InvestmentReportsControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import EditCompositionSection from "$root/functional-areas/edit-composition/EditCompositionSection";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { ellipsis } from "$root/utils/strings";
import { AsyncButton, Button } from "@mdotm/mdotui/components";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useParams } from "react-router";
import { UploadEnum } from "./Portfolios/UploadPortfolioPage";
import { PortfolioStudioTab } from "./PortfoliosStudio";
import { platformToast } from "$root/notification-system/toast";
import { AxiosError } from "axios";

const EditPortfolioCompositionPage = (): JSX.Element => {
	const [editorComposition, setEditorComposition] = useState<{
		disabled: boolean;
		onSubmit(fn: (data: ReviewTicker[]) => Promise<void>): Promise<void>;
	} | null>(null);

	const [pathToNotBlock, setPathToNotBlock] = useState(["/login"]);

	const history = useHistory();
	const { t } = useTranslation();
	const { formatDate } = useLocaleFormatters();
	const { portfolioUid } = useParams<{ portfolioUid: string }>();
	const user = useUserValue();
	const editorApi = useApiGen(EntityEditorControllerApiFactory);

	const manageFinish = useCallback(() => {
		setPathToNotBlock(["/login", typedUrlForRoute("PortfolioDetails", { portfolioUid })]);
		setTimeout(() => history.push(typedUrlForRoute("PortfolioDetails", { portfolioUid })));
	}, [history, portfolioUid]);

	const onOptimizeOrSaveEnhancement = useCallback(
		async (summary: InvestmentSummary, policy: EditorSaveEditOrReviewRequestEditPolicyEnum) => {
			await editorComposition?.onSubmit(async (submitData) => {
				try {
					if (summary.uuid === undefined) {
						return reportPlatformError(
							summary,
							"ERROR",
							"portfolio",
							`unable to submit a valid portfolio of undefined`,
						);
					}

					await editorApi.saveEditorEditEntity("INVESTMENT_ENHANCEMENT", {
						identifier: summary.uuid,
						tickerComposition: submitData,
						editPolicy: policy,
					});

					trackMixPanelEvent("Portfolio", {
						Type: "Update",
						Area: `Composition`,
						Mode: policy === "SAVE_AND_ACCEPT" ? "enhance" : "enhance Without Accept",
						ID: portfolioUid ?? "",
					});
					manageFinish();
				} catch (error) {
					const e = error as AxiosError<{ message?: string }>;
					const message = e.response?.data.message;

					if (message === "maxValue should be greater or equal to 0.02") {
						platformToast({
							children: "Please review your instrument, minimum weight acceptable is 0.02",
							severity: "error",
							icon: "Portfolio",
						});
					}

					if (message !== "maxValue should be greater or equal to 0.02") {
						platformToast({
							children: "Something went wrong, please try later",
							severity: "error",
							icon: "Portfolio",
						});
					}

					reportPlatformError(
						error,
						"ERROR",
						"portfolio",
						`unable to save the edited portfolio composition (${summary.uuid})`,
					);
				}
			});
		},
		[editorApi, editorComposition, manageFinish, portfolioUid],
	);

	const onEditComposition = useCallback(
		async (summary: InvestmentSummary) => {
			await editorComposition?.onSubmit(async (submitData) => {
				try {
					if (summary.uuid === undefined) {
						return reportPlatformError(
							summary,
							"ERROR",
							"portfolio",
							`unable to submit a valid portfolio of undefined`,
						);
					}

					await editorApi.saveEditorEditEntity(summary.reference ? "TARGET_INVESTMENT" : "INVESTMENT", {
						identifier: summary.uuid,
						tickerComposition: submitData,
					});

					trackMixPanelEvent("Portfolio", {
						Type: "Update",
						Area: `Composition`,
						Mode: "normal",
						ID: summary.uuid ?? "",
					});
					manageFinish();
				} catch (error) {
					const e = error as AxiosError<{ message?: string }>;
					const message = e.response?.data.message;

					if (message === "maxValue should be greater or equal to 0.02") {
						platformToast({
							children: "Please review your instrument, minimum weight acceptable is 0.02",
							severity: "error",
							icon: "Portfolio",
						});
					}

					if (message !== "maxValue should be greater or equal to 0.02") {
						platformToast({
							children: "Something went wrong, please try later",
							severity: "error",
							icon: "Portfolio",
						});
					}

					reportPlatformError(
						error,
						"ERROR",
						"portfolio",
						`unable to save the edited portfolio composition (${summary.uuid})`,
					);
				}
			});
		},
		[editorApi, editorComposition, manageFinish],
	);

	const investmentReport = useApiGen(InvestmentReportsControllerApiFactory);

	return (
		<>
			<ReactQueryWrapper
				queryKey={["portfolio", portfolioUid]}
				queryFn={() => investmentReport.getInvestmentSummary(portfolioUid).then(({ data }) => data)}
			>
				{(response) => {
					const { status, action, name, uuid, modificationDate, universeIdentifier, reference, richAcl } = response;
					const proposalName = modificationDate ? `Proposal ${formatDate(modificationDate)}` : ""; // TODO: translat
					const canEditComposition = aclByArea.portfolio.canEditComposition(user.id, [
						{ userId: user.id, permissions: richAcl?.currentUserPermissions },
					]);

					if (!canEditComposition) {
						return <Redirect to={typedUrlForRoute("PortfolioDetails", { portfolioUid: uuid ?? "" })} />;
					}

					return (
						<>
							<PageHeader
								title={reference ? "Edit target portfolio composition" : t("EDIT_COMPOSITION.EDIT_COMPOSITION_TITLE")} // TODO: translate
								crumbs={[
									{
										children: "Portfolio studio", // TODO: translate
										href: typedUrlForRoute("PortfoliosStudio", {}),
									},
									{
										children: reference ? "References" : "Portfolios", // TODO: translate
										href: reference
											? typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References })
											: typedUrlForRoute("PortfoliosStudio", {}),
									},
									{
										children: ellipsis(name ?? "...", 30), // TODO: translate
										href: typedUrlForRoute("PortfolioDetails", { portfolioUid: uuid! }),
									},
									...(status === "PROPOSAL_READY"
										? [
												{
													children: proposalName,
													href: typedUrlForRoute("PortfolioDetails", {
														portfolioUid: uuid!,
														proposal: "true",
													}),
												},
										  ]
										: []),
									{
										children: "Edit", // TODO: translate
									},
								]}
								titleAction={
									<div className="flex items-center space-x-2">
										<Button size="small" onClick={() => history.goBack()} palette="tertiary">
											Cancel
										</Button>
										{(action === "OPTIMIZATION" || action === "ENHANCEMENT") && status === "PROPOSAL_READY" ? (
											<>
												<AsyncButton
													size="small"
													palette="primary"
													disabled={editorComposition?.disabled ?? true}
													onClickAsync={() => onOptimizeOrSaveEnhancement(response, "SAVE_WITHOUT_ACCEPT")}
												>
													Update proposal
												</AsyncButton>
												<AsyncButton
													size="small"
													palette="primary"
													disabled={editorComposition?.disabled ?? true}
													onClickAsync={() => onOptimizeOrSaveEnhancement(response, "SAVE_AND_ACCEPT")}
												>
													Save and accept enhancement
												</AsyncButton>
											</>
										) : (
											<AsyncButton
												size="small"
												palette="primary"
												disabled={editorComposition?.disabled ?? true}
												onClickAsync={() => onEditComposition(response)}
											>
												Save
											</AsyncButton>
										)}
									</div>
								}
							/>
							<>
								{(action === "OPTIMIZATION" || action === "ENHANCEMENT") && status === "PROPOSAL_READY" ? (
									<EditCompositionSection
										uuid={uuid}
										type={action === "OPTIMIZATION" ? "optimize" : "enhance"}
										uploadEntity={UploadEnum.INVESTMENT_ENHANCEMENT}
										instrumentsLimit={1000}
										ref={setEditorComposition}
										pathToNotBlock={pathToNotBlock}
									/>
								) : reference ? (
									<EditCompositionSection
										uuid={uuid}
										instrumentsLimit={1000}
										uploadEntity={UploadEnum.TARGET_INVESTMENT}
										ref={setEditorComposition}
										pathToNotBlock={pathToNotBlock}
									/>
								) : (
									<EditCompositionSection
										uuid={uuid}
										type="normal"
										instrumentsLimit={1000}
										hasUniverse={Boolean(universeIdentifier)}
										uploadEntity={UploadEnum.INVESTMENT}
										ref={setEditorComposition}
										pathToNotBlock={pathToNotBlock}
									/>
								)}
							</>
						</>
					);
				}}
			</ReactQueryWrapper>
		</>
	);
};

export default EditPortfolioCompositionPage;
