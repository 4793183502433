function CommentaryReady(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="79" height="89" fill="none" viewBox="0 0 79 89">
			<g filter="url(#filter0_d_10765_7822)">
				<path
					fill="#fff"
					d="M63.935 22.617a1.142 1.142 0 00-.808-.335h-3.428v-3.427a1.145 1.145 0 00-1.142-1.143H28.852V16.57a4.57 4.57 0 10-9.14 0v1.142h-4.57a1.145 1.145 0 00-1.143 1.143v47.983a1.145 1.145 0 001.143 1.142h3.428v3.427a1.145 1.145 0 001.142 1.143h43.415a1.145 1.145 0 001.142-1.142V23.425c0-.303-.12-.594-.334-.808z"
				/>
			</g>
			<g clipPath="url(#clip0_10765_7822)">
				<path
					fill="#C3C9D5"
					d="M63.127 22.282h-3.428v-3.427a1.145 1.145 0 00-1.142-1.143H28.852V16.57a4.569 4.569 0 10-9.14 0v1.142h-4.57a1.145 1.145 0 00-1.143 1.143v47.983a1.145 1.145 0 001.143 1.142h3.428v3.427a1.145 1.145 0 001.142 1.143h43.415a1.145 1.145 0 001.142-1.142V23.425a1.145 1.145 0 00-1.142-1.143zm-46.843-2.285h3.428v3.428a3.427 3.427 0 106.854 0v-1.143h-2.285v1.143a1.142 1.142 0 11-2.285 0V16.57a2.286 2.286 0 014.57 0v1.142H24.28v2.285h33.133v45.698h-41.13V19.998zm45.7 50.268h-41.13V67.98h37.703a1.145 1.145 0 001.142-1.142v-42.27h2.285v45.697z"
				/>
				<path
					fill="#8792AB"
					d="M20.855 49h31.99v2.285h-31.99V49zM20.855 53.57h31.99v2.285h-31.99V53.57zM20.855 58.14h27.42v2.285h-27.42V58.14zM21 31h24v2.285H21V31zM21 35.57h31.99v2.285H21V35.57zM21 40.14h27.42v2.285H21V40.14z"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_10765_7822"
					width="78.27"
					height="88.55"
					x="0"
					y="0"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_10765_7822" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_10765_7822" result="shape" />
				</filter>
				<clipPath id="clip0_10765_7822">
					<path fill="#fff" d="M0 0H50.27V68.55H0z" transform="translate(14 4)" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default CommentaryReady;
