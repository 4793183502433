import type { CommentaryTemplateModel } from "$root/api/api-gen";
import { createPersistentAtom } from "$root/third-party-integrations/zustand";
import type { Updater } from "$root/utils/functions";

export type AllowedModules = Extract<
	keyof CommentaryTemplateModel,
	"instrumentInsightsBlock" | "metricsBlock" | "marketOutlook" | "portfolioCompositionBlock" | "dynamicsBlock"
>;

// export const Modules = {
// 	MarketOutlook: "Market Outlook",
// 	PortfolioDynamics: "Portfolio Dynamics",
// 	PortfolioComposition: "Portfolio Composition",
// 	InstrumentInsights: "Instrument Insights",
// 	PortfolioMetrics: "Portfolio Metrics",
// } as const;

// export type Modules = (typeof Modules)[keyof typeof Modules];

// Omit<NonUndefined<Required<CommentaryTemplateModel[K]>>, "moduleStyling"> & { moduleStyling?: string };
export const stableDefaultConfiguration: {
	[K in AllowedModules]: (
		current?: CommentaryTemplateModel[K],
	) => Omit<Required<NonNullable<CommentaryTemplateModel[K]>>, "moduleStyling"> & { moduleStyling?: string };
} = {
	dynamicsBlock: (module) => ({
		enabled: module?.enabled ?? true,
		moduleStyling: module?.moduleStyling,
		mainInstrument: module?.enabled ?? false,
		relevanceThreshold: module?.relevanceThreshold ?? 0,
	}),
	instrumentInsightsBlock: (module) => ({
		enabled: module?.enabled ?? true,
		moduleStyling: module?.moduleStyling,
		commentedInstrument: module?.commentedInstrument ?? { enabled: true, threshold: 3 },
		deletedInstrument: module?.deletedInstrument ?? { enabled: true, threshold: 3 },
		instrumentWithNegativeDeltaWeight: module?.instrumentWithNegativeDeltaWeight ?? { enabled: true, threshold: 3 },
		instrumentWithPositiveDeltaWeight: module?.instrumentWithPositiveDeltaWeight ?? { enabled: true, threshold: 3 },
		newInstrument: module?.newInstrument ?? { enabled: true, threshold: 3 },
	}),
	marketOutlook: (module) => ({
		enabled: module?.enabled ?? true,
		moduleStyling: module?.moduleStyling,
	}),
	metricsBlock: (module) => ({
		enabled: module?.enabled ?? true,
		moduleStyling: module?.moduleStyling,
		varVolatilityRiskConstraint: module?.varVolatilityRiskConstraint ?? false,
		riskBudgetOnVarVolatility: module?.riskBudgetOnVarVolatility ?? false,
		trackingError: module?.trackingError ?? false,
		riskBudgetTrackingError: module?.riskBudgetTrackingError ?? false,
		exAnteVar: module?.exAnteVar ?? false,
		exAnteDiversificationRatio: module?.exAnteDiversificationRatio ?? false,
		exAnteAnnualizedVolatility: module?.exAnteAnnualizedVolatility ?? false,
		exAnteVolatilityContribution: module?.exAnteVolatilityContribution ?? false,
		// riskConstraint: module?.riskConstraint ?? false,
		riskFactors: module?.riskFactors ?? false,
	}),
	portfolioCompositionBlock: (module) => ({
		enabled: module?.enabled ?? true,
		moduleStyling: module?.moduleStyling,
		commentConstraint: module?.commentConstraint ?? false,
		commentOnBenchmark: module?.commentOnBenchmark ?? false,
		relevanceThresholdForMicroAcCommented: module?.relevanceThresholdForMicroAcCommented ?? 0.3,
		showMainInstrumentForMacroAc: module?.showMainInstrumentForMacroAc ?? true,
	}),
};

export const stableEmptyCommentaryTemplate: CommentaryTemplateModel = {
	context: undefined,
	default: false,
	dynamicsBlock: stableDefaultConfiguration.dynamicsBlock({ enabled: false }),
	instrumentInsightsBlock: stableDefaultConfiguration.instrumentInsightsBlock({ enabled: false }),
	marketOutlook: stableDefaultConfiguration.marketOutlook({ enabled: false }),
	metricsBlock: stableDefaultConfiguration.metricsBlock({ enabled: false }),
	portfolioCompositionBlock: stableDefaultConfiguration.portfolioCompositionBlock({ enabled: false }),
	name: "Untitled template",
	structure: undefined,
	toneOfVoice: undefined, //TODO: ritornare un enum
	language: "ENGLISH", //TODO: ritornare un enum
};

export type StableConfiguration = { [K in AllowedModules]: ReturnType<(typeof stableDefaultConfiguration)[K]> };

type CommentaryAnimation = {
	objectId: string;
	uuid?: string;
};

const initProgress: Array<CommentaryAnimation> = [];
export const commentaryAnimationStore = createPersistentAtom<Array<CommentaryAnimation>>(
	initProgress,
	"commentary-animations",
);

export function commentaryAnimationState(): {
	setCommentaryAnimation: (newValue: CommentaryAnimation[] | Updater<CommentaryAnimation[]>) => void;
	commentaryAnimation: Array<CommentaryAnimation>;
} {
	const { set, value } = commentaryAnimationStore();
	return { setCommentaryAnimation: set, commentaryAnimation: value };
}

export function getCommentaryAnimationByObjectId(objectId: string): CommentaryAnimation | undefined {
	const animations = commentaryAnimationStore((animation) => animation.value);
	return animations.find((animation) => animation.objectId === objectId);
}
