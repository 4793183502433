import type {
	InvestmentSummary,
	ReviewTicker,
	UserCompositionColumnPreference,
	UserEnhancementCompositionColumnPreference,
} from "$root/api/api-gen";
import { hasAccess } from "$root/components/AuthorizationGuard";
import CustomLabels from "$root/components/CustomLabels";
import { InfoDelta } from "$root/components/InfoDelta";
import { MarkdownRenderer } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { TagBadge } from "$root/components/tags/TagBadge";
import { labelToTag } from "$root/components/tags/shared";
import type { PortfolioAlert } from "$root/functional-areas/portfolio/alerts";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import { AutoTooltip, TableV2, TooltipContent } from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

export function useCompositionColumn(
	preferences: UserCompositionColumnPreference[] | UserEnhancementCompositionColumnPreference[],
	portfolio: InvestmentSummary,
	rows: ReviewTicker[],
	alerts: PortfolioAlert[],
) {
	const { t } = useTranslation();
	const user = useUserValue();
	const formatters = useLocaleFormatters();
	const { formatNumber } = formatters;

	const tags = useMemo(
		() =>
			Array.from(new Set(rows.map(({ tagLabel }) => tagLabel)))
				.filter((label) => label)
				.map((label, _i, set) => labelToTag({ label: label! }, set as string[])),
		[rows],
	);

	const columns = useMemo(
		() =>
			preferences.map(
				(preference) =>
					({
						// header: t(`TABLE.HEADERS.${c.preferenceType}`),
						hidden: preference.enabled === false,
						...match(preference)
							.with(
								{ preferenceType: "INSTRUMENT_NAME" },
								(): TableV2.TableColumn<ReviewTicker, string> => ({
									name: "instrument",
									header: "Name",
									content: ({ instrument, tickerId, descriptionCreator, description }, cellProps) => {
										return (
											<TableV2.TableDataCell {...cellProps}>
												<AutoTooltip
													align="startToStart"
													disabled={!description}
													trigger={({ innerRef }) => (
														<div className="flex flex-row items-center flex-nowrap w-full pr-4" ref={innerRef}>
															<div
																className={overrideClassName("whitespace-nowrap text-ellipsis overflow-hidden", {
																	underline: Boolean(description),
																})}
															>
																{instrument ?? ""}
															</div>
															{alerts.some(
																(a) =>
																	(a.type === "InstrumentsChangesAlertCheck" ||
																		a.type === "InstrumentsChangesChildAlertCheck") &&
																	a.value.tickerId === tickerId,
															) && (
																<span
																	className="block rounded-full px-1 ml-1 py-0.5 bg-[#00AEEF] text-white uppercase"
																	style={{ fontSize: 8 }}
																>
																	{t("UPDATED")}
																</span>
															)}
														</div>
													)}
												>
													{descriptionCreator === "SPHERE" ? (
														<TooltipContent>
															<MarkdownRenderer>{description ?? ""}</MarkdownRenderer>
														</TooltipContent>
													) : (
														description
													)}
												</AutoTooltip>
											</TableV2.TableDataCell>
										);
									},
									maxWidth: 560,
									sortFn: builtInSortFnFor("instrument"),
								}),
							)
							.with(
								{ preferenceType: "IDENTIFIER" },
								(): TableV2.TableColumn<ReviewTicker, string> => ({
									name: "identifier",
									header: "Identifier",
									content: ({ identifier }) => identifier ?? "",
									width: 128,
									sortFn: builtInSortFnFor("identifier"),
								}),
							)
							.with(
								{ preferenceType: "ASSET_CLASS" },
								(): TableV2.TableColumn<ReviewTicker, string> => ({
									name: "assetClass",
									header: "Asset Class",
									content: ({ assetClass }) => assetClass ?? "",
									width: 128,
									sortFn: builtInSortFnFor("assetClass"),
								}),
							)
							.with(
								{ preferenceType: "SCORE" },
								(): TableV2.TableColumn<ReviewTicker, string> => ({
									header: (props) => (
										<TableV2.TableHeadCell {...props}>
											{portfolio?.scoreIdentifier ? (
												<CustomLabels
													labelKey={portfolio?.scoreIdentifier}
													fallback={t("SCORE")}
													mode="view"
													isEditable={false}
												/>
											) : (
												t("SCORE")
											)}
										</TableV2.TableHeadCell>
									),
									content: ({ score }) => (score ? formatNumber(score) : ""),
									name: "score",
									width: 128,
									hidden: preference.enabled === false || !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
									sortFn: builtInSortFnFor("score"),
								}),
							)
							.with(
								{ preferenceType: "MICRO_ASSET_CLASS" },
								(): TableV2.TableColumn<ReviewTicker, string> => ({
									name: "microAssetClass",
									header: "Micro Asset Class",
									width: 240,
									content: ({ microAssetClass }) => microAssetClass ?? "",
									sortFn: builtInSortFnFor("microAssetClass"),
								}),
							)
							.with(
								{ preferenceType: "WEIGHT" },
								(): TableV2.TableColumn<ReviewTicker, string> => ({
									name: "weight",
									header: "Weight",
									content: (row) => `${formatNumber(row.weight ?? 0)}%`,
									width: 104,
									sortFn: builtInSortFnFor("weight"),
									align: "end",
								}),
							)
							.with(
								{ preferenceType: "CURRENT_WEIGHT" },
								(): TableV2.TableColumn<ReviewTicker, string> => ({
									name: "currentWeight",
									header: "Current Weight",
									content: (row) => `${formatNumber(row.previousWeight ?? 0)}%`,
									width: 134,
									sortFn: builtInSortFnFor("previousWeight"),
									align: "end",
									cellClassList: "tabular-nums",
								}),
							)
							.with(
								{ preferenceType: "ENHANCED_WEIGHT" },
								(): TableV2.TableColumn<ReviewTicker, string> => ({
									name: "enhancedWeight",
									header: "Enhanced Weight",
									content: (row) => `${formatNumber(row.weight ?? 0)}%`,
									width: 104,
									sortFn: builtInSortFnFor("weight"),
									align: "end",
									cellClassList: "tabular-nums",
								}),
							)
							.with(
								{ preferenceType: "DIFFERENCE" },
								(): TableV2.TableColumn<ReviewTicker, string> => ({
									name: "difference",
									header: "difference",
									content: (instrument, cellProps) => {
										const deltaWeight = Number((instrument.weight ?? 0) - (instrument.previousWeight ?? 0)).toFixed(2);
										return (
											<TableV2.TableDataCell {...cellProps}>
												<InfoDelta diff={Number(deltaWeight) ?? 0} enh={instrument.weight ?? 0} />
											</TableV2.TableDataCell>
										);
									},
									align: "end",
									width: 140,
									cellClassList: "tabular-nums",
									sortFn: (rowa, rowb) => {
										const deltaA = (rowa.weight ?? 0) - (rowa.previousWeight ?? 0);
										const deltaB = (rowb.weight ?? 0) - (rowb.previousWeight ?? 0);

										if (deltaA > deltaB) {
											return 1;
										}

										if (deltaA < deltaB) {
											return -1;
										}

										return 0;
									},
								}),
							)
							.with(
								{
									preferenceType: "TAG",
								},
								(): TableV2.TableColumn<ReviewTicker, string> => ({
									name: "tag",
									header: "Tag",
									content: ({ tagLabel }, cellProps) => {
										const currentTag = tags?.find((item) => item.name === tagLabel);
										if (currentTag === undefined) {
											return "";
										}

										return (
											<TableV2.TableDataCell {...cellProps}>
												<TagBadge color={currentTag.color}>{currentTag.name}</TagBadge>
											</TableV2.TableDataCell>
										);
									},
									minWidth: 140,
									maxWidth: 320,
									sortFn: builtInSortFnFor("tagLabel"),
								}),
							)
							.otherwise(() => ({})),
					}) as TableV2.TableColumn<ReviewTicker, string>,
			),
		[formatNumber, portfolio?.scoreIdentifier, preferences, t, tags, user, alerts],
	);

	return columns;
}
