import type {
	CommentaryTemplateDto,
	CommentaryTemplateModel,
	InvestmentCommentaryResponseStatusEnum,
} from "$root/api/api-gen";
import {
	CommentaryTemplateControllerApiFactory,
	InvestmentControllerV4ApiFactory,
	InvestmentEnhancementControllerV4ApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentReportsControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import AuthorizationGuard, { hasAccess } from "$root/components/AuthorizationGuard";
import FakeAiLoader, { FakeAiLoaderProps } from "$root/components/FakeAiLoader";
import {
	animationProgressState,
	getAnimationProgressById,
	simulateAnimationProgress,
} from "$root/components/FakeAiLoader/atom";
import type { MarkdownRendererProps } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { MarkdownRenderer } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { useEventBus, waitForEvent } from "$root/event-bus";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import { PortfolioCommentaryGenerationPolicy } from "$root/functional-areas/portfolio/policies/commentary";
import { useLocaleFormatters } from "$root/localization/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { unpromisify } from "$root/utils/functions";
import { objMatchFn } from "$root/utils/objects";
import { AbortError } from "$root/utils/promise";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { WidgetBlockContext } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import {
	ActionText,
	AsyncButton,
	Banner,
	Icon,
	Option,
	ScrollWrapper,
	Text,
	Transition,
} from "@mdotm/mdotui/components";
import { toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { noop, stableEmptyObject } from "@mdotm/mdotui/utils";
import EventEmitter from "eventemitter3";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PortfolioContext } from "../contexts/portfolio";
import { IconWalls } from "$root/components/IconWall";
import { PortfolioQueryWidgetBase, WidgetStatus } from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import { useUserValue } from "$root/functional-areas/user";
import { ButtonWithSelect } from "$root/components/buttons/ButtonWithSelect";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { PortfolioDetailsTabs } from "$root/pages/PortfolioDetails";

function PortfolioCommentaryBodyMDBlockV2(props: {
	data: {
		commentary?: string | undefined;
		shortCommentary?: string | undefined;
		status?: InvestmentCommentaryResponseStatusEnum | undefined;
		commentaryDate?: string | undefined;
		template?: CommentaryTemplateDto | undefined;
	};
	ctx: ContextContent<typeof PortfolioContext>;
	templates: CommentaryTemplateModel[];
	onGenerate(selected: string): MaybePromise<void>;
	selected: string;
	commentaryGenerationStatus: string | null;
	isGeneratingCommentary: boolean;
	loaderProps: FakeAiLoaderProps;
}) {
	const { data, ctx, templates, selected, commentaryGenerationStatus, isGeneratingCommentary } = props;
	const { commentary, commentaryDate, status, template } = data;
	const { portfolio, enhanced } = ctx;
	const { formatDate } = useLocaleFormatters();

	const options = useMemo(
		(): Array<Option<string>> =>
			templates?.flatMap((x) => (x.name && x.uuid && x.visible ? [{ label: x.name, value: x.uuid }] : [])) ?? [],
		[templates],
	);

	// const selectedTemplate = useMemo(() => options.find((x) => x.value === selected), [options, selected]);

	return (
		<>
			<ScrollWrapper>
				<MarkdownRenderer componentOverrides={markdownOverrides}>{commentary ?? ""}</MarkdownRenderer>
			</ScrollWrapper>
			<div className="py-1 mt-auto">
				<AuthorizationGuard
					permissionChecker={aclByArea.portfolio.canEditComposition}
					acl={portfolio?.richAcl?.acl ?? []}
				>
					{isGeneratingCommentary ? (
						<FakeAiLoader {...props.loaderProps} />
					) : (
						<div className="flex justify-between py-1 mt-auto">
							{commentaryDate && (
								<div className="flex gap-1">
									<Icon icon="Clock" color={themeCSSVars.palette_N300} size={16} classList="my-auto" />
									<div>
										<p className="whitespace-pre-line">
											<Text type="Body/S/Book">Generated on </Text>
											<Text type="Body/S/Bold">
												{formatDate(commentaryDate, { hour: "2-digit", minute: "2-digit" })} CET
											</Text>
										</p>
										<p className="whitespace-pre-line">
											<Text type="Body/S/Book">Template </Text>
											<Text type="Body/S/Bold">{template?.name}</Text>
										</p>
									</div>
								</div>
							)}
							{!enhanced && (
								<div className="flex items-center gap-2">
									<ButtonWithSelect
										options={options}
										value={selected}
										disabled={
											portfolio?.status === "CALCULATING" ||
											portfolio?.status === "ERROR" ||
											status === "CALCULATING" ||
											commentaryGenerationStatus === "CALCULATING"
										}
										palette="secondary"
										size="small"
										onClick={props.onGenerate}
									>
										Generate
									</ButtonWithSelect>
								</div>
							)}
						</div>
					)}
				</AuthorizationGuard>
			</div>
		</>
	);
}

const PortfolioCommentaryMDBlock = (ctx: ContextContent<typeof PortfolioContext>) => {
	const { portfolio, enhanced, reportExcutionCounter } = ctx;
	const user = useUserValue();
	const [tabIndex, setTabIndex] = useState(0);
	const [tabTitlesContainer, setTabTitlesContainer] = useState<HTMLDivElement | null>(null);
	const [isGeneratingCommentary, setIsGeneratingCommentary] = useState(false);
	const { setAnimationProgress } = animationProgressState();

	const [commentaryGenerationAbortController, setCommentaryGenerationAbortController] = useState(new AbortController());
	const [commentaryGenerationDoneEmitter, setCommentaryGenerationDoneEmitter] = useState(new EventEmitter<"done">());

	const resetEvents = useCallback(() => {
		setCommentaryGenerationDoneEmitter(new EventEmitter<"done">());
		setCommentaryGenerationAbortController(new AbortController());
	}, []);

	const [commentaryGenerationStatus, setCommentaryGenerationStatus] = useState<null | string>(null);
	const thinkingBoxAnimation = useCallback(async () => {
		commentaryGenerationAbortController.abort(new AbortError("done"));
		let cleanup = noop;
		await new Promise((resolve) => {
			commentaryGenerationDoneEmitter.addListener("done", resolve);
			cleanup = () => commentaryGenerationDoneEmitter.removeListener("done", resolve);
		});
		cleanup();
	}, [commentaryGenerationAbortController, commentaryGenerationDoneEmitter]);

	const commentaryGenerationProgress = getAnimationProgressById(portfolio?.uuid ?? "-");

	const { formatDate } = useLocaleFormatters();
	const { t } = useTranslation();
	const enhanceInvestmentReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);

	//commentary api
	const investmentV4Api = useApiGen(InvestmentControllerV4ApiFactory);
	const investmentEnhancementV4Api = useApiGen(InvestmentEnhancementControllerV4ApiFactory);
	const commentaryTemplateApi = useApiGen(CommentaryTemplateControllerApiFactory);

	const underlineStyle = useMemo<{ left?: number; width?: number }>(() => {
		const selectedTab = tabTitlesContainer?.children[tabIndex] as HTMLButtonElement | undefined;
		if (!selectedTab) {
			return stableEmptyObject;
		}
		return {
			left: selectedTab.offsetLeft,
			width: selectedTab.offsetWidth,
		};
	}, [tabIndex, tabTitlesContainer]);

	const commentaryQuery = useQueryNoRefetch(
		["portfolioCommentaryWidget", portfolio?.uuid, enhanced, portfolio?.status, reportExcutionCounter],
		{
			enabled: Boolean(portfolio),
			queryFn: async () => {
				const commentary = await axiosExtract(
					enhanced
						? enhanceInvestmentReportApi.getCommentaries1(portfolio?.uuid ?? "")
						: investmentReportApi.getCommentaries(portfolio?.uuid ?? ""),
				);

				if (
					(commentary.status === "CALCULATING" || portfolio?.status === "CALCULATING") &&
					(!commentary.commentary || !commentary.shortCommentary)
				) {
					return {
						data: undefined,
						widgetStatus: WidgetStatus.CALCULATING as const,
					};
				}

				if (!commentary.commentary && !commentary.shortCommentary) {
					return {
						data: undefined,
						widgetStatus: WidgetStatus.EMPTY as const,
					};
				}
				const templates = await axiosExtract(commentaryTemplateApi.getTemplateList());
				return {
					data: { ...commentary, templates },
					widgetStatus: WidgetStatus.READY as const,
				};
			},
			onError: (e) => console.warn(e),
		},
	);

	const { data: commentaryResponse } = commentaryQuery;

	const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
	useEventBus("commentary-update", {
		filter: objMatchFn({ uuid: portfolio?.uuid }),
		listener: () => {
			timeoutIdRef.current = setTimeout(
				unpromisify(async () => {
					await thinkingBoxAnimation();
					setIsGeneratingCommentary(false);
					resetEvents();

					if (commentaryGenerationProgress) {
						setAnimationProgress((animations) => animations.filter((animation) => animation.id !== portfolio?.uuid));
					}

					await commentaryQuery.refetch();
				}),
				3000,
			);
		},
	});

	useEffect(
		() => () => {
			if (timeoutIdRef.current !== null) {
				clearTimeout(timeoutIdRef.current);
			}
		},
		[],
	);

	useEffect(() => {
		const uuid = portfolio?.uuid;
		if (commentaryGenerationProgress !== undefined && uuid && commentaryQuery.data) {
			if (commentaryResponse?.data?.status && commentaryResponse?.data?.status !== "CALCULATING") {
				setAnimationProgress((animations) => animations.filter((animation) => animation.id !== uuid));

				return;
			}

			if (commentaryResponse?.data?.status === "CALCULATING") {
				unpromisify(async () => {
					try {
						setIsGeneratingCommentary(true);
						await waitForEvent("commentary-update", {
							filter: objMatchFn({ uuid }),
							signal: AbortSignal.timeout(60000),
						});
					} catch (error) {
						await thinkingBoxAnimation();
						const { data } = await commentaryQuery.refetch();
						if (data?.data?.status === "ERROR") {
							setCommentaryGenerationStatus("ERROR");
						}
						resetEvents();
						setIsGeneratingCommentary(false);
						throw error;
					} finally {
						setAnimationProgress((animations) => animations.filter((animation) => animation.id !== uuid));
					}
				})();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [commentaryResponse?.data?.status]);

	const { setWidgetOptions } = useContext(WidgetBlockContext);
	const { push } = useTypedNavigation();

	useEffect(() => {
		setWidgetOptions({
			actionHeader: () =>
				hasAccess(user, { requiredServices: ["COMMENTARY_BUILDER", "PORTFOLIO_STUDIO_COMMENTARY_TAB"] }) ? (
					<Text
						color={themeCSSVars.palette_P400}
						type="Body/M/Bold"
						as="button"
						onClick={() =>
							push("PortfolioDetails", {
								portfolioUid: portfolio?.uuid ?? "",
								proposal: String(enhanced),
								tab: PortfolioDetailsTabs.COMMENTARY,
							})
						}
					>
						Open in tab
					</Text>
				) : (
					<></>
				),
			title: enhanced ? t("PROPOSAL_COMMENTARY.TITLE") : t("CURRENT_COMMENTARY.TITLE"),
			// TODO: based on API response
			/* someCondition ? [
						{
							variant: "warning",
							title: "Commentary may be out of date",
							content: "Some of the descriptions for the instruments contained in this portfolio are expired.",
						},
				  ] : */
			alerts: [],
			alertsActive: true,
			alertsFootnote: (
				<div>
					<Text type="Body/M/Book">Customize your</Text>{" "}
					<ActionText
						type="Body/M/Book"
						href={typedUrlForRoute("PortfolioStudioSettings", {
							tab: PortfolioStudioSettingTabEnum.InstrumentsCustomisation,
						})}
					>
						instrument descriptions
					</ActionText>
				</div>
			),
		});
	}, [t, setWidgetOptions, enhanced, user, push, portfolio?.uuid]);

	const defaultFakeLoader = useMemo(
		() =>
			commentaryGenerationProgress
				? simulateAnimationProgress(
						commentaryGenerationProgress.date,
						commentaryGenerationProgress.progress,
						commentaryGenerationProgress.step,
						commentaryGenerationProgress.step !== "preparing" ? 0.175 : 0.02,
				  )
				: undefined,
		[commentaryGenerationProgress],
	);

	async function onGenerateCommentary() {
		try {
			setIsGeneratingCommentary(true);
			if (enhanced) {
				investmentEnhancementV4Api.createCommentaryForEnhancement(portfolio!.uuid!).catch(noop);
			} else {
				investmentV4Api.createCommentary(portfolio!.uuid!).catch(noop);
			}

			await waitForEvent("commentary-update", {
				filter: objMatchFn({ uuid: portfolio?.uuid ?? "" }),
				signal: AbortSignal.timeout(60000),
			});
		} catch (error) {
			if (commentaryGenerationAbortController.signal.aborted) {
				resetEvents();
			} else {
				await thinkingBoxAnimation();
			}
			const { data } = await commentaryQuery.refetch();
			if (data?.data?.status === "ERROR") {
				setCommentaryGenerationStatus("ERROR");
			}
			setIsGeneratingCommentary(false);
			throw error;
		}
	}

	async function onGenerateCommentaryV2(templateUuid: string) {
		try {
			let sanitaizedTemplateUuid = templateUuid;
			const templates = commentaryResponse?.data?.templates;
			if (templates && !templates.find((x) => x.uuid === templateUuid)) {
				sanitaizedTemplateUuid = templates.find((x) => x.name === "Standard Template")?.uuid ?? templateUuid;
			}

			setIsGeneratingCommentary(true);
			if (enhanced) {
				investmentEnhancementV4Api
					.createCommentaryForEnhancementWithTemplate(portfolio!.uuid!, sanitaizedTemplateUuid)
					.catch(noop); //TODO: change
			} else {
				investmentV4Api.createCommentaryFromTemplateUUID(portfolio!.uuid!, sanitaizedTemplateUuid).catch(noop);
			}

			await waitForEvent("commentary-update", {
				filter: objMatchFn({ uuid: portfolio?.uuid ?? "" }),
				signal: AbortSignal.timeout(60000),
			});
		} catch (error) {
			if (commentaryGenerationAbortController.signal.aborted) {
				resetEvents();
			} else {
				await thinkingBoxAnimation();
			}
			const { data } = await commentaryQuery.refetch();
			if (data?.data?.status === "ERROR") {
				setCommentaryGenerationStatus("ERROR");
			}
			setIsGeneratingCommentary(false);
			throw error;
		}
	}

	return (
		<div className="h-full flex flex-col">
			<PortfolioQueryWidgetBase
				query={commentaryQuery}
				errorFallback="Generative AI commentary is not available at the moment. It will be generated soon!"
				iconWalls={{
					empty: IconWalls.PortfolioCommentaryEmpty({
						onGenerate: async () => {
							try {
								if (enhanced) {
									investmentEnhancementV4Api.createCommentaryForEnhancement(portfolio!.uuid!).catch(noop);
								} else {
									investmentV4Api.createCommentary(portfolio!.uuid!).catch(noop);
								}

								await waitForEvent("commentary-update", {
									filter: objMatchFn({ uuid: portfolio?.uuid ?? "" }),
									signal: AbortSignal.timeout(60000),
								});
							} catch (error) {
								if (commentaryGenerationAbortController.signal.aborted) {
									resetEvents();
								} else {
									await thinkingBoxAnimation();
								}
								const { data } = await commentaryQuery.refetch();
								if (data?.data?.status === "ERROR") {
									setCommentaryGenerationStatus("ERROR");
								}
								throw error;
							}
						},
					}),
				}}
			>
				{(response) => {
					const { commentary, shortCommentary, commentaryDate, templates, template } = response;

					if (hasAccess(user, { requiredService: "COMMENTARY_BUILDER" })) {
						return (
							<PortfolioCommentaryBodyMDBlockV2
								onGenerate={onGenerateCommentaryV2}
								ctx={ctx}
								data={response}
								templates={templates}
								selected={template!.uuid!}
								commentaryGenerationStatus={commentaryGenerationStatus}
								isGeneratingCommentary={isGeneratingCommentary}
								loaderProps={{
									signal: commentaryGenerationAbortController.signal,
									onDone: () => commentaryGenerationDoneEmitter.emit("done"),
									defaultValue: defaultFakeLoader,
									persist: (progress, step) => {
										const uuid = portfolio?.uuid;
										if (uuid) {
											setAnimationProgress((prevAnimationProgress) => {
												const indexOfAnimation = prevAnimationProgress.findIndex((animation) => animation.id === uuid);

												if (indexOfAnimation > -1) {
													prevAnimationProgress[indexOfAnimation].progress = progress;
													prevAnimationProgress[indexOfAnimation].step = step;
													prevAnimationProgress[indexOfAnimation].date = new Date();
													return prevAnimationProgress;
												}

												return [...prevAnimationProgress, { id: uuid, progress, step, date: new Date() }];
											});
										}
									},
								}}
							/>
						);
					}

					return (
						<>
							<div className="relative">
								<div className="flex items-center space-x-6 pb-2" ref={setTabTitlesContainer}>
									<button type="button" onClick={() => setTabIndex(0)} className="flex items-center space-x-2">
										<Icon
											icon="commentary-bulletpoint"
											classList="[&_*]:transition-[fill]"
											size={16}
											color={tabIndex === 0 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
										/>
										<Text
											classList="transition-colors"
											type="Body/M/Bold"
											color={tabIndex === 0 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
										>
											Summary
										</Text>
									</button>

									<button type="button" onClick={() => setTabIndex(1)} className="flex items-center space-x-2">
										<Icon
											icon="News-category-isntruments"
											classList="[&_*]:transition-[fill]"
											size={16}
											color={tabIndex === 1 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
										/>
										<Text
											classList="transition-colors"
											type="Body/M/Bold"
											color={tabIndex === 1 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
										>
											Full Commentary
										</Text>
									</button>
								</div>
								<div className="absolute -bottom-[1px] w-full h-[3px]">
									<div
										className={`transition-[left,width] absolute z-0 inset-y-0 bg-[${themeCSSVars.palette_P500}]`}
										style={underlineStyle}
									/>
								</div>
							</div>
							<div className="grow relative">
								<Transition
									in={tabIndex === 0}
									duration={1000}
									classList="absolute z-0 inset-0 transition-opacity"
									enterFromClassList="opacity-0"
									enterToClassList="opacity-100"
									exitedClassList="hidden"
								>
									{({ classList }) => (
										<div className={toClassName({ ...classList, "flex flex-col h-full": true })}>
											<ScrollWrapper classList="pt-4">
												{/* {isFetching ? (
													<div className="h-80 flex">
														<CircularProgressBar value="indeterminate" classList="m-auto" />
													</div>
												) : ( */}
												<MarkdownRenderer>{shortCommentary ?? ""}</MarkdownRenderer>
												{/* )} */}
											</ScrollWrapper>
										</div>
									)}
								</Transition>
								<Transition
									in={tabIndex === 1}
									duration={1000}
									classList="absolute z-0 inset-0 transition-opacity"
									enterFromClassList="opacity-0"
									enterToClassList="opacity-100"
									exitedClassList="hidden"
								>
									{({ classList }) => (
										<div className={toClassName({ ...classList, "flex flex-col h-full": true })}>
											<ScrollWrapper classList="pt-4">
												{/* {isFetching ? (
													<div className="h-80 flex">
														<CircularProgressBar value="indeterminate" classList="m-auto" />
													</div>
												) : ( */}
												<MarkdownRenderer componentOverrides={markdownOverrides}>{commentary ?? ""}</MarkdownRenderer>
												{/* )} */}
											</ScrollWrapper>
										</div>
									)}
								</Transition>
							</div>
							<AuthorizationGuard
								permissionChecker={aclByArea.portfolio.canEditComposition}
								acl={portfolio?.richAcl?.acl ?? []}
							>
								{isGeneratingCommentary ? (
									<FakeAiLoader
										signal={commentaryGenerationAbortController.signal}
										onDone={() => commentaryGenerationDoneEmitter.emit("done")}
										defaultValue={defaultFakeLoader}
										persist={(progress, step) => {
											const uuid = portfolio?.uuid;
											if (uuid) {
												setAnimationProgress((prevAnimationProgress) => {
													const indexOfAnimation = prevAnimationProgress.findIndex(
														(animation) => animation.id === uuid,
													);

													if (indexOfAnimation > -1) {
														prevAnimationProgress[indexOfAnimation].progress = progress;
														prevAnimationProgress[indexOfAnimation].step = step;
														prevAnimationProgress[indexOfAnimation].date = new Date();
														return prevAnimationProgress;
													}

													return [...prevAnimationProgress, { id: uuid, progress, step, date: new Date() }];
												});
											}
										}}
									/>
								) : (
									<div className="pt-2 flex items-center justify-between space-x-2">
										{commentaryGenerationStatus === "ERROR" ? (
											<Banner severity="warning" classList="flex-1">
												Commentary not generated, try again.
											</Banner>
										) : (
											<div className="flex space-x-1 items-center">
												{commentaryDate && (
													<>
														<Icon icon="Clock" color={themeCSSVars.palette_N300} size={16} />
														<p className="whitespace-pre-line">
															<Text type="Body/S/Book">Generated on </Text>
															<Text type="Body/S/Bold">
																{formatDate(commentaryDate, { hour: "2-digit", minute: "2-digit" })} CET
															</Text>
														</p>
													</>
												)}
											</div>
										)}
										{!enhanced &&
											hasAccess(user, {
												acl: portfolio?.richAcl?.acl ?? [],
												permissionChecker: aclByArea.portfolio.canEditComposition,
											}) && (
												<div className="flex items-center gap-2">
													<AsyncButton
														disabled={portfolio?.status === "ERROR"}
														palette="primary"
														size="small"
														onClickAsync={() => onGenerateCommentary()}
													>
														Generate
													</AsyncButton>
												</div>
											)}
									</div>
								)}
							</AuthorizationGuard>
						</>
					);
				}}
			</PortfolioQueryWidgetBase>
		</div>
	);
};

const markdownOverrides: MarkdownRendererProps["componentOverrides"] = {
	table: ({ node: _node, ...props }) => <table className="w-full border-collapse" {...props} />,
	thead: ({ node: _node, ...props }) => <thead {...props} />,
	tr: ({ node: _node, ...props }) => (
		<tr className={`even:bg-[#F7F8F9] border-b border-b-[color:${themeCSSVars.palette_N100}]`} {...props} />
	),
	td: ({ node: _node, ...props }) => <td className="text-left p-2 !text-[10px]" {...props} />,
	th: ({ node: _node, ...props }) => (
		<th className="text-left px-2 py-1 !font-bold !text-[10px] !uppercase text-[#667085]" {...props} />
	),
	tbody: ({ node: _node, ...props }) => <tbody {...props} />,
};

export default withContext(PortfolioContext)(PortfolioCommentaryMDBlock);
