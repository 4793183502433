import type { FC } from "react";
import { useMemo } from "react";

import { formatDate } from "$root/localization/formatters";
import { PaletteColors } from "$root/styles/themePalette";
import { addDays, differenceInDays, parseISO } from "date-fns";
import NewWidgetStandardTooltip from "../Tooltips/NewWidgetStandardTooltip";
import "./style.scss";

export type PositioningGraphSector = "FIRST" | "SECOND" | "THIRD" | "FOURTH" | "FIFTH";

type PositioningGraphProps = {
	current: number;
	prev?: number;
	index: number;
	lastDashboardUpdate: string;
	reverseGraph?: boolean;
	tooltip?: Array<{ label: string; value: string; data?: string }>;
	tootlipTitle?: string;
};

type PositioninGraphTooltipProps = {
	color: string;
	lastDashboardUpdate: string;
	tooltip: Array<{ label: string; value: string; data?: string }>;
};

const PositioningGraphSectorMap: Record<number, PositioningGraphSector> = {
	1: "FIRST",
	2: "SECOND",
	3: "THIRD",
	4: "FOURTH",
	5: "FIFTH",
};

export const CurrentPositionTooltipIcon: FC<{ color: string }> = ({ color }) => {
	return (
		<div
			style={{
				width: 10,
				height: 10,
				backgroundColor: `${color}`,
				borderRadius: "5px",
				zIndex: 1,
			}}
			className="shadow"
		/>
	);
};

export const PreviousPositionTooltipIcon: FC<{ color: string }> = ({ color }) => {
	return <div style={{ width: 10, height: 10, border: `2px solid ${color}`, borderRadius: "5px", zIndex: 1 }} />;
};

export const PositioninGraphTooltip: FC<PositioninGraphTooltipProps> = ({ color, tooltip, lastDashboardUpdate }) => {
	const helperDateFn = (currentDate?: string, previousDate?: string) => {
		if (!currentDate || !previousDate) {
			return { duration: "-" };
		}
		const duration = differenceInDays(
			new Date(new Date(currentDate).toISOString().split("T")[0]),
			new Date(new Date(previousDate).toISOString().split("T")[0]),
		);
		return { duration };
	};

	const MapIcon: Record<"Current" | "Previous", React.ElementType<{ color: string }>> = {
		Current: ({ color: iconColor }) => <CurrentPositionTooltipIcon color={iconColor} />,
		Previous: () => <PreviousPositionTooltipIcon color={PaletteColors.SANTAS_GREY} />,
	};

	const current = tooltip.find(({ label }) => label.toUpperCase() === "CURRENT");
	const previous = tooltip.find(({ label }) => label.toUpperCase() === "PREVIOUS");

	// Get Limit for Previous Date
	const previousEndDate = current?.data ? addDays(parseISO(current.data), -1) : undefined;

	// const { duration } = helperDateFn(currentWidgetsData.creation_time, current?.data);
	const { duration } = helperDateFn(lastDashboardUpdate, current?.data);
	const { duration: previousDuration } = helperDateFn(String(previousEndDate), previous?.data);

	// Current duration adjusted + 1, because of wur
	const adjustedDuration = duration === "-" ? duration : Number(duration) + 1;

	const formattedDate = formatDate(current?.data ?? "");
	const previousFormattedDate = formatDate(previous?.data ?? "");
	const previousFormattedEndDate = formatDate(previousEndDate ?? "");

	const CurrentIcon = MapIcon["Current"];
	const PreviousIcon = MapIcon["Previous"];

	return (
		<div>
			{current && (
				<article>
					<div className="relative">
						<div className="absolute top-[4px] left-[0px] drop-shadow-xl">
							<CurrentIcon color={color} />
						</div>
						<div className="flex-column ml-[14px]">
							<p className="m-0 text-[#1D2433] text-[12px] leading-[16px]">
								Current: <strong className="pl-[4px]">{current?.value}</strong>
							</p>
							<p className="text-[#667085] text-[10px] leading-[14px]">
								Since: {`${formattedDate} (${adjustedDuration}day${Number(adjustedDuration) > 1 ? "s" : ""})`}
							</p>
						</div>
					</div>
				</article>
			)}

			{previous && (
				<article className="mt-[8px]">
					<div className="relative">
						<div className="absolute top-[4px] left-[0px] drop-shadow-xl">
							<PreviousIcon color={color} />
						</div>
						<div className="flex-column ml-[14px]">
							<p className="m-0 text-[#1D2433] text-[12px] leading-[16px]">
								Previous: <strong className="pl-[4px]">{previous?.value}</strong>
							</p>
							<p className="text-[#667085] text-[10px] leading-[14px]">
								From:{" "}
								{`${previousFormattedDate} to ${previousFormattedEndDate} (${previousDuration}day${
									Number(previousDuration) > 1 ? "s" : ""
								})`}
							</p>
						</div>
					</div>
				</article>
			)}
		</div>
	);
};

const PrimaryCircle: FC<{ value?: number; columnNumber: number; color: string }> = (props) => {
	if (props.value === undefined) {
		return <></>;
	}

	const shadowMap = {
		[PaletteColors.SANTAS_GREY]: "santas_grey",
		[PaletteColors.AZURE]: "azure",
		[PaletteColors.NEON_RED]: "neon_red",
		[PaletteColors.GREENY_BLUE]: "grenny_blue",
	};

	const cx = Math.floor((100 / (props.columnNumber - 1)) * (props.value - 1));

	return (
		<g>
			<circle cx={`${cx}%`} cy="50%" r="9" fill="transparent" className="currentValue__hidden" />
			<circle cx={`${cx}%`} cy="50%" r="5.5" fill={props.color} className="currentValue" />
			<circle
				cx={`${cx}%`}
				cy="50%"
				r="10"
				fill="transparent"
				className="currentValue__ring"
				data-variable={shadowMap[props.color]}
			/>
		</g>
	);
};

const SecondaryCircle: FC<{ value?: number; columnNumber: number; color: string }> = (props) => {
	if (props.value === undefined) {
		return <></>;
	}
	const cx = Math.floor((100 / (props.columnNumber - 1)) * (props.value - 1));

	return (
		<g>
			<circle cx={`${cx}%`} cy="50%" r="4" fill={props.color} />
			<circle cx={`${cx}%`} cy="50%" r="2" fill="white" />
			<circle cx={`${cx}%`} cy="50%" r="5" fill="transparent" className="previousValue__ring" />
		</g>
	);
};

const PositioninGraph: FC<PositioningGraphProps> = ({
	index,
	current,
	prev,
	reverseGraph,
	tooltip,
	tootlipTitle,
	lastDashboardUpdate,
}) => {
	const mappedCurrent = PositioningGraphSectorMap[current];
	const currentValueRect: Record<PositioningGraphSector, { x: string; w: string }> = {
		FIRST: { x: "0%", w: "50%" },
		SECOND: { x: "25%", w: "25%" },
		THIRD: { x: "0%", w: "0%" },
		FOURTH: { x: "50%", w: "25%" },
		FIFTH: { x: "50%", w: "50%" },
	};

	const iconColor: Record<PositioningGraphSector, string> = useMemo(
		() => ({
			FIRST: reverseGraph ? PaletteColors.GREENY_BLUE : PaletteColors.NEON_RED,
			SECOND: reverseGraph ? PaletteColors.GREENY_BLUE : PaletteColors.NEON_RED,
			THIRD: PaletteColors.AZURE,
			FOURTH: reverseGraph ? PaletteColors.NEON_RED : PaletteColors.GREENY_BLUE,
			FIFTH: reverseGraph ? PaletteColors.NEON_RED : PaletteColors.GREENY_BLUE,
		}),
		[reverseGraph],
	);

	return (
		<NewWidgetStandardTooltip
			key={index}
			content={
				<PositioninGraphTooltip
					color={iconColor[mappedCurrent]}
					tooltip={tooltip ?? []}
					lastDashboardUpdate={lastDashboardUpdate}
				/>
			}
			title={tootlipTitle}
		>
			{({ innerRef }) => (
				<div ref={innerRef} className="w-full h-full px-4 relative positioningGraph flex items-center">
					<svg height="70%" width="100%" overflow="visible">
						<g stroke="rgba(159, 159, 163, 1)" strokeDasharray="3" strokeLinecap="round" strokeWidth="1">
							<line x1="0" y1="0" x2="0" y2="100%" />
							<line x1="25%" y1="0" x2="25%" y2="100%" />
							<line x1="50%" y1="0" x2="50%" y2="100%" strokeDasharray="0" strokeWidth="2" />
							<line x1="75%" y1="0" x2="75%" y2="100%" />
							<line x1="100%" y1="0" x2="100%" y2="100%" />
						</g>

						<rect
							fill={iconColor[mappedCurrent]}
							x={currentValueRect[mappedCurrent].x}
							width={currentValueRect[mappedCurrent].w}
							y="31%"
							height="11"
							opacity={0.3}
						/>

						<PrimaryCircle color={iconColor[mappedCurrent]} columnNumber={5} value={current} />
						<SecondaryCircle color={PaletteColors.SANTAS_GREY} columnNumber={5} value={prev} />
					</svg>
				</div>
			)}
		</NewWidgetStandardTooltip>
	);
};

export default PositioninGraph;
