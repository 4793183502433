import type {
	CommentaryModules,
	CommentaryTemplateModel,
	CommentaryTemplateModelLanguageEnum,
	CommentaryTemplateModelToneOfVoiceEnum,
} from "$root/api/api-gen";
import { CommentaryTemplateControllerApiFactory, EntityEditorControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import Texture from "$root/assets/images/texture.png";
import { IconWallBase } from "$root/components/IconWall";
import { LeavePrompt } from "$root/components/LeavePrompt";
import type { MarkdownRendererProps } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { MarkdownRenderer } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import RoundedButton from "$root/components/RoundedButton";
import { Sidebar } from "$root/components/Sidebar";
import { $createPillNode, PillNode } from "$root/stories/components/RichTextEditor/lexical/pills/PillNode";
import PillsPlugin from "$root/stories/components/RichTextEditor/lexical/pills/PillPlugin";
import type { Pill } from "$root/stories/components/RichTextEditor/lexical/pills/common";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { customObjectEntriesFn, customObjectValuesFn } from "$root/utils/experimental";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import type { ActionOrActionWithGroup } from "@mdotm/mdotui/components";
import {
	AsyncButton,
	Button,
	CollapsibleBase,
	Controller,
	DropdownMenu,
	DropdownMenuActionButton,
	ErrorBoundary,
	Icon,
	ProgressBar,
	Select,
	Text,
	TextArea,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import {
	adaptAnimatedNodeProvider,
	spawn,
	useRefProxyWithState,
	useUniqueDOMId,
	useUpdatedRef,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { nullary } from "@mdotm/mdotui/utils";
import type { LexicalEditor, SerializedEditorState, SerializedElementNode, SerializedLexicalNode } from "lexical";
import { $createParagraphNode, $createTextNode, $getRoot, $getSelection, ParagraphNode } from "lexical";
import type { MutableRefObject } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import CommentaryLoading from "./box/CommentaryReady";
import type { AllowedModules, StableConfiguration } from "./common";
import { stableDefaultConfiguration } from "./common";
import {
	InstumentInsightsModule,
	MarketOulookModule,
	PortfolioCompositionModule,
	PortfolioDynamicModule,
	PortfolioMetricsModule,
} from "./storyfolio-components";

function lexicalEditorStateToMarkdown(JSONEditorState: SerializedEditorState<SerializedLexicalNode>) {
	const lexialStateToJSON = JSONEditorState.root.children as SerializedElementNode[] | undefined;

	if (lexialStateToJSON) {
		const lexicalNodesToMarkdown = lexialStateToJSON.reduce((description, lexicalNode, i) => {
			const mainDescription = lexicalNode.children.reduce((subDescription, subLexicalNode) => {
				if (subLexicalNode.type === "text") {
					const textNode = subLexicalNode as SerializedLexicalNode & { text: string };

					if (textNode.text === "\n") {
						return (subDescription += "\n");
					}

					return (subDescription += textNode.text);
				}

				if (subLexicalNode.type === "linebreak") {
					return (subDescription += "\n");
				}

				if (subLexicalNode.type === "pill") {
					const pillNode = subLexicalNode as SerializedLexicalNode & { text: string } & {
						pill: Pill<CommentaryModules>;
					};
					return (subDescription += `[[${pillNode.pill.value}]]`);
				}

				return subDescription;
			}, "");

			if (i > 0) {
				description += "\n";
			}

			description += mainDescription;

			return description;
		}, "");

		return lexicalNodesToMarkdown;
	}

	return "";
}

const customModules: Record<CommentaryModules, { label: string; value: CommentaryModules }> = {
	INSTRUMENT_INSIGHTS: {
		label: "#Instrument Insights",
		value: "INSTRUMENT_INSIGHTS",
	},
	MARKET_OUTLOOK: {
		label: "#Market Outlook",
		value: "MARKET_OUTLOOK",
	},
	PORTFOLIO_COMPOSITION: {
		label: "#Portfolio Composition",
		value: "PORTFOLIO_COMPOSITION",
	},
	PORTFOLIO_DYNAMICS: {
		label: "#Portfolio Dynamics",
		value: "PORTFOLIO_DYNAMICS",
	},
	PORTFOLIO_METRICS: {
		label: "#Portfolio Metrics",
		value: "PORTFOLIO_METRICS",
	},
};

const customModulesFieldsName = {
	INSTRUMENT_INSIGHTS: "instrumentInsightsBlock",
	MARKET_OUTLOOK: "marketOutlook",
	PORTFOLIO_COMPOSITION: "portfolioCompositionBlock",
	PORTFOLIO_DYNAMICS: "dynamicsBlock",
	PORTFOLIO_METRICS: "metricsBlock",
} satisfies Record<CommentaryModules, AllowedModules>;

const availablePills = customObjectValuesFn(customModules);

function StoryfolioInner(props: {
	template: CommentaryTemplateModel;
	list: CommentaryTemplateModel[];
	onSubmit: (template: CommentaryTemplateModel) => MaybePromise<void>;
	onChangeTemplate: (uuid: string | null) => void;
}): JSX.Element {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const lexicalEditorHandlerRef = useRef<LexicalEditor | null>(null);
	const [isEditorDirty, setIsEditorDirty] = useState(false);
	const [editor] = useRefProxyWithState(null, lexicalEditorHandlerRef);
	const { push } = useTypedNavigation();

	const { setValue, watch, getValues, handleSubmit, formState } = useForm({
		defaultValues: props.template,
	});

	const observedTemplate = watch();
	const templateListPool = useMemo(
		() => props.list.flatMap(({ name, uuid }) => (name && uuid ? [{ label: name, value: uuid }] : [])),
		[props.list],
	);

	useEffect(() => {
		const unregister = lexicalEditorHandlerRef.current?.registerUpdateListener(({ editorState }) => {
			editorState.read(() => {
				const selection = $getSelection();
				if (selection?.dirty) {
					setIsEditorDirty(true);
				}
			});
		});

		return () => unregister?.();
	}, [editor]);

	return (
		<>
			<PageHeader
				title={observedTemplate.name ?? "New Template"}
				crumbs={[
					{
						children: "Storyfolio",
						href: typedUrlForRoute("Storyfolio/Studio", {}),
					},
					{
						children: (
							<Select
								classList="flex items-center w-40 truncate"
								strategy="fixed"
								value={props.template.uuid!}
								onChange={props.onChangeTemplate}
								enableSearch
								unstyled
								options={templateListPool}
							/>
						),
					},
				]}
				titleAction={
					<div className="flex justify-end items-center gap-2 py-2.5">
						<Button onClick={() => push("Storyfolio/Studio", {})} size="small" palette="tertiary">
							Cancel
						</Button>
						<AsyncButton
							palette="primary"
							size="small"
							onClickAsync={() =>
								handleSubmit(async (values) => {
									setIsEditorDirty(false);
									await props.onSubmit({
										...values,
										structure: lexicalEditorHandlerRef.current?.getEditorState().toJSON()
											? lexicalEditorStateToMarkdown(lexicalEditorHandlerRef.current.getEditorState().toJSON())
											: values.structure,
									});
								})
							}
						>
							{observedTemplate.default ? "Save as" : "Save"}
						</AsyncButton>
					</div>
				}
			/>
			<div className="h-[calc(100dvh_-_165px)] rounded-md bg-white">
				<div className="flex space-x-2 h-full">
					<div
						className={`overflow-y-auto flex-1 flex flex-col border-r border-r-[${themeCSSVars.palette_N100}] h-full`}
					>
						<StyleBlock
							onChangeContext={(ctx) => setValue("context", ctx)}
							onChangeToneOfVoice={(tone) => {
								const currentTone = getValues("toneOfVoice");
								if (currentTone === tone) {
									return setValue("toneOfVoice", undefined);
								}

								setValue("toneOfVoice", tone);
							}}
							// onChangeLanguage={(language) => {
							// 	const currentLanguage = getValues("language");
							// 	if (currentLanguage === language) {
							// 		return setValue("language", undefined);
							// 	}

							// 	setValue("language", language);
							// }}
							onChangeLanguage={(language) => setValue("language", language)}
							template={observedTemplate}
						/>
						<ContenStructureBlock
							template={observedTemplate}
							onModuleClick={({ value }) => {
								const newSearchParams = new URLSearchParams(location.search);
								newSearchParams.set("module", value);
								history.replace({ search: newSearchParams.toString() });
								spawnStoryfolioModuleSettings({
									type: customModulesFieldsName[value],
									module: stableDefaultConfiguration[customModulesFieldsName[value]](
										getValues(customModulesFieldsName[value]) as any,
									) as any,
									onChange: (module: any) => setValue(customModulesFieldsName[value], module),
									onCancel: (reject) => {
										history.replace({ search: undefined });
										if (history.location.search === "" || history.location.search === undefined) {
											reject?.();
										}
									},
								});
							}}
							onAddModule={(module, payload) => setValue(module, payload as any)}
							onRemoveModule={(module, payload) => setValue(module, payload as any)}
							lexicalRef={lexicalEditorHandlerRef}
						/>
					</div>

					<div className="flex-1">
						<StoryfolioPreviewBlock template={observedTemplate} lexicalRef={lexicalEditorHandlerRef} />
					</div>
				</div>
			</div>
			<LeavePrompt
				title={t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.TITLE")}
				when={formState.isDirty || isEditorDirty}
				showCancelIcon={false}
				pathToNotBlock={[history.location.pathname, history.location.pathname + history.location.search]}
			>
				{t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.MESSAGE")}
			</LeavePrompt>
		</>
	);
}

function StyleBlock(props: {
	onChangeToneOfVoice(tone: CommentaryTemplateModelToneOfVoiceEnum): void;
	onChangeLanguage(languange: CommentaryTemplateModelLanguageEnum): void;
	onChangeContext(context: string): void;
	template: CommentaryTemplateModel;
}) {
	const { template } = props;
	const [expandContext, setExpandContext] = useState(false);
	const commentaryTemplateApi = useApiGen(CommentaryTemplateControllerApiFactory);
	const { t } = useTranslation();

	return (
		<div className={`p-4 border-b border-b-[${themeCSSVars.palette_N100}]`}>
			<div className="flex items-center mb-2">
				<Text type="Body/XL/Bold" classList="mr-2">
					Style
				</Text>
				<Button unstyled onClick={() => setExpandContext((expand) => !expand)} classList="flex space-x-2 items-center">
					Edit text context <Icon icon={expandContext ? "Up" : "Down"} color={themeCSSVars.palette_N200} />
				</Button>

				<div className="flex space-x-2 ml-auto mr-0">
					<ReactQueryWrapper
						queryKey={["queryCommentaryTemplateLanguage"]}
						queryFn={() => axiosExtract(commentaryTemplateApi.getLanguages())}
						loadingFallback={
							<RoundedButton classList="cursor-progress animate-pulse" disabled size="small">
								<div className="flex gap-2">
									<Icon icon="Language" size={18} />
									Languages
								</div>
							</RoundedButton>
						}
					>
						{(languages) => (
							<DropdownMenu
								trigger={({ innerRef, onClick }) => (
									<RoundedButton innerRef={innerRef} onClick={onClick} size="small">
										<div className="flex gap-2">
											<Icon icon="Language" size={18} />
											Languages
										</div>
									</RoundedButton>
								)}
								actions={languages.map((language) => ({
									children: ({ onClose }) => (
										<DropdownMenuActionButton
											classList={{ [`bg-[color:${themeCSSVars.palette_P400}]`]: template.language === language }}
											onClick={() => {
												props.onChangeLanguage(language as CommentaryTemplateModelLanguageEnum);
												onClose();
											}}
											icon={template.language === language ? "Outline" : undefined}
										>
											{t(`LANGUAGES.${language as CommentaryTemplateModelLanguageEnum}`)}
										</DropdownMenuActionButton>
									),
								}))}
							/>
						)}
					</ReactQueryWrapper>

					<ReactQueryWrapper
						queryKey={["queryToneOfVoice"]}
						queryFn={() => axiosExtract(commentaryTemplateApi.getToneOfVoices())}
						loadingFallback={
							<RoundedButton classList="cursor-progress animate-pulse" disabled size="small">
								<div className="flex gap-2">
									<Icon icon="Tone-of-voice" size={18} />
									Tone of voice
								</div>
							</RoundedButton>
						}
					>
						{(toneOfVoice) => (
							<DropdownMenu
								trigger={({ innerRef, onClick }) => (
									<RoundedButton innerRef={innerRef} onClick={onClick} size="small">
										<div className="flex gap-2">
											<Icon icon="Tone-of-voice" size={18} />
											Tone of voice
										</div>
									</RoundedButton>
								)}
								actions={toneOfVoice.map((tone) => ({
									children: ({ onClose }) => (
										<DropdownMenuActionButton
											classList={{ [`bg-[color:${themeCSSVars.palette_P400}]`]: template.toneOfVoice === tone }}
											onClick={() => {
												props.onChangeToneOfVoice(tone as CommentaryTemplateModelToneOfVoiceEnum);
												onClose();
											}}
											icon={template.toneOfVoice === tone ? "Outline" : undefined}
										>
											{t(`TONE_OF_VOICE.${tone as CommentaryTemplateModelToneOfVoiceEnum}`)}
										</DropdownMenuActionButton>
									),
								}))}
							/>
						)}
					</ReactQueryWrapper>
				</div>
			</div>
			<CollapsibleBase expand={expandContext}>
				<Controller value={template.context ?? ""} onChange={props.onChangeContext}>
					{({ onChange, onCommit, value }) => (
						<TextArea
							rows={4}
							classList={{
								"[&>textarea]:resize-none overflow-y-auto": true,
							}}
							value={value}
							onChangeText={onChange}
							onBlur={nullary(onCommit)}
						/>
					)}
				</Controller>
			</CollapsibleBase>
		</div>
	);
}

const contentEditableClassName = [
	"relative w-auto h-full z-0 min-w-0 max-w-none flex-1 rounded-[4px] border-2 placeholder:text-[#80848B] font-title",
	"overflow-y-auto text-ellipsis",
	"border-[#DFE2E7] active:border-[#667085] focus:outline-none focus:border-[#667085] disabled:border-[#DFE2E7]",
	"bg-[#ffffff] disabled:border-[#DFE2E7] disabled:text-[#A0A7B6] disabled:bg-[#EFF0F3]",
	"data-[invalid=true]:border-[#E81E25] text-[14px] py-[5px] px-2",
].join(" ");

function RichTextEditor(props: {
	template: CommentaryTemplateModel;
	onModuleClick(pill: Pill<CommentaryModules>): void;
	innerRef: MutableRefObject<LexicalEditor | null>;
	onAddModule<K extends AllowedModules>(moduleType: K, payload: StableConfiguration[K]): void;
	onRemoveModule<K extends AllowedModules>(moduleType: K, payload: StableConfiguration[K]): void;
}) {
	const id = useUniqueDOMId();
	const [editor, setEditor] = useRefProxyWithState(null, props.innerRef);

	const mutationListerDataRef = useUpdatedRef({
		onRemoveModule: props.onRemoveModule,
		onAddModule: props.onAddModule,
	});

	useEffect(() => {
		const unregister = editor?.registerMutationListener(PillNode, (mutatedNodes, { prevEditorState }) => {
			for (const [nodeKey, mutation] of mutatedNodes) {
				if (mutation === "created") {
					const node = editor.getEditorState()._nodeMap.get(nodeKey) as PillNode<CommentaryModules> | undefined;
					if (node !== undefined) {
						const pill = node.__pill;
						const key = customModulesFieldsName[pill.value];
						mutationListerDataRef.current.onAddModule(key, {
							...props.template[key],
							enabled: true,
						});
					}
				}

				if (mutation === "destroyed") {
					const node = prevEditorState._nodeMap.get(nodeKey);

					if (node) {
						const value = (node as PillNode<CommentaryModules>).__pill.value;
						const key = customModulesFieldsName[value];
						mutationListerDataRef.current.onRemoveModule(key, {
							...props.template[key],
							enabled: false,
						});
					}
				}
			}
		});

		const removeUpdateListener = editor?.registerNodeTransform(PillNode<CommentaryModules>, (node) => {
			if (node.__onClick === undefined) {
				const onClickFn = () => props.onModuleClick(node.__pill);
				node.setOnClick(onClickFn);
			}
		});

		return () => {
			unregister?.();
			removeUpdateListener?.();
		};
	}, [editor, mutationListerDataRef, props, props.template]);

	const selectablePills = useMemo(
		() =>
			availablePills.map(
				(x): Pill<CommentaryModules> => ({
					...x,
					disaled: props.template[customModulesFieldsName[x.value]]?.enabled,
				}),
			),
		[props.template],
	);

	return (
		<LexicalComposer
			initialConfig={{
				namespace: id,
				onError: console.error,
				nodes: [PillNode],
				editorState: (ctx) => {
					// props.innerRef.current = editor;
					const root = $getRoot();
					if (root.getFirstChild() === null && props.template.structure) {
						// TODO: move/refactor?
						const paragraph = $createParagraphNode();

						const pillValues = Object.values(customModules).map((x) => x.value);
						const customModuleLabelsByValue = Object.fromEntries(
							Object.values(customModules).map(({ label, value }) => [value, label]),
						);
						// console.assert(!pillValues.some((p) => p.includes("|")), "pill values should not contain any | symbol");
						// const availableNodesRegex = new RegExp(`#(${pillValues.join("|")})`, "g");
						const availableNodesRegex = new RegExp(`\\[\\[(${pillValues.join("|")})\\]\\]`, "g");
						let captured: RegExpExecArray | null;
						let lastIndex = 0;
						while ((captured = availableNodesRegex.exec(props.template.structure)) !== null) {
							const pill = {
								label: customModuleLabelsByValue[captured[1]],
								value: captured[1] as CommentaryModules,
							} satisfies Pill<CommentaryModules>;

							paragraph.append($createTextNode(props.template.structure.slice(lastIndex, captured.index)));
							const pillNode = $createPillNode(pill, () => props.onModuleClick(pill));

							paragraph.append(pillNode);
							lastIndex = availableNodesRegex.lastIndex;
						}
						paragraph.append($createTextNode(props.template.structure.slice(lastIndex)));

						root.append(paragraph);
					}

					setEditor(ctx);
				},
			}}
		>
			<RichTextPlugin
				placeholder={null}
				ErrorBoundary={(errorProps) => <ErrorBoundary {...errorProps} errorChildren="Ops..." />}
				contentEditable={<ContentEditable className={contentEditableClassName} />}
			/>
			{/* <OnChangePlugin onChange={onChange} /> */}
			<HistoryPlugin />
			<PillsPlugin
				pills={selectablePills}
				onClick={(pill) => props.onModuleClick({ ...pill })}
				onSelect={({ value }) =>
					props.onAddModule(
						customModulesFieldsName[value],
						props.template[customModulesFieldsName[value]]
							? { ...props.template[customModulesFieldsName[value]], enabled: true }
							: stableDefaultConfiguration[customModulesFieldsName[value]](undefined),
					)
				}
			/>
		</LexicalComposer>
	);
}

function ContenStructureBlock(props: {
	template: CommentaryTemplateModel;
	onModuleClick(pill: Pill<CommentaryModules>): void;
	lexicalRef: MutableRefObject<LexicalEditor | null>;
	onAddModule<K extends AllowedModules>(moduleType: K, payload: StableConfiguration[K]): void;
	onRemoveModule<K extends AllowedModules>(moduleType: K, payload: StableConfiguration[K]): void;
}) {
	const actions = useMemo<ActionOrActionWithGroup<string>[]>(
		() =>
			customObjectEntriesFn(customModules).map(([, x]) => ({
				children: ({ onClose }) => (
					<DropdownMenuActionButton
						onClick={() => {
							const fieldName = customModulesFieldsName[x.value];
							props.onAddModule(
								fieldName,
								props.template[fieldName]
									? { ...props.template[fieldName], enabled: true }
									: stableDefaultConfiguration[fieldName](undefined),
							);
							props.lexicalRef.current?.update(() => {
								const module = $createPillNode(x, () => props.onModuleClick(x));
								const toAdd = [$createTextNode(" "), module, $createTextNode(" ")];

								const selection = $getSelection();
								if (selection) {
									selection.insertNodes(toAdd);
								} else {
									const root = $getRoot();
									const lastChild = root.getLastChild();
									if (lastChild && lastChild instanceof ParagraphNode) {
										lastChild.append(...toAdd);
									} else {
										const paragraph = $createParagraphNode();
										paragraph.append(...toAdd);
										root.append(paragraph);
									}
								}
							});
							onClose();
						}}
						disabled={props.template[customModulesFieldsName[x.value]]?.enabled}
					>
						{`${x.label}`}
					</DropdownMenuActionButton>
				),
			})),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.template, props.lexicalRef.current],
	);
	return (
		<div className="flex-1 flex flex-col">
			<div className="flex p-4">
				<Text type="Body/XL/Bold">Content structure</Text>

				<DropdownMenu
					trigger={({ innerRef, onClick }) => (
						<RoundedButton innerRef={innerRef} onClick={onClick} classList="ml-auto mr-0" size="small">
							<div className="flex gap-2">
								<Icon icon="blocks" size={18} />
								Add modules
							</div>
						</RoundedButton>
					)}
					actions={actions}
				/>
			</div>
			<div className="px-4 pb-4 grow">
				<RichTextEditor
					onModuleClick={props.onModuleClick}
					onAddModule={props.onAddModule}
					onRemoveModule={props.onRemoveModule}
					template={props.template}
					innerRef={props.lexicalRef}
				/>
			</div>
		</div>
	);
}

function spawnStoryfolioModuleSettings(
	params: {
		[K in AllowedModules]: {
			type: K;
			module: StableConfiguration[K];
			onChange: (module: StableConfiguration[K]) => void;
		} & { onCancel?(reject?: (err?: unknown) => void): void };
	}[AllowedModules],
) {
	const { onChange } = params;

	const map = {
		instrumentInsightsBlock: InstumentInsightsModule,
		marketOutlook: MarketOulookModule,
		portfolioCompositionBlock: PortfolioCompositionModule,
		dynamicsBlock: PortfolioDynamicModule,
		metricsBlock: PortfolioMetricsModule,
	};

	const Component = map[params.type as "dynamicsBlock"];

	function onCancel(reject?: (err?: unknown) => void) {
		params.onCancel?.(reject);
	}

	return spawn<void>(
		adaptAnimatedNodeProvider(({ onHidden, reject, resolve, show }) => (
			<Sidebar
				open={show}
				onClose={() => onCancel(reject)}
				width={440}
				mode="modal"
				onAnimationStateChange={(s) => s === "exited" && onHidden()}
			>
				<Component
					module={params.module as StableConfiguration["dynamicsBlock"]}
					onCancel={() => onCancel(reject)}
					onChange={(newInstrumentInsightsBlockModel) => {
						(onChange as (module: StableConfiguration["dynamicsBlock"]) => void)(newInstrumentInsightsBlockModel);
						resolve();
					}}
				/>
			</Sidebar>
		)),
	);
}

function StoryfolioPreviewBlock(props: {
	template: CommentaryTemplateModel;
	lexicalRef: MutableRefObject<LexicalEditor | null>;
}) {
	const [selectedInvestment, setSelectedInvestment] = useState<string | null>(null);
	const entityEditorApi = useApiGen(EntityEditorControllerApiFactory);
	const [preview, setPreview] = useState("");
	const { lexicalRef } = props;

	const delayMarkdownPrint = useCallback(
		(token: string, description: string, opt: { timeout?: number } = { timeout: 50 }) => {
			if (token.length < description.length) {
				const newToken = token + description[token.length + 1];
				if (opt.timeout! > 0) {
					setTimeout(() => {
						setPreview(newToken);
						delayMarkdownPrint(newToken, description, opt);
					}, opt.timeout);
				} else {
					setPreview(newToken);
					delayMarkdownPrint(newToken, description, opt);
				}
			}
		},
		[],
	);

	const commentaryTemplateApi = useApiGen(CommentaryTemplateControllerApiFactory);
	const commentaryPreview = useQueryNoRefetch(["queryCommentaryTemplatePreview"], {
		enabled: selectedInvestment !== null && props.template.structure !== "" && props.template.structure !== undefined,
		queryFn: () => {
			let structure: string = "";
			const JSONEditorState = lexicalRef.current?.getEditorState().toJSON();
			if (JSONEditorState) {
				structure = lexicalEditorStateToMarkdown(JSONEditorState);
			}
			return axiosExtract(
				commentaryTemplateApi.preview(selectedInvestment ?? "", {
					...props.template,
					structure: structure ?? props.template.structure,
				}),
			);
		},
	});

	useEffect(() => {
		const content = commentaryPreview.data;
		if (content) {
			setPreview(content);
		}
	}, [delayMarkdownPrint, commentaryPreview.data]);

	return (
		<>
			<div
				className="flex flex-col overflow-hidden h-full pb-3 bg-repeat"
				style={{
					backgroundImage: `url(${Texture})`,
				}}
			>
				<div className="flex p-4 mb-4 items-center space-x-2">
					<Text type="Body/XL/Bold" as="span">
						Preview on
					</Text>
					<ReactQueryWrapper
						queryKey={["selectablePortfolioTemplate"]}
						queryFn={async () => {
							const { selectablePortfolios } = await axiosExtract(
								entityEditorApi.getEditorNewSelectablePortfolios("INVESTMENT"),
							);
							return selectablePortfolios?.flatMap((x) =>
								x.name && x.uuid
									? [
											{
												label: x.name,
												value: x.uuid,
											},
									  ]
									: [],
							);
						}}
						onSuccess={(opt) => setSelectedInvestment(opt?.[0]?.value ?? null)}
						loadingFallback={
							<Select
								options={[]}
								value=""
								size="small"
								i18n={{ searchPlaceholder: () => "searching for portfolios.." }}
								disabled
								classList="cursor-progress animate-pulse w-[250px]"
							/>
						}
					>
						{(options) => (
							<Select
								options={options}
								value={selectedInvestment}
								onChange={setSelectedInvestment}
								classList="w-[250px]"
								size="small"
								i18n={{ searchPlaceholder: () => "select a portfolio.." }}
							/>
						)}
					</ReactQueryWrapper>
					<AsyncButton palette="secondary" size="small" onClickAsync={() => commentaryPreview.refetch()}>
						<Icon icon="Ask-ai" color={themeCSSVars.palette_P400} />
						Generate
					</AsyncButton>
				</div>
				<div className="px-2 pb-2 flex flex-col  flex-1 overflow-y-auto">
					<div className="flex flex-col flex-1">
						{commentaryPreview.isFetching || selectedInvestment === null ? (
							<IconWallBase>
								<ProgressBar
									value="indeterminate"
									accentColor={themeCSSVars.palette_A300}
									backgroundColor={themeCSSVars.palette_N100}
									classList="absolute top-0 inset-x-0"
									barHeight={8}
								/>
								<div className="flex flex-col">
									<h4 className="text-center">Calculating...</h4>
								</div>
							</IconWallBase>
						) : commentaryPreview.data ? (
							<div className="p-8 grow bg-white shadow-xl">
								<MarkdownRenderer componentOverrides={markdownOverrides}>{preview}</MarkdownRenderer>
							</div>
						) : (
							<div className="flex flex-col flex-1">
								<IconWallBase>
									{/** addign missing illustration */}

									<div className="text-center">
										<div className="mx-auto w-fit">
											<CommentaryLoading />
										</div>

										<Text as="p" type="Body/L/Bold">
											Preview in not available yet
										</Text>
										<Text as="p" type="Body/M/Book">
											Complete structure and press update preview
										</Text>
									</div>
								</IconWallBase>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

const markdownOverrides: MarkdownRendererProps["componentOverrides"] = {
	table: ({ node: _node, ...props }) => <table className="w-full border-collapse" {...props} />,
	thead: ({ node: _node, ...props }) => <thead {...props} />,
	tr: ({ node: _node, ...props }) => (
		<tr className={`even:bg-[#F7F8F9] border-b border-b-[color:${themeCSSVars.palette_N100}]`} {...props} />
	),
	td: ({ node: _node, ...props }) => <td className="text-left p-2 !text-[10px]" {...props} />,
	th: ({ node: _node, ...props }) => (
		<th className="text-left px-2 py-1 !font-bold !text-[10px] !uppercase text-[#667085]" {...props} />
	),
	tbody: ({ node: _node, ...props }) => <tbody {...props} />,
};

export default StoryfolioInner;
