import {
	Banner,
	Button,
	Checkbox,
	Controller,
	Dialog,
	DialogFooter,
	DialogProps,
	DraggableList,
	ScrollWrapper,
	SubmitButton,
	Text,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { SpawnResult, adaptAnimatedNodeProvider, spawn, toClassName } from "@mdotm/mdotui/react-extensions";

export type ColumnMetadata<T> = {
	id: T;
	visible: boolean;
	label: string;
	disabled?: boolean;
	hidden?: boolean;
};

export type CustomizeColumnsProps<T> = {
	show: boolean;
	onClose(): void;
	columns: ColumnMetadata<T>[];
	onSubmitAsync(columns: ColumnMetadata<T>[]): MaybePromise<void>;
	onAnimationStateChange?: DialogProps["onAnimationStateChange"];
	limit?: number;
};

export function CustomizeColumns<T extends string>({
	show,
	onClose,
	columns,
	onSubmitAsync,
	limit,
	onAnimationStateChange,
}: CustomizeColumnsProps<T>): JSX.Element {
	return (
		<Controller value={columns}>
			{({ value: list, onChange: setList }) => {
				const numberOfSelectedColumns = list.filter((x) => x.visible && x.hidden === false).length;

				return (
					<Dialog
						size="medium"
						show={show}
						onClose={onClose}
						onSubmitAsync={async () => {
							await onSubmitAsync(list);
							onClose();
						}}
						onAnimationStateChange={onAnimationStateChange}
						header="Customize columns"
						footer={
							<DialogFooter
								primaryAction={<SubmitButton>Apply</SubmitButton>}
								neutralAction={
									<Button palette="tertiary" onClick={() => onClose()}>
										Cancel
									</Button>
								}
							/>
						}
						classList="transition-all"
					>
						<div className="overflow-y-auto transition-all">
							<div className="mb-2">
								<Text type="Body/M/Bold">Select the information to showcase and determine the preferred order.</Text>
								{limit == null || limit - numberOfSelectedColumns === 0 ? null : (
									<Text type="Body/M/Bold">
										{" "}
										{limit - numberOfSelectedColumns === 1
											? `You can still pick ${limit - numberOfSelectedColumns}/${limit} more column.`
											: `You can still pick ${limit - numberOfSelectedColumns}/${limit} more columns.`}
									</Text>
								)}
							</div>
							{limit != null && (
								<div
									className={toClassName({
										"opacity-100 h-full scale-100": numberOfSelectedColumns === limit,
										"opacity-0 invisible h-0 scale-95": numberOfSelectedColumns < limit,
										"transition-all py-2": true,
									})}
								>
									<Banner severity="info" title="You have reached the limit" />
								</div>
							)}
							<ScrollWrapper classList="max-h-[400px]">
								<DraggableList items={list} onReorder={setList} itemKey={(item) => item.id}>
									{({ draggableProps, dragHandleProps, isDragging, item, innerRef }) => (
										<div
											{...draggableProps}
											className={toClassName({
												"flex py-2 border-b bg-white": true,
												"shadow-2xl": isDragging,
												hidden: item.hidden,
											})}
											ref={innerRef}
										>
											<div {...dragHandleProps} className="shrink pr-2">
												<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M5.04243 2.29406L6.96157 0.374726C7.01738 0.319102 7.10765 0.319102 7.16347 0.374726L9.08261 2.29406C9.12342 2.33487 9.13554 2.39626 9.1136 2.44958C9.09147 2.50289 9.0395 2.53773 8.98176 2.53773H5.14328C5.08554 2.53773 5.03338 2.50289 5.01144 2.44958C4.9895 2.39626 5.00162 2.33487 5.04243 2.29406ZM7.16366 13.8293C7.10785 13.885 7.01758 13.885 6.96176 13.8293L5.04243 11.91C5.00162 11.8692 4.9895 11.8078 5.01144 11.7545C5.03357 11.7012 5.08573 11.6663 5.14347 11.6663H8.98195C9.03969 11.6663 9.09166 11.7012 9.11379 11.7545C9.13574 11.8078 9.12361 11.8692 9.08281 11.91L7.16366 13.8293ZM13.3452 4.99967H0.978833C0.622469 4.99967 0.333984 5.29382 0.333984 5.65018C0.333984 6.00654 0.333984 6.30068 0.978833 6.30068H13.3339C13.7016 6.30068 13.9901 6.0122 13.9901 5.65018C13.9901 5.28816 13.7016 4.99967 13.3452 4.99967ZM0.978833 8.33301H13.3452C13.7016 8.33301 13.9901 8.62149 13.9901 8.98351C13.9901 9.34553 13.7016 9.63402 13.3339 9.63402H0.978833C0.333984 9.63402 0.333984 9.33988 0.333984 8.98351C0.333984 8.62715 0.622469 8.33301 0.978833 8.33301Z"
														fill="#8792AB"
													/>
												</svg>
											</div>
											<div className="grow">
												<Checkbox
													onChange={(checked) =>
														setList(list.map((x) => (x.id === item.id ? { ...x, visible: checked } : x)))
													}
													disabled={item.disabled || (numberOfSelectedColumns === limit && !item.visible)}
													checked={item.visible}
												>
													{item.label}
												</Checkbox>
											</div>
										</div>
									)}
								</DraggableList>
							</ScrollWrapper>
						</div>
					</Dialog>
				);
			}}
		</Controller>
	);
}

type SpawnCustomizeColumnsDialogParams<T extends string> = Omit<CustomizeColumnsProps<T>, "onClose" | "show">;
export function spawnCustomizeColumnsDialog<T extends string>(
	params: SpawnCustomizeColumnsDialogParams<T>,
): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show, onHidden }) => (
			<CustomizeColumns
				{...params}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmitAsync={async (columns) => {
					await params.onSubmitAsync(columns);
					resolve();
				}}
			/>
		)),
	);
}
