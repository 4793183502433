import type { EditorSaveEditOrReviewRequestEditPolicyEnum, ReviewTicker } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory, InvestmentEnhancementControllerV4ApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import ReviewCompositionErrors from "$root/components/ReviewCompositionErrors";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import EditCompositionSection from "$root/functional-areas/edit-composition/EditCompositionSection";
import { SmallInvestmentSummary } from "$root/functional-areas/portfolio/SmallInvestmentSummary";
import type { PortfolioAlert } from "$root/functional-areas/portfolio/alerts";
import { getPortfolioAlertVariantByType } from "$root/functional-areas/portfolio/alerts";
import { EditProxiedInstrumentSection } from "$root/functional-areas/proxies/EditProxiedInstrumentSection";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import { platformToast } from "$root/notification-system/toast";
import type { TransientNotificationVariant } from "$root/notification-system/transient";
import { TransientNotification } from "$root/notification-system/transient";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { groupBy } from "$root/utils/collections";
import { Card } from "$root/widgets-architecture/layout/Card";
import WidgetsGrid from "$root/widgets-architecture/layout/WidgetsGrid";
import { AsyncButton, Banner, Button, Dialog, DialogFooter, Table, Text } from "@mdotm/mdotui/components";
import type { QueryObserverResult } from "@tanstack/react-query";
import type React from "react";
import type { FC } from "react";
import { useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import type { IextendedInvestmentEnhancedDetails, IextendedInvestmentsModel, TgridName } from ".";
import { UploadEnum } from "../Portfolios/UploadPortfolioPage";
import { customObjectEntriesFn } from "$root/utils/experimental";
import { generateUniqueDOMId } from "@mdotm/mdotui/react-extensions";
import { PageCtxConsumer } from "$root/components/PageCtx";
import { createPortal } from "react-dom";

type TAlert = "SYSTEM_ERROR" | "CONSTRAINTS_ERRORS";

type OverviewProps = {
	portfolio?: IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails;
	portfolioUid: string;
	isEnhanceSelected: boolean;
	gridName: TgridName;
	isModalOpen: boolean;
	portfolioAlert: PortfolioAlert[];
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	requestEnhance(): void;
	refetchPortfolio?(): Promise<QueryObserverResult<IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails>>;
};

interface IportfolioAlert {
	alerts: PortfolioAlert[];
	portfolio: IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails | undefined;
	gridName: TgridName;
	requestEnhance: () => void;
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isEnhanceSelected: boolean;
}

interface IportfolioComposition {
	portfolio: IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails | undefined;
	gridName: TgridName;
	portfolioUid: string;
	isEnhanceSelected: boolean;
	refetchPortfolio?(): Promise<QueryObserverResult<IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails>>;
}

const PortfolioAlertArea: FC<IportfolioAlert> = ({ alerts, portfolio, isEnhanceSelected }) => {
	const { reference, name, Error } = portfolio ?? {};
	const { t } = useTranslation();

	const lazyErrorNotifications = useMemo(
		() => ({
			SYSTEM_ERROR: {
				bodyError: `?subject=${encodeURIComponent("Additional Information")}&body=${encodeURIComponent(
					t("PORTFOLIOS.PORTFOLIOS_ERROR.SYSTEM", { portfolioName: name ?? "-" }),
				)}`,
				i18nKey: "SYSTEM_ERROR" as TAlert,
			},
		}),
		[name, t],
	);

	const typeError = "SYSTEM_ERROR";
	const { bodyError, i18nKey } = lazyErrorNotifications[typeError];

	const mainIssueVariant = useMemo<TransientNotificationVariant | null>(() => {
		if (!alerts) {
			return null;
		}
		const { warning = [], info = [] } = groupBy(alerts, (singleAlert) =>
			getPortfolioAlertVariantByType(singleAlert.type),
		);
		if (warning.length > 0) {
			return "warning";
		}
		if (info.length > 0) {
			return "info";
		}
		return null;
	}, [alerts]);

	const filteredAlert = alerts.filter((alert) => {
		if (alert.type === "PortfolioConstraintWarningAlertCheck" && isEnhanceSelected) {
			return false;
		}

		return true;
	});

	const alertsByType = groupBy(alerts, (x) => x.type);
	const groupedAlerts = customObjectEntriesFn(alertsByType).flatMap(([, payload]) =>
		Object.values(groupBy(payload ?? [], (x) => x.value.identifier ?? generateUniqueDOMId())),
	);

	if (!groupedAlerts.length || reference) {
		return <></>;
	}

	return (
		<>
			<TransientNotification
				variant="warning"
				dismissible={false}
				location="in-page"
				autoHide={null}
				trigger={Boolean(Error && !reference)}
			>
				<Trans i18nKey={`ALERT.${i18nKey}.description`}>
					<a style={{ textDecoration: "underline" }} href={`mailto:operations@mdotm.eu${bodyError}`}>
						{{ email: "operations@mdotm.eu" } as any}
					</a>
				</Trans>
			</TransientNotification>
			<TransientNotification
				variant={mainIssueVariant ?? "warning"}
				dismissible={false}
				location="in-page"
				autoHide={null}
				trigger
				action={
					<div className="flex flex-row gap-2 items-center">
						{/* {isEnhancable && alerts.some(({ type }) => isAutoFixable(type)) && (
							<Button size="x-small" palette="inverted" onClick={() => setIsModalOpen(true)}>
								<>
									<Icon icon="Optimize" classList="mr-1" />
									{t("PORTFOLIO_DETAILS.AUTO_FIX")}
								</>
							</Button>
						)} */}
						{/* {isEnhancable && alerts.some(({ type }) => isFixableByEnhance(type)) && (
							<Button size="x-small" palette="inverted" onClick={requestEnhance}>
								<>
									<Icon icon="Enhance" classList="mr-1" />
									{t("PORTFOLIOS.ENHANCE")}
								</>
							</Button>
						)} */}
					</div>
				}
			>
				<div className="grow flex flex-row justify-between items-center">
					<div className="min-w-0 overflow-hidden text-ellipsis">
						{t("PORTFOLIO_ALERTS.ISSUES_DETECTED", { count: groupedAlerts.length })}&nbsp;
						{/* {alerts.map((alert, i, { length }) => {
							const widgetName = widgetFromAlertType(alert.type);

							return (
								<Fragment key={i}>
									{widgetName ? (
										<button
											type="button"
											onClick={() => {
												scrollToWidget(gridName, widgetName, { marginTop: 120 })
													.then(() => toggleWidgetTooltip(gridName, widgetName, true))
													.catch(console.error);
											}}
											className="underline"
										>
											{mapAlertShortTitle({ t, ...formatters }, alert)}
										</button>
									) : (
										mapAlertShortTitle({ t, ...formatters }, alert)
									)}
									{i !== length - 1 && ", "}
								</Fragment>
							);
						})} */}
						Review the widgets for more details.
					</div>
				</div>
			</TransientNotification>
		</>
	);
};

const PortfolioComposition: FC<IportfolioComposition> = ({ portfolio, gridName, portfolioUid, refetchPortfolio }) => {
	const { isUpload, isOptimize, status, reference, richAcl } = portfolio ?? {};
	const { t } = useTranslation();
	const history = useHistory();
	const editorApi = useApiGen(EntityEditorControllerApiFactory);
	const user = useUserValue();
	const { push } = useTypedNavigation();

	const [editorComposition, setEditorComposition] = useState<{
		disabled: boolean;
		onSubmit(fn: (data: ReviewTicker[]) => Promise<void>): Promise<void>;
	} | null>(null);

	const manageFinish = useCallback(() => history.push(`/portfolio_details/${portfolioUid}`), [history, portfolioUid]);

	const onOptimizeOrSaveEnhancement = useCallback(
		async (policy: EditorSaveEditOrReviewRequestEditPolicyEnum) => {
			await editorComposition?.onSubmit(async (submitData) => {
				try {
					if (portfolioUid === undefined) {
						return reportPlatformError(
							portfolio,
							"ERROR",
							"portfolio",
							`unable to submit a valid portfolio of undefined`,
						);
					}

					await editorApi.saveEditorEditEntity("INVESTMENT", {
						identifier: portfolioUid,
						tickerComposition: submitData,
						editPolicy: policy,
					});

					trackMixPanelEvent("Portfolio", {
						Type: "Update",
						Area: `Composition`,
						Mode: policy === "SAVE_AND_ACCEPT" ? "optimize" : "optimize Without Accept",
						ID: portfolioUid ?? "",
					});
					manageFinish();
				} catch (error) {
					reportPlatformError(
						error,
						"ERROR",
						"portfolio",
						`unable to save the edited portfolio composition (${portfolioUid})`,
					);
				}
			});
		},
		[editorApi, editorComposition, manageFinish, portfolio, portfolioUid],
	);

	const { formatNumber } = useLocaleFormatters();
	const canEditComposition = aclByArea.portfolio.canEditComposition(user.id, richAcl?.acl ?? []);

	if (isOptimize && canEditComposition) {
		return (
			<div>
				<EditCompositionSection
					uuid={portfolioUid}
					type="optimize"
					uploadEntity={UploadEnum.INVESTMENT_ENHANCEMENT}
					ref={setEditorComposition}
					instrumentsLimit={1000}
					pathToNotBlock={[typedUrlForRoute("PortfolioDetails", { portfolioUid })]}
				/>
				<div className="flex item-center gap-4 mt-4">
					<AsyncButton
						size="small"
						palette="primary"
						disabled={editorComposition?.disabled ?? true}
						onClickAsync={() => onOptimizeOrSaveEnhancement("SAVE_WITHOUT_ACCEPT")}
					>
						Update proposal
					</AsyncButton>
					<AsyncButton
						size="small"
						palette="primary"
						disabled={editorComposition?.disabled ?? true}
						onClickAsync={() => onOptimizeOrSaveEnhancement("SAVE_AND_ACCEPT")}
					>
						Save and accept enhancement
					</AsyncButton>
				</div>
			</div>
		);
	}

	if (isUpload) {
		return (
			<ReactQueryWrapper
				queryFn={() =>
					axiosExtract(
						editorApi.getEditorReviewComposition(portfolio?.uuid ?? "", reference ? "TARGET_INVESTMENT" : "INVESTMENT"),
					)
				}
				queryKey={["portfolioComposition", portfolio?.uuid, portfolio?.status]}
			>
				{(data) => (
					<Card>
						<Text type="Body/XL/Bold" classList="mb-4">
							Composition
						</Text>
						<ReviewCompositionErrors errors={data?.uploadErrors} />
						<div className="mb-4" hidden={status === "RETRIEVING_DATA"}>
							<Banner severity="warning" title="Review Before Saving">
								Some instruments require your attention. This may be due to missing information or because they have
								been delisted. Please review the list below and make any necessary updates before saving.
							</Banner>
						</div>
						{status === "RETRIEVING_DATA" ? (
							<>
								<div className="mb-4">
									<Banner severity="info">{t("PORTFOLIO_UPLOAD_ALERTS.CALCULATING_UPLOAD")}</Banner>
								</div>
								<Table
									columns={[
										{
											relativeWidth: 3,
											header: t("COMPOSITION.INSTRUMENT"),
											content: ({ instrument }) => instrument ?? "",
										},
										{
											relativeWidth: 1.9,
											header: t("COMPOSITION.IDENTFIER"),
											content: ({ identifier }) => identifier ?? "",
										},
										{
											relativeWidth: 1.5,
											header: t("COMPOSITION.ASSET_CLASS"),
											content: ({ assetClass }) => assetClass ?? "",
										},
										{
											relativeWidth: 2.6,
											header: t("COMPOSITION.MICRO_ASSET_CLASS"),
											content: ({ microAssetClass }) => microAssetClass ?? "",
										},
										{
											relativeWidth: 1,
											header: t("COMPOSITION.WEIGHT"),
											cellClassList: "tabular-nums",
											content: ({ weight }) => `${formatNumber(weight ?? 0)}%`,
											align: "end",
										},
									]}
									rows={data.composition ?? []}
									visibleRows={Math.min(data.composition?.length ?? 0, 12)}
								/>
							</>
						) : (
							<EditProxiedInstrumentSection
								uuid={portfolio?.uuid}
								disabled={canEditComposition === false}
								composition={data.composition ?? []}
								section={reference ? "target_investment" : "portfolio"}
								onSaveAsync={async (tickerComposition) => {
									await editorApi.saveEditorReviewEntity("INVESTMENT", {
										identifier: portfolioUid,
										tickerComposition,
									});
									await refetchPortfolio?.();
								}}
								customActions={({ isSaveDisabled, onSaveAsync }) => (
									<PageCtxConsumer>
										{(ctx) =>
											ctx.titleActionPortalTarget &&
											createPortal(
												<div className="flex items-center gap-2">
													<Button onClick={() => push("PortfoliosStudio", {})} size="small" palette="tertiary">
														Cancel
													</Button>
													<AsyncButton
														onClickAsync={onSaveAsync}
														disabled={isSaveDisabled}
														size="small"
														palette="primary"
													>
														Save
													</AsyncButton>
												</div>,
												ctx.titleActionPortalTarget,
											)
										}
									</PageCtxConsumer>
								)}
							/>
						)}
					</Card>
				)}
			</ReactQueryWrapper>
		);
	}

	return <WidgetsGrid gridName={gridName} />;
};

const Overview: FC<OverviewProps> = ({
	portfolio,
	portfolioUid,
	isEnhanceSelected,
	gridName,
	isModalOpen,
	portfolioAlert,
	setIsModalOpen,
	requestEnhance,
	refetchPortfolio,
}) => {
	const { t } = useTranslation();
	const investmentsEnhanceApi = useApiGen(InvestmentEnhancementControllerV4ApiFactory);

	const { push } = useTypedNavigation();
	const confirmOptimization = useCallback(() => {
		if (!portfolio || !portfolio.uuid) {
			console.warn("missing portfolioData, confirm ignored.");
			return;
		}

		investmentsEnhanceApi
			.fixVar(portfolio.uuid)
			.then(() => {
				platformToast({
					children: t("PORTFOLIOS.OPTIMIZE_ACCEPTED"),
					severity: "success",
					icon: "Portfolio",
				});
				setIsModalOpen(false);
				push("PortfoliosStudio", {});
			})
			.catch(console.warn);
	}, [portfolio, investmentsEnhanceApi, t, setIsModalOpen, push]);

	return (
		<>
			{/* <PortfolioAlertArea
				alerts={portfolioAlert}
				gridName={gridName}
				portfolio={portfolio}
				setIsModalOpen={setIsModalOpen}
				requestEnhance={requestEnhance}
				isEnhanceSelected={isEnhanceSelected}
			/> */}

			<Dialog
				classList="flex-1"
				show={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				header={t("PORTFOLIOS.PORTFOLIO_OPTIMIZATION")}
				footer={({ loading }) => (
					<DialogFooter
						primaryAction={
							<Button palette="primary" size="small" onClick={() => confirmOptimization()}>
								{t("PROCEED")}
							</Button>
						}
						neutralAction={
							<Button palette="tertiary" size="small" disabled={loading} onClick={() => setIsModalOpen(false)}>
								{t("LEAVE")}
							</Button>
						}
					/>
				)}
			>
				{t("PORTFOLIOS.ALICE_SUGGEST_ENHANCHED")}
			</Dialog>
			<div className="mb-5">
				<SmallInvestmentSummary enhanced={isEnhanceSelected} portfolio={portfolio} />
			</div>
			<PortfolioComposition
				portfolio={portfolio}
				portfolioUid={portfolioUid}
				gridName={gridName}
				isEnhanceSelected={isEnhanceSelected}
				refetchPortfolio={refetchPortfolio}
			/>
		</>
	);
};

export default Overview;
