import {
	TinyTableDataCell,
	TinyTableHeadCell,
	tinyTableHeadCellFontSize,
} from "$root/components/EvolvedPrint/components/table/tiny-table";
import PositioninGraph from "$root/components/OutlookPositioningGraph";
import TimeSeriesModal from "$root/functional-areas/outlook/TimeSeriesModal";
import useWidgetsData from "$root/hooks/useWidgetsData";
import { builtInSortFnFor } from "$root/utils/collections";
import { toClassName } from "$root/utils/react-dom-extra";
import { Button, TableV2, Text } from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { FC } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

export type TableVariants =
	| "EQUITY"
	| "FIXED_INCOME"
	| "COMMODITIES"
	| "DASHBOARD_EQUITY"
	| "DASHBOARD_FIXED_INCOME"
	| "DASHBOARD_COMMODITIES"
	| "EQUITY_EU"
	| "EQUITY_USA";

export type TableParams = {
	assetClass: string;
	current: number;
	prev?: number;
	tooltip: Array<{ label: string; value: string; date?: string }>;
	index: number;
};

type OutlookBlockTableProps = {
	data: Array<TableParams>;
	title: TableVariants;
	className?: string;
	rowClassName?: string;
	pushTo?: string;
	sortable?: boolean;
	hideHeader?: boolean;
	assetClassTimeSeries?: {
		[key: string]: [number, number, boolean][];
	};
	size?: "medium" | "small";
};

const OutlookBlockTable: FC<OutlookBlockTableProps> = ({
	data,
	title,
	sortable = false,
	className = "",
	rowClassName = "",
	pushTo = "",
	hideHeader,
	assetClassTimeSeries,
	size = "medium",
}) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { currentWidgetsData } = useWidgetsData();

	const TABLE_TITLE: Record<TableVariants, string> = {
		COMMODITIES: "Commodities",
		EQUITY: "Equity",
		FIXED_INCOME: "Fixed income",
		DASHBOARD_EQUITY: `${t("EQUITY")} outlook`,
		DASHBOARD_FIXED_INCOME: `${t("FI")} outlook`,
		DASHBOARD_COMMODITIES: `${t("COMMODITIES")} outlook`,
		EQUITY_EU: "Equity Europe Large Cap",
		EQUITY_USA: "Equity USA Large Cap",
	};

	const TABLE_HEADER: Record<TableVariants, string> = {
		COMMODITIES: "MICRO ASSET CLASS",
		EQUITY: "MICRO ASSET CLASS",
		FIXED_INCOME: "MICRO ASSET CLASS",
		EQUITY_EU: "MICRO ASSET CLASS",
		EQUITY_USA: "MICRO ASSET CLASS",
		DASHBOARD_EQUITY: t("EQUITY"),
		DASHBOARD_FIXED_INCOME: t("FI"),
		DASHBOARD_COMMODITIES: t("SHORT_COMMODITIES"),
	};

	const outLookTile = TABLE_TITLE[title];
	const outLookHeader = TABLE_HEADER[title];
	const assetClassLabels = t("ASSET_CLASS_LABEL", { returnObjects: true });
	const columns = useMemo<Array<TableV2.TableColumn<TableParams>>>(
		() => [
			{
				header:
					size === "medium"
						? outLookHeader
						: (props) => <TinyTableHeadCell {...props}>{outLookHeader}</TinyTableHeadCell>,
				content:
					size === "medium"
						? ({ assetClass }) => assetClassLabels[assetClass as keyof typeof assetClassLabels] || assetClass
						: ({ assetClass }, props) => (
								<TinyTableDataCell {...props}>
									{assetClassLabels[assetClass as keyof typeof assetClassLabels] || assetClass}
								</TinyTableDataCell>
						  ),
				minWidth: 144,
				name: "assetClass",
				...(sortable ? { sortFn: builtInSortFnFor("assetClass") } : {}),
			},
			{
				name: "positioning",
				header: (props) => (
					<div
						style={{ ...props.style, minHeight: size === "medium" ? props.style?.minHeight : 31 }}
						className={overrideClassName(props.classList, "flex items-center justify-between grow px-2")}
					>
						{["SU", "U", "N", "O", "SO"].map((el) => (
							<Text
								style={{
									fontSize: size === "medium" ? undefined : tinyTableHeadCellFontSize,
								}}
								type="Body/S/BOLD-UPPERCASE"
								color={themeCSSVars.palette_N500}
								as="div"
								key={el}
							>
								{el}
							</Text>
						))}
					</div>
				),
				content: (content, props) => (
					<div
						style={{ ...props.style, minHeight: size === "medium" ? props.style?.minHeight : 31 }}
						className={overrideClassName(props.classList, "flex items-center h-10")}
					>
						<PositioninGraph
							{...content}
							tooltip={content.tooltip}
							tootlipTitle={assetClassLabels[content.assetClass as keyof typeof assetClassLabels] || content.assetClass}
							lastDashboardUpdate={currentWidgetsData.creation_time}
						/>
					</div>
				),
				width: 232,
			},
			{
				header: null,
				content: function Graph(p, props) {
					const disabled =
						assetClassTimeSeries === undefined ||
						assetClassTimeSeries[p.assetClass] === undefined ||
						assetClassTimeSeries[p.assetClass].length === 0;

					const currentRegime = {
						value: p.current,
						label: p.tooltip.find(({ label }) => label.toUpperCase() === "CURRENT")?.value ?? "",
					};

					const previousRegime = {
						value: p.prev,
						label:
							p.tooltip.find(({ label }) => label.toUpperCase() === "PREVIOUS")?.value ??
							p.tooltip.find(({ label }) => label.toUpperCase() === "CURRENT")?.value ??
							"",
					};

					return (
						<TableV2.TableDataCell
							{...props}
							style={{ ...props.style, minHeight: size === "medium" ? props.style?.minHeight : 31 }}
						>
							<TimeSeriesModal
								disabled={disabled}
								assetClass={p.assetClass}
								series={assetClassTimeSeries}
								currentRegime={currentRegime}
								previousRegime={previousRegime}
							/>
						</TableV2.TableDataCell>
					);
				},
				name: "timeSeries",
				width: 40,
				hidden: assetClassTimeSeries === undefined,
			},
		],
		[size, outLookHeader, sortable, assetClassTimeSeries, assetClassLabels, currentWidgetsData.creation_time],
	);

	return (
		<div className="flex flex-col min-h-0 grow h-full">
			<div className="flex justify-between">
				<h3 className={toClassName({ hidden: hideHeader, "text-xs font-semibold": true })}>{outLookTile}</h3>
				{pushTo && (
					<Button onClick={() => history.push(pushTo)} size="x-small" palette="tertiary">
						{t("VIEW")}
					</Button>
				)}
				{/* <DataSeriesModal typeMode="DASHBOARD" splitted ACFilter={title} blockedPath="/report" /> */}
			</div>
			<TableV2.BaseTable
				columns={columns}
				classList={className}
				rows={data}
				headerRowClassList={toClassName({
					hidden: hideHeader,
				})}
				rowClassList={`OutlookRowsTable ${rowClassName}`}
				noDataText={
					<div className="flex items-center justify-center h-20">
						<p className="!bg-transparent">No data available</p>
					</div>
				}
			/>
		</div>
	);
};

export default OutlookBlockTable;
