import type { InvestmentReportsBatchOrderingEnum, InvestmentSummary } from "$root/api/api-gen";
import type { Dispatch } from "react";
import { createContext } from "react";
import type { PortfolioAlert } from "../../functional-areas/portfolio/alerts";

export const PortfolioContext = createContext<{
	portfolio: InvestmentSummary | null;
	enhanced: boolean;
	selectedBenchmark: string | null;
	alerts: PortfolioAlert[];
	isMarketScenarioEnhancable?: boolean;
	currentTab?: number;
	updateMarketScenario?: Dispatch<React.SetStateAction<boolean>>;
	requestOptimize?: () => void;
	requestEnhance?: () => void;
	onChangePortfolioUid?: (uuid: string) => void;
	reportsExecution: { [key in InvestmentReportsBatchOrderingEnum]?: boolean };
	reportExcutionCounter?: number;
}>({
	portfolio: null,
	enhanced: false,
	selectedBenchmark: null,
	alerts: [],
	isMarketScenarioEnhancable: false,
	reportsExecution: { ANAGRAPHIC: false },
});
