import type { PortfolioExAnteMetric } from "$root/api/api-gen";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import { TinyTableDataCell, TinyTableHeadCell } from "$root/components/EvolvedPrint/components/table/tiny-table";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { useLocaleFormatters } from "$root/localization/hooks";
import type { ReportTemplateVariant } from "$root/pages/PortfolioStudioSettings/ReportEditor/version/report-v1";
import { TableV2 } from "@mdotm/mdotui/components";
import { useTranslation } from "react-i18next";

const ExAnteMetrics = ({
	benchmark: showBechmark,
	variant,
	list,
}: PrintableProps<{ benchmark: boolean; variant: ReportTemplateVariant }, PortfolioExAnteMetric>): JSX.Element => {
	const { formatNumber } = useLocaleFormatters();
	const { t } = useTranslation();

	const columns = [
		{
			name: "name",
			width: variant === "current" ? 330 : 200,
			header: (props) => <TinyTableHeadCell {...props}>{t("METRICS")}</TinyTableHeadCell>,
			content: ({ type }, props) => (
				<TinyTableDataCell {...props}>{t(`EXANTE_METRICS.METRICS.${type!}`)}</TinyTableDataCell>
			),
		},
		{
			name: "current",
			width: 124,
			cellClassList: "tabular-nums",
			header: (props) => <TinyTableHeadCell {...props}>Current</TinyTableHeadCell>,
			align: "end",
			content: ({ current, type }, props) => {
				const label = t(`EXANTE_METRICS.METRICS.${type!}`);
				return (
					<TinyTableDataCell {...props}>{`${formatNumber(current)}${
						label.toLowerCase().includes("ratio") ? "" : "%"
					}`}</TinyTableDataCell>
				);
			},
		},
		{
			name: "benchmark",
			width: 124,
			cellClassList: "tabular-nums",
			header: (props) => <TinyTableHeadCell {...props}>Benchmark</TinyTableHeadCell>,
			align: "end",
			content: ({ benchmark: benchmarkValue, type }, props) => {
				const label = t(`EXANTE_METRICS.METRICS.${type!}`);
				return (
					<TinyTableDataCell {...props}>{`${formatNumber(benchmarkValue)}${
						label.toLowerCase().includes("ratio") ? "" : "%"
					}`}</TinyTableDataCell>
				);
			},
			hidden: !showBechmark,
		},
		{
			name: "proposal",
			width: 124,
			cellClassList: "tabular-nums",
			header: (props) => <TinyTableHeadCell {...props}>Proposal</TinyTableHeadCell>,
			align: "end",
			content: ({ type, proposal }, props) => {
				const label = t(`EXANTE_METRICS.METRICS.${type!}`);
				return (
					<TinyTableDataCell {...props}>{`${formatNumber(proposal)}${
						label.toLowerCase().includes("ratio") ? "" : "%"
					}`}</TinyTableDataCell>
				);
			},
			hidden: variant === "current",
		},
		{
			name: "empty",
			minWidth: 0,
			header: (props) => <TinyTableHeadCell {...props}>{null}</TinyTableHeadCell>,
			content: (_, props) => <TinyTableDataCell {...props}>{null}</TinyTableDataCell>,
		},
	] satisfies TableV2.TableColumn<PortfolioExAnteMetric>[];

	return (
		<Card title="Ex-ante portfolio metrics">
			<TableV2.BaseTable palette="uniform" columns={columns} rows={list} noDataText="No metrics" />
		</Card>
	);
};

export default ExAnteMetrics;
