import { CherryBankControllerV6ApiFactory, PdfControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import AuthorizationGuard, { hasAccess } from "$root/components/AuthorizationGuard";
import { OutlookWidgetData } from "$root/components/OutlookWidgetData";
import { PageDownloadMenu } from "$root/components/PageDownloadMenu";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import env from "$root/env";
import { useUserValue } from "$root/functional-areas/user";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { downloadBlob, downloadFileResponse } from "$root/utils/files";
import WidgetsMapper from "$root/widgets-architecture/layout/WidgetsMapper";
import { Text } from "@mdotm/mdotui/components";
import { useUpdatedRef } from "@mdotm/mdotui/react-extensions";
import * as dateFn from "date-fns";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const CustomReports = (): JSX.Element => {
	const { t } = useTranslation();
	const cherryBankApi = useApiGen(CherryBankControllerV6ApiFactory);
	const pdfV3Api = useApiGen(PdfControllerApiFactory);
	const user = useUserValue();

	const downloadPf = useCallback(async () => {
		const pageUrl = encodeURIComponent(`${env.appOrigin}/login?from=/report/custom-report`);
		const pdf = await axiosExtract(
			pdfV3Api.generateInvestmentsPdf(
				pageUrl,
				"TPLPortraitNoMargin",
				`Cherry_Bank_Report_${dateFn.format(new Date(), "MMddyyyy")}`,
				{ responseType: "blob" },
			),
		);
		trackMixPanelEvent("Custom-reports", {
			Type: "Export",
			Mode: "PDF",
		});
		downloadBlob(pdf as Blob, {
			fileName: `Cherry_Bank_Report_${dateFn.format(new Date(), "MMddyyyy")}.pdf`,
		});
	}, [pdfV3Api]);

	const downloadXls = async () => {
		const template = await axiosExtract(cherryBankApi.retrieveReport());
		trackMixPanelEvent("Custom-reports", {
			Type: "Export",
			Mode: "XLS",
		});
		downloadFileResponse(template, {
			fileName: `Cherry_Bank_Report_${dateFn.format(new Date(), "MMddyyyy")}.xlsx`,
		});
	};

	const downloadPfRef = useUpdatedRef(downloadPf);
	const downloadXlsRef = useUpdatedRef(downloadXls);

	return (
		<AuthorizationGuard requiredService="CUSTOM_REPORT_CB1" placeholder={<>{t("NOT_AUTHORIZED")}</>}>
			{() => (
				<>
					<PageHeader
						title="Custom reports" // TODO: translate
						titleAction={
							<div className="flex items-center justify-between gap-2">
								<div>
									<OutlookWidgetData />
								</div>
								<div>
									<PageDownloadMenu
										area="custom-report"
										actions={[
											{
												icon: "Dowload",
												onClickAsync: () => downloadXlsRef.current(),
												label: "XLS report",
												"data-qualifier": "CustomReports/DropdownMenu/DropdownItem(XLSReport)",
											},
											{
												icon: "Dowload",
												onClickAsync: () => downloadPfRef.current(),
												label: "PDF report",
												"data-qualifier": "CustomReports/DropdownMenu/DropdownItem(PDFReport)",
											},
											!hasAccess(user, { requiredRoles: ["ROOT"] })
												? null
												: {
														group: "ROOT FEATURES",
														icon: "Expand",
														onClick: () => {
															window.open(typedUrlForRoute("Report/Demo", { templateId: "custom-report" }), "_blank");
														},
														label: "Vertical Report",
														"data-qualifier": "CustomReports/DropdownMenu/DropdownItem(Preview)",
												  },
										]}
									/>
								</div>
							</div>
						}
					/>
					<div className="relative">
						<WidgetsMapper
							widgetName="AssetClassOverviewBlock"
							style={{ paddingBottom: "1rem", marginBottom: "1rem" }}
						/>
						<WidgetsMapper widgetName="CustomOutlookBlock" style={{ paddingBottom: "1rem" }} />
					</div>
				</>
			)}
		</AuthorizationGuard>
	);
};

export default CustomReports;
