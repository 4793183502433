import type { ReviewTicker, ReviewTickerCommentaryStatusEnum } from "$root/api/api-gen";
import { InstrumentCustomizationControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { useEventBus } from "$root/event-bus";
import type { InstrumentCustomizationTableProps } from "$root/functional-areas/instruments/InstrumentCustomizationTable";
import { InstrumentCustomizationTable } from "$root/functional-areas/instruments/InstrumentCustomizationTable";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { parallelize } from "$root/utils/promise";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { useRef, useState } from "react";
import { Map } from "immutable";
import { maybeUppercase } from "$root/utils/strings";

export const instrumentCommentaryGenerationCode = {
	DATA_NOT_AVAILABLE: "DATA_NOT_AVAILABLE",
	COMMENTARY_GENERATION_ERROR: "COMMENTARY_GENERATION_ERROR",
};

const InstrumentCommentarySetting = (_: { active: boolean }): JSX.Element => {
	const [rowOverrides, setRowOverrides] = useState(() => Map</* ticker */ string, Partial<ReviewTicker>>());

	const instrumentCustomizationApi = useApiGen(InstrumentCustomizationControllerApiFactory);
	const currentRowsRef = useRef<Array<ReviewTicker>>([]);

	const loadInstruments: InstrumentCustomizationTableProps["rowsProvider"] = async (request) => {
		const paginationResult = await axiosExtract(
			instrumentCustomizationApi.listInstruments({
				offset: request.skip,
				size: request.take,
				baskets: request.filters?.baskets,
				macroAssetClasses: request.filters?.assetClasses,
				microAssetClasses: request.filters?.microAssetClasses,
				searchQuery: request.filters?.query,
				sortBy: request.orderBy?.[0]?.columnName,
				sortDirection: maybeUppercase(request.orderBy?.[0]?.direction),
			}),
		);

		// We assume that the data in the latest response is more up-to-date than
		// the local override, therefore we can simply remove existing tickers from the rowOverrides
		if (paginationResult.items) {
			setRowOverrides(rowOverrides.removeAll(paginationResult.items?.map((x) => x.ticker!)));
			currentRowsRef.current = paginationResult.items;
		}

		return {
			items: paginationResult.items ?? [],
			total: paginationResult.pagination?.total ?? 0,
		};
	};

	const selectableQuery = useQueryNoRefetch({
		queryKey: ["instrument-selectable-basket"],
		queryFn: async function loadSelectables() {
			const {
				assetClasses,
				microAssetClasses,
				basketData: { selectableBaskets },
			} = await parallelize(
				{
					assetClasses: () => axiosExtract(instrumentCustomizationApi.getProxySelectableAssetClasses1()),
					microAssetClasses: () => axiosExtract(instrumentCustomizationApi.getProxySelectableMicroAssetClasses1()),
					basketData: () => axiosExtract(instrumentCustomizationApi.getEditorNewSelectableBaskets()),
				},
				{ concurrency: 3 },
			);

			return {
				assetClasses,
				baskets: selectableBaskets ?? [],
				microAssetClasses,
			};
		},
	});

	function getExistingTickerDataBy(
		filter: { ticker: string; isin?: undefined } | { isin: string; ticker?: undefined },
	): [string, Partial<ReviewTicker>] | undefined {
		const filterFn = filter.ticker
			? (x: { ticker?: string }) => x.ticker === filter.ticker
			: (x: { isin?: string }) => x.isin === filter.isin;
		const existingTickerData =
			rowOverrides.findEntry(filterFn) ??
			(() => {
				const candidate = currentRowsRef.current.find(filterFn);
				if (candidate) {
					return [candidate.ticker!, candidate];
				}
				return undefined;
			})();
		return existingTickerData;
	}

	useEventBus("instrument-commentary-update", ({ description, descriptionStatus, isin }) => {
		if (!isin) {
			return;
		}
		const existingTickerDataForIsin = getExistingTickerDataBy({ isin });
		if (existingTickerDataForIsin) {
			const [ticker, tickerData] = existingTickerDataForIsin;
			setRowOverrides((cur) =>
				cur.set(ticker, {
					...tickerData,
					// TODO: this should be provided by the server
					commentaryDate: new Date().toISOString(),

					descriptionStatus,
					description,
					// TODO: this should be provided by the server
					descriptionDate: new Date().toISOString(),
				}),
			);
		}
	});

	async function generateCommentary(ticker: string, isin: string) {
		try {
			setRowOverrides((cur) =>
				cur.set(ticker!, { ...getExistingTickerDataBy({ ticker })?.[1], descriptionStatus: "CALCULATING" }),
			);
			const tickerData = await axiosExtract(instrumentCustomizationApi.generateInstrumentSphereCommentary(isin));
			// The server side event may be received before the above API call ends, so we check the status to prevent race conditions
			if (getExistingTickerDataBy({ ticker })?.[1]?.descriptionStatus === "CALCULATING") {
				setRowOverrides((cur) => cur.set(ticker!, tickerData));
			}
		} catch (err) {
			platformToast({
				children: `Unable to generate the commentary for ${isin} instrument`,
				icon: "Portfolio",
				severity: "error",
			});
			if (getExistingTickerDataBy({ ticker })?.[1]?.descriptionStatus === "CALCULATING") {
				setRowOverrides((cur) =>
					cur.set(ticker!, { ...getExistingTickerDataBy({ ticker }), descriptionStatus: "ERROR" }),
				);
			}
			throw err;
		}
	}

	async function saveCustomProxy(instrument: ReviewTicker) {
		try {
			const ticker = await axiosExtract(instrumentCustomizationApi.saveInstrumentProxies(instrument));
			setRowOverrides((cur) => cur.set(instrument.ticker!, ticker));
		} catch (err) {
			platformToast({
				children: `Unable to save the custom proxy ${instrument.isin} `,
				icon: "Portfolio",
				severity: "error",
			});
			throw err;
		}
	}

	async function onChangeCommentary(ticker: string, newCommentaryText: string) {
		try {
			const tickerData = await axiosExtract(
				instrumentCustomizationApi.saveInstrumentUserCommentary(ticker, {
					content: newCommentaryText,
				}),
			);
			setRowOverrides((cur) => cur.set(ticker, tickerData));
		} catch (err) {
			platformToast({
				children: `Unable to update commentary for ${ticker} instrument`,
				icon: "Portfolio",
				severity: "error",
			});
			throw err;
		}
	}

	async function onChangeInstrumentName(ticker: string, newInstrumentName: string) {
		try {
			const tickerData = await axiosExtract(
				instrumentCustomizationApi.saveInstrument({
					ticker,
					...getExistingTickerDataBy({ ticker })?.[1],
					instrument: newInstrumentName,
				}),
			);
			setRowOverrides((cur) => cur.set(ticker, tickerData));
		} catch (err) {
			platformToast({
				children: `Unable to update instrument name for ${ticker}`,
				icon: "Portfolio",
				severity: "error",
			});
			throw err;
		}
	}

	const defaultBaskets = new URLSearchParams(location.search).get("uuid")?.split(",");

	return (
		<>
			<ReactQueryWrapperBase query={selectableQuery}>
				{(availableFilters) => (
					<InstrumentCustomizationTable
						style={{ maxHeight: "calc(100dvh - 54px - 40px)" }}
						rowOverrides={rowOverrides}
						rowsProvider={loadInstruments}
						availableFilters={availableFilters}
						onGenerateCommentary={generateCommentary}
						onSubmitCustomProxy={saveCustomProxy}
						onChangeCommentary={onChangeCommentary}
						onChangeInstrumentName={onChangeInstrumentName}
						defaultBaskets={defaultBaskets?.flatMap((uuid) => {
							const basket = availableFilters.baskets.find((x) => x.basketIdentifier === uuid);
							return basket ? [basket] : [];
						})}
					/>
				)}
			</ReactQueryWrapperBase>
		</>
	);
};

export default InstrumentCommentarySetting;
