import type { IndexTicker } from "$root/api/api-gen";
import { BenchmarksControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { IconWalls } from "$root/components/IconWall";
import { useEventBus } from "$root/event-bus";
import { useLocaleFormatters } from "$root/localization/hooks";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { downloadContentDisposition } from "$root/utils/files";
import { objMatchFn } from "$root/utils/objects";
import { useUpdatedRef } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { BenchmarkContext } from "$root/widgets-architecture/contexts/benchmark";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { TableColumn } from "@mdotm/mdotui/components";
import { DropdownMenu, Icon, Searchable, Table, TextInput } from "@mdotm/mdotui/components";
import type { ContextContent } from "@mdotm/mdotui/react-extensions";
import { withContext } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, noop } from "@mdotm/mdotui/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

const BenchmarkComposition = (props: ContextContent<typeof BenchmarkContext>) => {
	const { benchmarkId } = props;
	const benchmarkV4Api = useApiGen(BenchmarksControllerApiFactory);

	const { t } = useTranslation();

	const { data, isFetching, isError, refetch } = useQueryNoRefetch(["getBenchmarkComposition"], {
		queryFn: async () => {
			if (!benchmarkId) {
				throw new Error("unable to find benchmarkId of undefined");
			}
			const benchmarkComposition = await benchmarkV4Api.getBenchmarkComposition(benchmarkId);
			const benchmarkSummary = await benchmarkV4Api.getBenchmarkSummary(benchmarkId);

			return { composition: benchmarkComposition.data, summary: benchmarkSummary.data };
		},
	});

	useEventBus("benchmark-update", {
		filter: objMatchFn({ uuid: benchmarkId }),
		listener: () => {
			refetch().catch(noop);
		},
	});

	const { composition } = data ?? {};

	const handleDownloadErrorReport = async () => {
		try {
			if (!benchmarkId) {
				throw new Error("unable to find benchmarkId of undefined");
			}
			const response = await benchmarkV4Api.exportComposition1(benchmarkId, { responseType: "blob" });
			downloadContentDisposition(response);

			trackMixPanelEvent("Benchmark", {
				Type: "Export",
				Action: "save",
				ID: benchmarkId,
			});
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const matchFnMemo = useCallback((item: IndexTicker, query: string): boolean => {
		if (query.length === 0) {
			return true;
		}
		return JSON.stringify(item).toLowerCase().includes(query.toLowerCase());
	}, []);

	const handleDownloadErrorReportRef = useUpdatedRef(handleDownloadErrorReport);

	useWidgetOptions(
		() => ({
			title: "Composition",
			actionHeader: function Download() {
				return (
					<div style={{ display: "flex", flexDirection: "row" }} className="space-x-2">
						<DropdownMenu
							trigger={({ innerRef, open, ...forward }) => (
								<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
									<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
								</button>
							)}
							actions={[
								{
									icon: "xls",
									disabled: data?.summary.status === "CALCULATING" || data === undefined,
									onClickAsync: async () => {
										await handleDownloadErrorReportRef.current();
									},
									label: "Benchmark composition",
								},
							]}
						/>
						<InfoTooltip>{t("COMPOSITION.TOOLTIP")}</InfoTooltip>
					</div>
				);
			},
		}),
		[data, handleDownloadErrorReportRef, t],
	);

	const { formatNumber } = useLocaleFormatters();

	const columns = useMemo<TableColumn<IndexTicker>[]>(
		() => [
			{
				header: t("TABLE.HEADERS.INDEX"),
				relativeWidth: 6.3,
				content: ({ instrument }) => instrument,
				sortFn: builtInSortFnFor("instrument"),
				name: "instrument",
			},
			{
				header: t("TABLE.HEADERS.ASSET_CLASS"),
				relativeWidth: 1.2,
				content: ({ assetClass }) => assetClass ?? "",
				sortFn: builtInSortFnFor("assetClass"),
				name: "assetClass",
			},
			{
				header: t("TABLE.HEADERS.MICRO_ASSET_CLASS"),
				relativeWidth: 1.8,
				content: ({ microAssetClass, microGeography }) => `${microAssetClass ?? ""} ${microGeography ?? ""}`,
				sortFn: builtInSortFnFor("microAssetClass"),
				name: "microAssetClass",
			},
			{
				header: t("TABLE.HEADERS.WEIGHT"),
				cellClassList: "tabular-nums",
				relativeWidth: 0.7,
				content: ({ weight }) => `${formatNumber(weight)}%`,
				sortFn: builtInSortFnFor("weight"),
				name: "weight",
			},
		],
		[formatNumber, t],
	);

	if (isError) {
		return <IconWalls.ErrorData />;
	}
	if (isFetching) {
		return <IconWalls.LoadingData />;
	}

	if (composition === undefined) {
		return <IconWalls.HistoricalDataNotAvailable />;
	}

	return (
		<Searchable matchFn={matchFnMemo} collection={composition ?? []}>
			{({ filtered, query, setQuery }) => (
				<div className="grid gap-4">
					<TextInput
						value={query}
						placeholder="Search for instruments name, identifier o asset class"
						onChangeText={setQuery}
						leftContent={<Icon icon="Search" />}
					/>

					<Table rows={filtered} columns={columns} visibleRows={Math.min(12, composition.length)} />
				</div>
			)}
		</Searchable>
	);
};

export default withContext(BenchmarkContext)(BenchmarkComposition);
