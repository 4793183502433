import type { StylableProps } from "@mdotm/mdotui/components";
import { Icon, TextInput } from "@mdotm/mdotui/components";
import type { KeyboardEvent } from "react";
import { useCallback, useState } from "react";
import { useDebounced } from "@mdotm/mdotui/react-extensions";

type DebouncedSearchInputProps = {
	query: string;
	onChange(debouncedQuery: string): void;
	timeout?: number;
	placeholder?: string;
	name?: string;
	maxLength?: number;
	onKeyDown?(e: KeyboardEvent): void;
} & StylableProps;

export function DebouncedSearchInput({
	query: initialQuery,
	onChange,
	timeout,
	...forward
}: DebouncedSearchInputProps): JSX.Element {
	const [query, _setQuery] = useState(initialQuery);

	const { invoke: dispatchOnChange } = useDebounced((q: string) => onChange(q), {
		debounceInterval: timeout ?? 200,
	});

	const setQuery = useCallback(
		(q: string) => {
			_setQuery(q);
			dispatchOnChange(q);
		},
		[dispatchOnChange],
	);

	return <TextInput leftContent={<Icon icon="Search" />} value={query} onChangeText={setQuery} {...forward} />;
}
