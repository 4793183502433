import { useCallback, useEffect, useRef, useState } from "react";
import WidgetsGrid from "$root/widgets-architecture/layout/WidgetsGrid";
import { useTranslation } from "react-i18next";
import { getQueryParam } from "$root/utils/url";
import { replaceSpaceWith_ } from "$root/utils/strings";
import useTypeToLabel from "$root/hooks/useTypeToLabel";
import type { Option } from "$root/components/shared";
import type { OptionTreeNode } from "$root/components/HierarchicalMultiSelect";
import HierarchicalMultiSelect, { withPlaceholder } from "$root/components/HierarchicalMultiSelect";
import { useHistory, useLocation } from "react-router";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { UniverseFiltersContext } from "$root/widgets-architecture/contexts/filters";
import { useApiGen } from "$root/api/hooks";
import { MarketUniverseControllerV3ApiFactory, OutlookControllerV3ApiFactory } from "$root/api/api-gen";
import { PageHeader } from "$root/components/PageHeader";
import { OutlookWidgetData } from "$root/components/OutlookWidgetData";
import { useFunctionalAreas } from "$root/App/context";
import { ProgressBar } from "@mdotm/mdotui/components";
import { PageDownloadMenu } from "$root/components/PageDownloadMenu";
import { getApiGen } from "$root/api/factory";
import { downloadContentDisposition } from "$root/utils/files";

const PAGE_NAME = "OUTLOOK";

const DEFAULT_FILTER = "EQ";
const DEFAULT_AREA = "MARKETS";

const Outlook = (): JSX.Element => {
	const { t } = useTranslation();
	const { getLabel } = useTypeToLabel();

	const history = useHistory();
	const location = useLocation();

	const [multiFilter, setMultiFilter] = useState<{
		assetClass: string | undefined;
		area: string | undefined;
	} | null>(null);

	const updateFilterUsingSearchString = useCallback((optionTree: OptionTreeNode, searchString: string) => {
		const candidateSelection = {
			assetClass: getQueryParam(searchString, "filter") || DEFAULT_FILTER,
			area: getQueryParam(searchString, "area") || DEFAULT_AREA,
		};

		const assetClassOption = optionTree.find((opt) => opt.value === candidateSelection.assetClass);
		let areaOption:
			| undefined
			| (Option<string | undefined> & {
					subOptions?: OptionTreeNode;
			  });
		if (assetClassOption) {
			areaOption = assetClassOption.subOptions?.find((opt) => opt.value === candidateSelection.area);
		}
		if (!assetClassOption || (!areaOption && assetClassOption.subOptions && assetClassOption.subOptions.length > 0)) {
			setMultiFilter({
				assetClass: optionTree[0].value,
				area: optionTree[0].subOptions?.[0]?.value,
			});
		} else {
			setMultiFilter(candidateSelection);
		}
	}, []);

	const universeApi = useApiGen(MarketUniverseControllerV3ApiFactory);

	const optionTree = useQueryNoRefetch(["outlookOptions"], {
		queryFn: async () => {
			const equityAreas = (await universeApi.universeControllerGetAsset()).data.filter((x) => x.type === "Equity");
			const equityAreaOptions: Option<string>[] = [
				{ value: "MARKETS", label: t("OUTLOOK.ALL_MARKETS") },
				...equityAreas
					.filter((el) => el.sector?.length)
					.map((el) => ({
						value: replaceSpaceWith_(el.label!).toUpperCase(),
						label: getLabel(`EQ_${el.label}`) as string,
					})),
			];

			const result: OptionTreeNode = withPlaceholder(t("OUTLOOK.SELECT_ASSET_CLASS"), [
				{
					value: "EQ",
					label: t("EQUITY"),
					subOptions: withPlaceholder(
						t("OUTLOOK.SELECT_GEOGRAPHY"),
						equityAreaOptions.map((areaOption) => ({
							...areaOption,
						})),
					),
				},
				{
					value: "FI",
					label: t("OUTLOOK.FI"),
				},
				{
					value: "CO",
					label: t("OUTLOOK.CO"),
				},
			]);
			return result;
		},
		onSuccess(data) {
			updateFilterUsingSearchString(data, location.search);
		},
	});

	// The following code manages the "authoritativeness" of the URL.
	// The problem we are trying to solve is that the URL may contain
	// an invalid combination of filters, therefore it cannot be used
	// directly in the page. That's why we have a custom multiFilter state
	// which always contains a valid combination. Moreover, when the state changes
	// the URL is updated accordingly, while when the URL changes (e.g. because the
	// user clicked some link), the state should be updated only after validating the
	// parameters contained in the query string.
	const historyRef = useRef(history);
	const locationRef = useRef(location);
	const ignoreNextHistoryUpdateRef = useRef(false);
	useEffect(() => {
		historyRef.current = history;
	}, [history]);
	useEffect(() => {
		locationRef.current = location;
	}, [location]);
	const optionTreeDataRef = useRef<OptionTreeNode | null>(null);
	useEffect(() => {
		optionTreeDataRef.current = optionTree.data ?? null;
	}, [optionTree.data]);
	useEffect(() => {
		if (ignoreNextHistoryUpdateRef.current) {
			ignoreNextHistoryUpdateRef.current = false;
			return;
		}
		if (optionTreeDataRef.current) {
			updateFilterUsingSearchString(optionTreeDataRef.current, location.search);
		}
	}, [location.search, updateFilterUsingSearchString]);
	useEffect(() => {
		if (multiFilter) {
			const href = `?filter=${encodeURIComponent(multiFilter.assetClass ?? "")}&area=${encodeURIComponent(
				multiFilter.area ?? "",
			)}`;
			if (locationRef.current.search !== href) {
				ignoreNextHistoryUpdateRef.current = true;
				historyRef.current.push(href);
			}
		}
	}, [multiFilter]);

	useFunctionalAreas(
		() => ({
			areas: "outlook",
			data: { firstFilter: multiFilter?.assetClass, secondFilter: multiFilter?.area },
		}),
		[multiFilter?.assetClass, multiFilter?.area],
	);

	return (
		<>
			<PageHeader
				title="Outlook" // TODO: translate
				titleAction={
					<div className="flex items-center justify-between gap-2">
						<div>
							<OutlookWidgetData />
						</div>
						<div>
							<PageDownloadMenu
								area="outlook"
								actions={[
									{
										icon: "xls",
										label: "Market outlook",
										onClickAsync: async () =>
											downloadContentDisposition(
												await getApiGen(OutlookControllerV3ApiFactory).createExcelReport({
													responseType: "blob",
												}),
											),
										"data-qualifier": "Outlook/DropdownMenu/DropdownItem(XLSReport)",
									},
								]}
							/>
						</div>
					</div>
				}
			/>
			{optionTree.isError ? (
				t("SOMETHING_WENT_WRONG")
			) : optionTree.isLoading ? (
				<ProgressBar value="indeterminate" />
			) : (
				optionTree.data &&
				multiFilter && (
					<HierarchicalMultiSelect
						label={t("OUTLOOK.LOOKING_AT")}
						selection={[multiFilter.assetClass, multiFilter.area]}
						onChange={([assetClass, area]) => setMultiFilter({ assetClass, area })}
						optionTree={optionTree.data}
					/>
				)
			)}
			{multiFilter && optionTree.isFetched && (
				<>
					<UniverseFiltersContext.Provider
						value={{
							firstFilter: multiFilter.assetClass ?? "",
							secondFilter: multiFilter.area ?? "",
							thirdFilter: "",
						}}
					>
						<WidgetsGrid gridName={PAGE_NAME} />
					</UniverseFiltersContext.Provider>
				</>
			)}
		</>
	);
};

export default Outlook;
