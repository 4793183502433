import type {
	InvestmentSummary,
	ReviewTicker,
	UserCompositionColumnOrdering,
	UserCompositionColumnPreference,
	UserCompositionColumnPreferencePreferenceTypeEnum,
	UserEnhancementCompositionColumnOrdering,
	UserEnhancementCompositionColumnPreference,
	UserEnhancementCompositionColumnPreferencePreferenceTypeEnum,
} from "$root/api/api-gen";
import {
	IntegrationsControllerApiFactory,
	InvestmentEnhancementExportControllerApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentExportControllerApiFactory,
	InvestmentExportConverterType,
	InvestmentReportsControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import type { ColumnMetadata } from "$root/components/tables-extra/CustomizeColumns";
import { spawnCustomizeColumnsDialog } from "$root/components/tables-extra/CustomizeColumns";
import type { PortfolioAlert } from "$root/functional-areas/portfolio/alerts";
import { useUserValue } from "$root/functional-areas/user";
import useCompositionDownload from "$root/hooks/useCompositionDownload";
import { platformToast } from "$root/notification-system/toast";
import { usePortfolioStudioTableSettings } from "$root/pages/PortfoliosStudio/portfolio-studio-table-settings";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { actionsColumn } from "$root/ui-lib/interactive-collections/common-table-actions";
import { preventSubmitOnPressEnter } from "$root/utils/experimental";
import { downloadContentDisposition } from "$root/utils/files";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { Card } from "$root/widgets-architecture/layout/Card";
import { DropdownMenu, Icon, TableV2, sortRows } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useTranslation } from "react-i18next";
import { PortfolioQueryWidgetBase, WidgetStatus, portfolioWidgetMissingDataReason } from "../PortfolioWidgetStatus";
import { useCompositionColumn } from "./columns";
import { useSearchableInstrumentTable } from "$root/functional-areas/instruments/hooks";
import { DebouncedSearchInput } from "$root/components/DebouncedSearchInput";

type PortfolioCompositionProps = {
	portfolio: InvestmentSummary;
	enhanced: boolean;
	reportExcutionCounter: number;
	alerts: PortfolioAlert[];
};

export const PortfolioComposition = (props: PortfolioCompositionProps): JSX.Element => {
	const { enhanced, portfolio, reportExcutionCounter } = props;
	const uuid = props.portfolio?.uuid;

	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const investmentEnhancementReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const query = useQueryNoRefetch(["compositionProvider", uuid, enhanced, portfolio?.status, reportExcutionCounter], {
		queryFn: async () => {
			if (!uuid) {
				return {
					data: undefined,
					widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "Composition"),
				};
			}

			const data = await axiosExtract(
				props.enhanced
					? investmentEnhancementReportApi.getInvestmentComposition1(uuid)
					: investmentReportApi.getInvestmentComposition(uuid),
			);

			// PROPOSAL: we can either remap the response here or ask the BE to give us the
			// appropriate data status (letting us delete portfolioWidgetMissingDataReason)
			if (data.composition) {
				return {
					data: {
						composition: data.composition,
						userCompositionColumnOrderingPreferences: !enhanced
							? (await axiosExtract(portfolioStudioPreferencesApi.getUserCompositionColumnOrderingPreferences()))
									.userCompositionColumnPreferences
							: [],
						userEnhancementCompositionColumnOrderingPreferences: enhanced
							? (
									await axiosExtract(
										portfolioStudioPreferencesApi.getUserEnhancementCompositionColumnOrderingPreferences(),
									)
							  ).userEnhancementCompositionColumnPreferences
							: [],
					},
					widgetStatus: WidgetStatus.READY,
				};
			}

			return {
				data: undefined,
				widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "Composition"),
			};
		},
	});

	return (
		<PortfolioQueryWidgetBase query={query}>
			{(
				{ composition, userCompositionColumnOrderingPreferences, userEnhancementCompositionColumnOrderingPreferences },
				{ refetch },
			) => (
				<PortfolioCompositionInner
					ctx={props}
					rows={composition ?? []}
					columnPreferences={{
						current: userCompositionColumnOrderingPreferences ?? [],
						enhance: userEnhancementCompositionColumnOrderingPreferences ?? [],
					}}
					onAsyncChangeColumnPreferences={async (columns) => {
						if (enhanced) {
							const payload = {
								userEnhancementCompositionColumnPreferences: columns.map((preference) => ({
									enabled: preference.visible,
									preferenceType: preference.id as UserEnhancementCompositionColumnPreferencePreferenceTypeEnum,
								})),
							} satisfies UserEnhancementCompositionColumnOrdering;
							await portfolioStudioPreferencesApi.setUserEnhancementCompositionColumnOrderingPreferences(payload);
						} else {
							const payload = {
								userCompositionColumnPreferences: columns.map((preference) => ({
									enabled: preference.visible,
									preferenceType: preference.id as UserCompositionColumnPreferencePreferenceTypeEnum,
								})),
							} satisfies UserCompositionColumnOrdering;
							await portfolioStudioPreferencesApi.setUserCompositionColumnOrderingPreferences(payload);
						}

						await refetch();
					}}
				/>
			)}
		</PortfolioQueryWidgetBase>
	);
};

function PortfolioCompositionInner(props: {
	ctx: PortfolioCompositionProps;
	rows: ReviewTicker[];
	columnPreferences: {
		current: UserCompositionColumnPreference[];
		enhance: UserEnhancementCompositionColumnPreference[];
	};
	onAsyncChangeColumnPreferences: (
		columns: ColumnMetadata<
			UserEnhancementCompositionColumnPreferencePreferenceTypeEnum | UserCompositionColumnPreferencePreferenceTypeEnum
		>[],
	) => Promise<void>;
}) {
	const { filtered, setQuery, query } = useSearchableInstrumentTable(props.rows ?? [], {
		mode: "keyword",
	});

	const { columnPreferences, onAsyncChangeColumnPreferences } = props;
	const { enhanced, portfolio } = props.ctx;
	const user = useUserValue();
	const { t } = useTranslation();
	const {
		portfolioCompositionOrderByName,
		setPortfolioCompositionOrderByName,
		portfolioCompositionEnhanceOrderByName,
		setPortfolioCompositionEnhanceOrderByName,
	} = usePortfolioStudioTableSettings();

	const exportApi = useApiGen(InvestmentExportControllerApiFactory);
	const exportEnhancedApi = useApiGen(InvestmentEnhancementExportControllerApiFactory);
	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);

	const exportPortfolioComposition = async (downloadTarget: "composition" | "trades") => {
		const response = enhanced
			? downloadTarget === "composition"
				? await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "FULL_COMPOSITION", {
						responseType: "blob",
				  })
				: await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "TRADES_ONLY", { responseType: "blob" })
			: await exportApi.exportComposition(portfolio!.uuid!, { responseType: "blob" });

		trackMixPanelEvent("Portfolio", {
			Type: "Export",
			Area: downloadTarget,
			ID: portfolio!.uuid!,
		});
		downloadContentDisposition(response);
	};

	const { downloadInvestmentConversion } = useCompositionDownload();
	const customExportComposition = async (
		exportFormat: InvestmentExportConverterType,
		uuid: string,
		enhance?: boolean,
	) => {
		if (exportFormat === InvestmentExportConverterType.EasimTemplateConverter) {
			const composition = await axiosExtract(integrationsApi.exportInvestment(uuid, enhance));
			await axiosExtract(integrationsApi.convertTo(exportFormat, [composition], true));
			platformToast({
				children: "Sphere has taken over your request",
				severity: "info",
				icon: "Dowload",
			});
			return;
		}

		await downloadInvestmentConversion(exportFormat, uuid, enhance);
		trackMixPanelEvent("Portfolio", {
			Type: "Export",
			Area: "composition",
			ID: portfolio!.uuid!,
		});
	};

	const customizableColumns = (enhanced ? columnPreferences.enhance : columnPreferences.current ?? []).map((c) => ({
		label: c.preferenceType ? t(`TABLE.HEADERS.${c.preferenceType}`) : "-",
		id: c.preferenceType!,
		visible: c.enabled ?? false,
		disabled:
			c.preferenceType === "INSTRUMENT_NAME" ||
			c.preferenceType === "WEIGHT" ||
			c.preferenceType === "CURRENT_WEIGHT" ||
			c.preferenceType === "ENHANCED_WEIGHT" ||
			c.preferenceType === "DIFFERENCE",
		hidden: c.preferenceType === "INSTRUMENT_NAME",
	}));

	const columns = [
		...useCompositionColumn(
			enhanced ? columnPreferences.enhance : columnPreferences.current,
			props.ctx.portfolio,
			props.rows,
			props.ctx.alerts,
		),
		actionsColumn({
			onSettingsClick: () =>
				spawnCustomizeColumnsDialog({
					columns: customizableColumns,
					onSubmitAsync: onAsyncChangeColumnPreferences,
				}),
			dropdownActions: [],
		}),
	];

	return (
		<Card>
			<div className="flex justify-between mb-6">
				<DebouncedSearchInput
					query={query}
					onChange={setQuery}
					name="search"
					maxLength={60}
					placeholder={t("PORTFOLIOS.CONSTRAINTS_TARGETS.PORTFOLIO_LIST")}
					onKeyDown={preventSubmitOnPressEnter}
					style={{ width: 692 }}
				/>
				<DropdownMenu
					trigger={({ innerRef, open, ...forward }) => (
						<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
							<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
						</button>
					)}
					actions={[
						{
							icon: "xls",
							onClickAsync: async () => {
								await exportPortfolioComposition("composition");
							},
							label: t("COMPOSITION.DOWNLOAD_TITLE"),
						},
						portfolio?.status === "PROPOSAL_READY" &&
							enhanced && {
								icon: "xls",
								onClickAsync: async () => {
									await exportPortfolioComposition("trades");
								},
								label: t("COMPOSITION.DOWNLOAD_TRADES_TITLE"),
							},
					]}
				/>
			</div>
			<TableV2.BaseHScrollTable
				columns={columns}
				rows={sortRows({
					rows: filtered,
					columns,
					orderByArr: enhanced ? portfolioCompositionEnhanceOrderByName : portfolioCompositionOrderByName,
				})}
				pinnedColumns={[
					{ name: "instrument", side: "left" },
					{ name: "settings-action", side: "right" },
				]}
				orderBy={enhanced ? portfolioCompositionEnhanceOrderByName : portfolioCompositionOrderByName}
				onOrderByChange={enhanced ? setPortfolioCompositionEnhanceOrderByName : setPortfolioCompositionOrderByName}
			/>
		</Card>
	);
}
