import type { OrderBy } from "@mdotm/mdotui/components";
import { create } from "zustand";
import { persist, createJSONStorage, devtools } from "zustand/middleware";
import env from "$root/env";

export const usePortfolioStudioTableSettings = create(
	devtools(
		persist<{
			portfolioCompositionEnhanceOrderByName: OrderBy[];
			portfolioCompositionOrderByName: OrderBy[];
			portfolioListOrderByName: OrderBy[];
			referenceListOrderByName: OrderBy[];
			universeListOrderByName: OrderBy[];
			marketViewListOrderByName: OrderBy[];
			setPortfolioCompositionEnhanceOrderByName(newOrderBy: OrderBy[]): void;
			setPortfolioCompositionOrderByName(newOrderBy: OrderBy[]): void;
			setPortfolioListOrderByName(newOrderBy: OrderBy[]): void;
			setReferenceListOrderByName(newOrderBy: OrderBy[]): void;
			setUniverseListOrderByName(newOrderBy: OrderBy[]): void;
			setMarketViewListOrderByName(newOrderBy: OrderBy[]): void;
		}>(
			(set) => ({
				portfolioCompositionOrderByName: [],
				portfolioCompositionEnhanceOrderByName: [],
				portfolioListOrderByName: [{ columnName: "inception", direction: "asc" }],
				referenceListOrderByName: [],
				universeListOrderByName: [],
				marketViewListOrderByName: [],
				setPortfolioCompositionEnhanceOrderByName: (portfolioCompositionEnhanceOrderByName) => set({ portfolioCompositionEnhanceOrderByName }),
				setPortfolioCompositionOrderByName: (portfolioCompositionOrderByName) => set({ portfolioCompositionOrderByName }),
				setPortfolioListOrderByName: (portfolioListOrderByName) => set({ portfolioListOrderByName }),
				setReferenceListOrderByName: (referenceListOrderByName) => set({ referenceListOrderByName }),
				setUniverseListOrderByName: (universeListOrderByName) => set({ universeListOrderByName }),
				setMarketViewListOrderByName: (marketViewListOrderByName) => set({ marketViewListOrderByName }),
			}),
			{
				name: "portfolio-studio-table-settings",
				storage: createJSONStorage(() => localStorage),
			},
		),
		{ enabled: env.appEnv !== "production" },
	),
);
