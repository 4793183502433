import type { InvestmentContributionResponse } from "$root/api/api-gen";
import { InvestmentReportsControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import GraphLegend from "$root/components/GraphLegend";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import { useLocaleFormatters } from "$root/localization/hooks";
import {
	PortfolioQueryWidgetBase,
	portfolioWidgetMissingDataReason,
	WidgetStatus,
} from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { BarGraphPCSvg } from "$root/ui-lib/charts";
import { builtInSortFnFor } from "$root/utils/collections";
import { DrillDownByValueMap, getGraphMarkers, objSumRounded, roundCustomByStep } from "$root/utils/experimental";
import { parallelize } from "$root/utils/promise";
import { pxToRem } from "$root/utils/pxToRem";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { Checkbox, ScrollWrapper, Select, TableWithGroups } from "@mdotm/mdotui/components";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { HorizonType } from "../const";
import { horizonOptions } from "../const";

const categoryOptions = [
	{
		label: "Macro Asset Class vs Micro Asset Class",
		selector: "assetClass",
		vsSelector: "microAssetClass",
	},
	{
		label: "Macro Asset Class vs Geography",
		selector: "assetClass",
		vsSelector: "geography",
	},
	{
		label: "Micro Asset Class vs Geography",
		selector: "microAssetClass",
		vsSelector: "geography",
	},
	{
		label: "Geography vs Macro Asset Class",
		selector: "geography",
		vsSelector: "assetClass",
	},
	{
		label: "Geography vs Micro Asset Class",
		selector: "geography",
		vsSelector: "microAssetClass",
	},
];

const defaultCategorySelection = { selector: categoryOptions[0].selector, vsSelector: categoryOptions[0].vsSelector };
const ContributionVolatility = (props: ContextContent<typeof PortfolioContext>) => {
	const investmentReportsApi = useApiGen(InvestmentReportsControllerApiFactory);
	const { portfolio, selectedBenchmark } = props;
	const uuid = props.portfolio?.uuid;
	const benchmarkId = selectedBenchmark ?? portfolio?.primaryBenchmarkIdentifier ?? "";

	const [category, setCategory] = useState(defaultCategorySelection);
	const [horizon, setHorizon] = useState<HorizonType>(horizonOptions[0].value);
	const { t } = useTranslation();

	useWidgetOptions(
		() => ({
			title: t("PORTFOLIO_CONTRIBUTION_VOLATILITY.TITLE"),
			actionHeader: <InfoTooltip>{t("PORTFOLIO_CONTRIBUTION_VOLATILITY.TOOLTIP")}</InfoTooltip>,
		}),
		[t],
	);

	const query = useQueryNoRefetch(
		["Contribution", props.portfolio?.status, horizon, benchmarkId, props.reportExcutionCounter],
		{
			queryFn: async () => {
				if (!uuid || !benchmarkId) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "ContributionVolatility"),
					};
				}

				const [realizedVolatilityContribution, realizedPerformanceContribution] = await parallelize(
					[
						() => axiosExtract(investmentReportsApi.getRealizedVolatilityContribution(uuid, benchmarkId, horizon)),
						() => axiosExtract(investmentReportsApi.getRealizedPerformanceContribution(uuid, benchmarkId, horizon)),
					],
					{
						concurrency: 3,
					},
				);
				if (!realizedVolatilityContribution.current && !realizedPerformanceContribution.current) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "ContributionVolatility"),
					};
				}
				return {
					data: { realizedVolatilityContribution, realizedPerformanceContribution },
					widgetStatus: WidgetStatus.READY,
				};
			},
		},
	);

	return (
		<PortfolioQueryWidgetBase query={query}>
			{({ realizedPerformanceContribution, realizedVolatilityContribution }) => (
				<ContributionVolatilityInner
					ctx={props}
					realizedPerformanceContribution={realizedPerformanceContribution}
					realizedVolatilityContribution={realizedVolatilityContribution}
					onChangeCategory={setCategory}
					category={category}
					onChangeHorizon={setHorizon}
					horizon={horizon}
				/>
			)}
		</PortfolioQueryWidgetBase>
	);
};

const ContributionVolatilityInner = ({
	realizedPerformanceContribution,
	realizedVolatilityContribution,
	category,
	onChangeCategory,
	horizon,
	onChangeHorizon,
}: {
	ctx: ContextContent<typeof PortfolioContext>;
	realizedVolatilityContribution: InvestmentContributionResponse;
	realizedPerformanceContribution: InvestmentContributionResponse;
	category: { selector: string; vsSelector: string };
	onChangeCategory(opt: { selector: string; vsSelector: string }): void;
	horizon: HorizonType;
	onChangeHorizon(horizon: HorizonType): void;
}) => {
	const { formatNumber } = useLocaleFormatters();
	const { t } = useTranslation();

	const contributionData = useMemo(() => {
		const aggregateData = {
			volatility: (realizedVolatilityContribution.current ?? []).map((el) => ({
				assetClass: el.assetClass,
				geography: el.geography,
				microAssetClass: el.microAssetClass,
				originalVolatility: el,
				volatilityValue: el.value,
				id: `${el.assetClass}${el.geography}${el.microAssetClass}`.split(" ").join("_"),
			})),

			performance: (realizedPerformanceContribution.current ?? []).map((el) => ({
				assetClass: el.assetClass,
				geography: el.geography,
				microAssetClass: el.microAssetClass,
				originalPerformance: el,
				performanceValue: el.value,
				id: `${el.assetClass}${el.geography}${el.microAssetClass}`.split(" ").join("_"),
			})),
		};

		const decupledByID = [
			...new Set([...aggregateData.performance.map((el) => el.id), ...aggregateData.volatility.map((el) => el.id)]),
		];

		const result = decupledByID.map((id) => {
			const currentVTK = aggregateData.volatility.find((el) => el.id === id);
			const currentPTK = aggregateData.performance.find((el) => el.id === id);

			return {
				...currentVTK,
				...currentPTK,
			};
		});
		return result;
	}, [realizedPerformanceContribution, realizedVolatilityContribution]);
	const [filterBySignificant, setFilterBySignificant] = useState(true);
	const computedContribution = useMemo(() => {
		const drilldown = DrillDownByValueMap(
			contributionData ?? [],
			category.selector as keyof (typeof contributionData)[number],
			category.vsSelector as keyof (typeof contributionData)[number],
			{
				volatilityValue: (val) => objSumRounded(val, "volatilityValue"),
				performanceValue: (val) => objSumRounded(val, "performanceValue"),
				maxVol: (val) => objSumRounded(val, "volatilityValue"),
				minVol: (val) => objSumRounded(val, "volatilityValue"),
				maxPer: (val) => objSumRounded(val, "performanceValue"),
				minPer: (val) => objSumRounded(val, "performanceValue"),
			},
			(a, b) => b.volatilityValue - a.volatilityValue,
		);

		if (!filterBySignificant) {
			return drilldown;
		}
		return {
			...drilldown,
			drilldownData: drilldown.drilldownData
				.filter(
					(x) => Math.abs(x.computedData.volatilityValue) >= 0.01 || Math.abs(x.computedData.performanceValue) >= 0.01,
				)
				.map((x) => ({
					...x,
					drilldown: x.drilldown.filter(
						(y) => Math.abs(y.computedData.volatilityValue) >= 0.01 || Math.abs(y.computedData.performanceValue) >= 0.01,
					),
				})),
		};
	}, [category.selector, category.vsSelector, contributionData, filterBySignificant]);

	// Correction Of Graph Scala
	const graphLimits = useMemo(() => {
		const value = computedContribution.drilldownData.reduce(
			(acc, group) => {
				const rowValues = group.drilldown.reduce(
					(a, row) => {
						return {
							maxVolatility: Math.max(a.maxVolatility, row.computedData.maxVol, group.computedData.maxVol),
							maxPerformance: Math.max(a.maxPerformance, row.computedData.maxPer, group.computedData.maxPer),
							minVolatility: Math.min(a.minVolatility, row.computedData.minVol, group.computedData.minVol),
							minPerformance: Math.min(a.minPerformance, row.computedData.minPer, group.computedData.minPer),
						};
					},
					{ maxVolatility: 0, maxPerformance: 0, minVolatility: 0, minPerformance: 0 },
				);
				return {
					maxVolatility: Math.max(acc.maxVolatility, rowValues.maxVolatility),
					maxPerformance: Math.max(acc.maxPerformance, rowValues.maxPerformance),
					minVolatility: Math.min(acc.minVolatility, rowValues.minVolatility),
					minPerformance: Math.min(acc.minPerformance, rowValues.minPerformance),
				};
			},
			{ maxVolatility: 0, maxPerformance: 0, minVolatility: 0, minPerformance: 0 },
		);
		return {
			maxVolatility: roundCustomByStep(value.maxVolatility, 0.5),
			maxPerformance: roundCustomByStep(value.maxPerformance, 0.5),
			minVolatility: roundCustomByStep(value.minVolatility, 0.5),
			minPerformance: roundCustomByStep(value.minPerformance, 0.5),
		};
	}, [computedContribution.drilldownData]);

	// Generate Markers
	const graphColumnMarkers = useMemo(
		() => ({
			markersVolatility: getGraphMarkers(graphLimits.maxVolatility, graphLimits.minVolatility, 0.5, "%", 10),
			markersPerformance: getGraphMarkers(graphLimits.maxPerformance, graphLimits.minPerformance, 0.5, "%", 10),
		}),
		[graphLimits],
	);

	// Get constribution translation Object Options
	const constributionT = useMemo(
		() =>
			t(`PORTFOLIO_CONTRIBUTION_VOLATILITY`, {
				returnObjects: true,
			}),
		[t],
	);

	return (
		<div className="h-full">
			<div className="flex justify-between">
				<div className="inline-block">
					<Select
						value={category}
						onChange={onChangeCategory}
						options={categoryOptions.map(({ label, selector, vsSelector }) => ({
							label,
							value: { selector, vsSelector },
						}))}
					/>
				</div>
				<div className="inline-flex gap-4 items-center">
					<Checkbox
						onChange={() => setFilterBySignificant(!filterBySignificant)}
						checked={filterBySignificant}
						switchPosition="start"
						switchType="switch"
					>
						Significant values only
					</Checkbox>
					<Select value={horizon} onChange={onChangeHorizon} options={horizonOptions} />
				</div>
			</div>
			<div className="h-[85%] w-100 pb-3 mt-3 mb-2 px-2 flex flex-col">
				<ScrollWrapper startShadow={false}>
					<TableWithGroups
						headerRowClassList="sticky top-0 z-10 bg-white"
						groupedRows={computedContribution.drilldownData.map((el) => ({ ...el, rows: el.drilldown }))}
						groupRowKey={(r) => r.index}
						columns={[
							{
								header: constributionT.TABLE[category.selector as keyof typeof constributionT.TABLE],
								groupContent: (groupedRow) => <strong>{groupedRow.value as any /* TODO: fix types */}</strong>,
								content: (row) => row.value as any /* TODO: fix types */,
								sortFn: builtInSortFnFor("value"),
								name: "value",
								relativeWidth: 20,
							},
							{
								header: "PORTFOLIO PERFORMANCE",
								groupCellClassList: "font-semibold text-[#005C8B] tabular-nums",
								groupContent: (groupedRow) => `${formatNumber(groupedRow.computedData.performanceValue, 2)}%`,
								cellClassList: "text-[#005C8B] tabular-nums",
								content: (row) => `${formatNumber(row.computedData.performanceValue, 2)}%`,
								sortFn(a, b) {
									return a.computedData.performanceValue - b.computedData.performanceValue;
								},
								relativeWidth: 8,
								align: "end",
							},
							{
								orderable: false,
								header: (
									<div className="flex grow justify-between">
										{graphColumnMarkers.markersPerformance.map((m, i) => (
											<div key={`marker-${i}`} className="">
												{m.label}
											</div>
										))}
									</div>
								),
								groupContent: ({ computedData: { performanceValue } }) => {
									return (
										<BarGraphPCSvg
											classList="w-full"
											options={{
												resize: true,
												marksLabels: false,
												markerStep: 0.5,
												scale: { max: graphLimits.maxPerformance, min: graphLimits.minPerformance },
											}}
											data={[{ value: performanceValue, color: "#005C8B" }]}
										/>
									);
								},
								content: ({ computedData: { performanceValue } }) => {
									return (
										<BarGraphPCSvg
											classList="w-full"
											options={{
												resize: true,
												marksLabels: false,
												markerStep: 0.5,
												scale: { max: graphLimits.maxPerformance, min: graphLimits.minPerformance },
											}}
											data={[{ value: performanceValue, color: "#005C8B" }]}
										/>
									);
								},
								relativeWidth: 16,
							},
							{
								header: "PORTFOLIO VOLATILITY",
								groupCellClassList: "font-semibold text-[#572C7F] tabular-nums",
								groupContent: (groupedRow) => `${formatNumber(groupedRow.computedData.volatilityValue, 2)}%`,
								cellClassList: "text-[#572C7F] tabular-nums",
								content: (row) => `${formatNumber(row.computedData.volatilityValue, 2)}%`,
								sortFn(a, b) {
									return a.computedData.volatilityValue - b.computedData.volatilityValue;
								},
								relativeWidth: 8,
								align: "end",
							},
							{
								orderable: false,
								header: (
									<div className="flex grow justify-between">
										{graphColumnMarkers.markersVolatility.map((m, i) => (
											<div key={`marker-${i}`} className="">
												{m.label}
											</div>
										))}
									</div>
								),
								groupContent: ({ computedData: { volatilityValue } }) => {
									return (
										<BarGraphPCSvg
											classList="w-full"
											options={{
												resize: true,
												marksLabels: false,
												markerStep: 0.5,
												scale: { max: graphLimits.maxVolatility, min: graphLimits.minVolatility },
											}}
											data={[{ value: volatilityValue, color: "#572C7F" }]}
										/>
									);
								},
								content: ({ computedData: { volatilityValue } }) => {
									return (
										<BarGraphPCSvg
											classList="w-full"
											options={{
												resize: true,
												marksLabels: false,
												markerStep: 0.5,
												scale: { max: graphLimits.maxVolatility, min: graphLimits.minVolatility },
											}}
											data={[{ value: volatilityValue, color: "#572C7F" }]}
										/>
									);
								},
								relativeWidth: 16,
							},
						]}
					/>
				</ScrollWrapper>
			</div>
			<GraphLegend>
				<div className="legend-container light book" style={{ marginRight: pxToRem(16) }}>
					<ColoredRectangle color="#005C8B" variant="vertical" /> Portfolio performance
				</div>
				<div className="legend-container light book">
					<ColoredRectangle color="#7E45A9" variant="vertical" /> Portfolio volatility
				</div>
			</GraphLegend>
		</div>
	);
};

export default withContext(PortfolioContext)(ContributionVolatility);
