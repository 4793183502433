import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn } from "@mdotm/mdotui/react-extensions";
import type { ReactNode } from "react";
import { createContext, useContext, useMemo, useState } from "react";

export type PageCtx = {
	titleActionPortalTarget: HTMLDivElement | null;
	setTitleActionPortalTarget: (el: HTMLDivElement | null) => void;
	subtitlePortalTarget: HTMLDivElement | null;
	setSubtitlePortalTarget: (el: HTMLDivElement | null) => void;
};
export const PageCtx = createContext<PageCtx | null>(null);

export function PageCtxProvider(props: { children: ReactNode }): JSX.Element {
	const [titleActionPortalTarget, setTitleActionPortalTarget] = useState<HTMLDivElement | null>(null);
	const [subtitlePortalTarget, setSubtitlePortalTarget] = useState<HTMLDivElement | null>(null);
	const ctx = useMemo(
		() => ({
			titleActionPortalTarget,
			setTitleActionPortalTarget,
			subtitlePortalTarget,
			setSubtitlePortalTarget,
		}),
		[subtitlePortalTarget, titleActionPortalTarget],
	);

	return <PageCtx.Provider value={ctx}>{props.children}</PageCtx.Provider>;
}

export function PageCtxConsumer(props: { children: NodeOrFn<PageCtx> }): JSX.Element {
	const ctx = useContext(PageCtx);
	if (!ctx) {
		throw new Error("attempted to consume a PageCtx in a layout that doesn't provide one");
	}
	return <>{renderNodeOrFn(props.children, ctx)}</>;
}
