import type { FileResponseContentType, InvestmentStatuses } from "$root/api/api-gen";
import { statusIconMap } from "$root/pages/PortfoliosStudio";
import * as dateFn from "date-fns";
import type { CustomReportDataUnion } from "./hooks/useExtractReports";

export function mmToPx(millimeter: number): number {
	const testPoint = document.createElement("div");
	testPoint.style.height = `${millimeter}mm`;
	document.body.appendChild(testPoint);
	const heightStr = getComputedStyle(testPoint).height;
	document.body.removeChild(testPoint);
	return Math.ceil(Number(heightStr.replace("px", "")));
}

function composeHeaderSubtitle(status?: string) {
	return `Status: ${status ? statusIconMap[status as InvestmentStatuses].title.toUpperCase() : ""} / ${dateFn.format(
		new Date(),
		"do LLLL yyyy",
	)}`;
}

/**
 *
 * @param data CustomReportDataUnion
 * @returns title, subtitle
 */
export function discriminateSingleHeadingPage(data: CustomReportDataUnion): { title: string; subtitle: string } {
	const { payload, template } = data;
	switch (template) {
		case "mixed-portfolio":
			return {
				title: (() => {
					if (payload.portfolio.name) {
						if (payload.portfolio.status === "PROPOSAL_READY") {
							return `${payload.portfolio.name} - Current`;
						}
						return payload.portfolio.name;
					}
					return "-";
				})(),
				subtitle: composeHeaderSubtitle(payload.portfolio.status),
			};
		case "portfolio-reference":
			return { title: payload.portfolio.name ?? "-", subtitle: composeHeaderSubtitle(payload.portfolio.status) };
		case "custom-report":
			return { title: "POSITIONING INDICATORS", subtitle: dateFn.format(new Date(), "do LLLL yyyy") };
		case "mocked":
			return { title: "TITLE", subtitle: "SUBTITLE" };
		case "storyfolio":
			return { title: "Commentary", subtitle: payload.portfolio.name ?? "-" };
		default:
			return { title: "", subtitle: "" };
	}
}

export function generateImageObjectUrlFromFileResponse(file: FileResponseContentType): string {
	return `data:image/*;base64,${file.realData}`;
}

export function generateBase64Uri(file: File): Promise<string> {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.onload = () => resolve(fileReader.result as string);
		fileReader.onerror = reject;
		fileReader.readAsDataURL(file);
	});
}

export async function getDataUri(file: File | null): Promise<string | undefined> {
	try {
		if (!file) {
			throw Error("unable load file");
		}
		const url = await generateBase64Uri(file);
		return url;
	} catch (error) {
		console.error(error);
	}
}
