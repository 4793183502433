import { useFunctionalAreas } from "$root/App/context";
import type {
	AssetClassSummary,
	Chip,
	LabelValueV2,
	MacroContextDriverDTO,
	MarketOutlookTimeSeriesDTO,
} from "$root/api/api-gen";
import {
	MarketControllerV2ApiFactory,
	MarketUniverseControllerV3ApiFactory,
	OutlookControllerV3ApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { BubbleChartSvg, SmartBubbleFragment } from "$root/components/BubbleChart/BubbleChartSvg";
import { DonutChartSvg } from "$root/components/DonutChart/DonutChartSvg";
import type { OptionTreeNode } from "$root/components/HierarchicalMultiSelect";
import HierarchicalMultiSelect, { withPlaceholder } from "$root/components/HierarchicalMultiSelect";
import { OutlookWidgetData } from "$root/components/OutlookWidgetData";
import { PageHeader } from "$root/components/PageHeader";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import type { Option } from "$root/components/shared";
import PositioningBadge, {
	positioningPresets,
	positioningValueTypeMap,
} from "$root/functional-areas/market-view/PositioningBadge";
import { SentimentBadge } from "$root/functional-areas/market-view/analysis/SentimentBadge";
import type { SentimentType } from "$root/functional-areas/market-view/analysis/sentiment";
import TimeSeriesModal from "$root/functional-areas/outlook/TimeSeriesModal";
import type { SectorVariants } from "$root/hooks/useOutlookComposition";
import useOutlookComposition from "$root/hooks/useOutlookComposition";
import useTypeToLabel from "$root/hooks/useTypeToLabel";
import { useLocaleFormatters } from "$root/localization/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import colorGenerator from "$root/utils/chart/colorGenerator";
import { builtInCaseInsensitiveSortFor, groupBy, maxArrayLike } from "$root/utils/collections";
import { customObjectEntriesFn, customObjectValuesFn } from "$root/utils/experimental";
import { ForEach } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { capitalizeString, replaceSpaceWith_ } from "$root/utils/strings";
import { getThemeCssVars } from "$root/utils/theme";
import { getQueryParam } from "$root/utils/url";
import { UniverseFiltersContext } from "$root/widgets-architecture/contexts/filters";
import { Card } from "$root/widgets-architecture/layout/Card";
import WidgetsGrid from "$root/widgets-architecture/layout/WidgetsGrid";
import { CommentaryMDBlockContent } from "$root/widgets-architecture/widgets/CommentaryMDBlock";
import { ClickableArea, ProgressBar, ScrollWrapper, Svg, Text } from "@mdotm/mdotui/components";
import { useDebouncedMemo, useUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { mapBetweenRanges } from "@mdotm/mdotui/utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { ScatterPlotLimits } from "./data";
import { PageDownloadMenu } from "$root/components/PageDownloadMenu";
import { getApiGen } from "$root/api/factory";
import { downloadContentDisposition } from "$root/utils/files";

const PAGE_NAME = "ASSET_CLASS";

const DEFAULT_FILTER = "EQ";
const DEFAULT_AREA = "EU";
const DEFAULT_SECTOR = "ALL_SECTORS";

const AssetClass = (): JSX.Element => {
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const { getLabel } = useTypeToLabel();

	const [multiFilter, setMultiFilter] = useState<{
		assetClass: string | undefined;
		area: string | undefined;
		sector: string | undefined;
	} | null>(null);

	const updateFilterUsingSearchString = useCallback((optionTreeNode: OptionTreeNode, searchString: string) => {
		const candidateSelection = {
			assetClass: getQueryParam(searchString, "filter") || DEFAULT_FILTER,
			area: getQueryParam(searchString, "area") || DEFAULT_AREA,
			sector: getQueryParam(searchString, "sector") || DEFAULT_SECTOR,
		};

		const assetClassOption = optionTreeNode.find((opt) => opt.value === candidateSelection.assetClass);
		let areaOption:
			| undefined
			| (Option<string | undefined> & {
					subOptions?: OptionTreeNode;
			  });
		let sectorOption:
			| undefined
			| (Option<string | undefined> & {
					subOptions?: OptionTreeNode;
			  });
		if (assetClassOption) {
			areaOption = assetClassOption.subOptions?.find((opt) => opt.value === candidateSelection.area);
			if (areaOption) {
				sectorOption = areaOption.subOptions?.find((opt) => opt.value === candidateSelection.sector);
			}
		}
		if (
			!assetClassOption ||
			(!areaOption && assetClassOption.subOptions && assetClassOption.subOptions.length > 0) ||
			(!sectorOption && areaOption?.subOptions && areaOption?.subOptions.length > 0)
		) {
			setMultiFilter({
				assetClass: optionTreeNode[0].value,
				area: optionTreeNode[0].subOptions?.[0]?.value,
				sector: optionTreeNode[0].subOptions?.[0]?.subOptions?.[0]?.value,
			});
		} else {
			setMultiFilter(candidateSelection);
		}
	}, []);

	const universeApi = useApiGen(MarketUniverseControllerV3ApiFactory);

	const optionTree = useQueryNoRefetch(["assetClassOptionsOld"], {
		queryFn: async () => {
			const { data: allAssets } = await universeApi.universeControllerGetAsset();
			const sortByLabel = builtInCaseInsensitiveSortFor("label");

			const assetTypeMap = {
				Equity: "equity",
				"Fixed income": "fixedIncome",
				Commodities: "commodities",
			} as const;

			const {
				equity = [],
				fixedIncome = [],
				commodities = [],
			} = groupBy(allAssets, (asset) => assetTypeMap[asset.type as keyof typeof assetTypeMap]);

			const equityAreaOptions: Option<string>[] = equity
				.map((el) => ({
					value: replaceSpaceWith_(el.label!).toUpperCase(),
					label: getLabel(`EQ_${el.label}`) as string,
				}))
				.sort(sortByLabel);

			const equitySectorOptionsByArea = equity.reduce(
				(byArea, area) => {
					byArea[area.label!] = !area?.sector
						? []
						: [
								{
									value: "ALL_SECTORS",
									label: t("ASSET_CLASS_LABEL.ALL_SECTORS") as string,
								},
								...(area.sector ?? [])
									.map((el) => ({
										value: el.replace(" ", "_").toUpperCase(),
										label: t(
											// eslint-disable-next-line @typescript-eslint/ban-ts-comment
											// @ts-ignore
											`EQ_SECTOR.${el.replace(" ", "_").toUpperCase()}`,
											// eslint-disable-next-line @typescript-eslint/ban-ts-comment
											// @ts-ignore
											t(`EQ_SECTOR.${el.replace(" ", "").toUpperCase()}`),
										) as string,
									}))
									.sort(sortByLabel),
						  ];
					return byArea;
				},
				{} as Record<string, Option<string>[]>,
			);

			const commoditiesAreaOptions: Option<string>[] = commodities
				.map((el) => ({
					value: replaceSpaceWith_(el.label!).toUpperCase(),
					label: el.label!,
				}))
				.sort(sortByLabel);

			const commoditiesSectorOptionsByArea = commodities.reduce(
				(byArea, area) => {
					byArea[area.label!] = !area?.sector
						? []
						: [
								{
									value: "ALL_SECTORS",
									label: t("ASSET_CLASS_LABEL.ALL_SECTORS") as string,
								},
						  ];
					return byArea;
				},
				{} as Record<string, Option<string>[]>,
			);

			// temporary map assuming label are mapped like this
			const fixedIncomeGeo: Record<string, string> = t("FI_GEO", { returnObjects: true });
			const fixedIncomeAreaOptions: Option<string>[] = Array.from(
				new Set(fixedIncome.map((el) => el.label!.toString().slice(0, 3).replace(" ", "").toUpperCase())),
			)
				.map((el) => ({
					value: el,
					label: fixedIncomeGeo[el] ?? el,
				}))
				.sort(sortByLabel);
			// temporary map assuming label are mapped like this
			// const fixedIncomeSectors: Record<string, string> = t("FI_BOND", { returnObjects: true });
			const fixedIncomeSectorOptionsByArea = fixedIncome.reduce(
				(byArea, areaAndSector) => {
					const area = areaAndSector.label!.toString().slice(0, 3).replace(" ", "").toUpperCase();
					byArea[area] = byArea[area] || [];
					byArea[area].push({
						value: replaceSpaceWith_(areaAndSector.label!.replace(`${area} `, "")).toUpperCase(),
						label: areaAndSector.label!.replace(`${area} `, ""),
					});
					return byArea;
				},
				{} as Record<string, Option<string>[]>,
			);
			for (const k in fixedIncomeSectorOptionsByArea) {
				fixedIncomeSectorOptionsByArea[k].sort(sortByLabel);
			}

			const result: OptionTreeNode = withPlaceholder(
				t("OUTLOOK.SELECT_ASSET_CLASS"),
				[
					{
						value: "EQ",
						label: t("EQUITY"),
						subOptions: withPlaceholder(
							t("OUTLOOK.SELECT_GEOGRAPHY"),
							equityAreaOptions.map((areaOption) => ({
								...areaOption,
								subOptions: equitySectorOptionsByArea[areaOption.value],
							})),
						),
					},
					{
						value: "FI",
						label: t("OUTLOOK.FI"),
						subOptions: withPlaceholder(
							t("OUTLOOK.SELECT_GEOGRAPHY"),
							fixedIncomeAreaOptions.map((areaOption) => ({
								...areaOption,
								subOptions: fixedIncomeSectorOptionsByArea[areaOption.value],
							})),
						),
					},
					{
						value: "CO",
						label: t("OUTLOOK.CO"),
						subOptions: withPlaceholder(
							t("OUTLOOK.SELECT_TYPE"),
							commoditiesAreaOptions.map((areaOption) => ({
								...areaOption,
								subOptions: commoditiesSectorOptionsByArea[areaOption.value],
							})),
						),
					},
				].sort(sortByLabel),
			);
			return result;
		},
		onSuccess(data) {
			updateFilterUsingSearchString(data, location.search);
		},
	});

	// The following code manages the "authoritativeness" of the URL.
	// The problem we are trying to solve is that the URL may contain
	// an invalid combination of filters, therefore it cannot be used
	// directly in the page. That's why we have a custom multiFilter state
	// which always contains a valid combination. Moreover, when the state changes
	// the URL is updated accordingly, while when the URL changes (e.g. because the
	// user clicked some link), the state should be updated only after validating the
	// parameters contained in the query string.
	const historyRef = useUpdatedRef(history);
	const locationRef = useUpdatedRef(location);
	const ignoreNextHistoryUpdateRef = useRef(false);

	const optionTreeDataRef = useUpdatedRef<OptionTreeNode | null>(optionTree.data ?? null);

	useEffect(() => {
		if (ignoreNextHistoryUpdateRef.current) {
			ignoreNextHistoryUpdateRef.current = false;
			return;
		}
		if (optionTreeDataRef.current) {
			updateFilterUsingSearchString(optionTreeDataRef.current, location.search);
		}
	}, [location.search, optionTreeDataRef, updateFilterUsingSearchString]);

	useEffect(() => {
		if (multiFilter) {
			const href = `?filter=${encodeURIComponent(multiFilter.assetClass ?? "")}&area=${encodeURIComponent(
				multiFilter.area ?? "",
			)}${multiFilter.sector ? `&sector=${encodeURIComponent(multiFilter.sector)}` : ""}`;

			if (locationRef.current.search !== href) {
				ignoreNextHistoryUpdateRef.current = true;
				historyRef.current.replace(href);
			}
		}
	}, [historyRef, locationRef, multiFilter]);

	useFunctionalAreas(
		() => ({
			areas: "outlookFocus",
			data: { firstFilter: multiFilter?.assetClass, secondFilter: multiFilter?.area, thirdFilter: multiFilter?.sector },
		}),
		[multiFilter?.assetClass, multiFilter?.area, multiFilter?.sector],
	);

	return (
		<>
			<PageHeader
				title="Outlook Focus" // TODO: translate
				titleAction={
					<div className="flex items-center justify-between gap-2">
						<div>
							<OutlookWidgetData />
						</div>
						<div>
							<PageDownloadMenu
								area="outlook-focus"
								actions={[
									{
										icon: "xls",
										label: "Market outlook",
										onClickAsync: async () =>
											downloadContentDisposition(
												await getApiGen(OutlookControllerV3ApiFactory).createExcelReport({
													responseType: "blob",
												}),
											),
										"data-qualifier": "OutlookFocus/DropdownMenu/DropdownItem(XLSReport)",
									},
								]}
							/>
						</div>
					</div>
				}
			/>
			{optionTree.isError ? (
				t("SOMETHING_WENT_WRONG")
			) : optionTree.isLoading ? (
				<ProgressBar value="indeterminate" />
			) : (
				optionTree.data &&
				multiFilter && (
					<HierarchicalMultiSelect
						selection={[multiFilter.assetClass, multiFilter.area, multiFilter.sector]}
						optionTree={optionTree.data}
						label={t("OUTLOOK.LOOKING_AT")}
						onChange={([newAssetClass, newArea, newSector]) => {
							setMultiFilter({
								assetClass: newAssetClass,
								area: newArea,
								sector: newSector,
							});
						}}
					/>
				)
			)}
			{/* TODO: when an item is selected by clicking on a donut "slice", we need to scroll the card into view */}
			{multiFilter && optionTree.isFetched && (
				<>
					<MacroDynamicsSection multiFilter={multiFilter} />
					{/* TODO: remove widgets according to figma */}
					<UniverseFiltersContext.Provider
						value={{
							firstFilter: multiFilter.assetClass ?? "",
							secondFilter: multiFilter.area ?? "",
							thirdFilter: multiFilter.sector ?? "",
						}}
					>
						<WidgetsGrid gridName={PAGE_NAME} />
					</UniverseFiltersContext.Provider>
				</>
			)}
		</>
	);
};

export default AssetClass;

type PerformanceIndicatorsProps = Required<MacroContextDriverDTO> & { color: string };
function MacroDynamicsSection({
	multiFilter,
}: {
	multiFilter: { assetClass?: string; area?: string; sector?: string };
}) {
	const sentimentList = ["negative", "neutral", "positive"] satisfies SentimentType[];

	const outlookFocusApi = useApiGen(OutlookControllerV3ApiFactory);
	const marketApi = useApiGen(MarketControllerV2ApiFactory);

	function validateValue<T>(data: T): boolean | T {
		return data !== null && data !== undefined ? data : false;
	}

	const { assetClass, geography, sector } = useOutlookComposition(
		multiFilter?.assetClass ?? "",
		multiFilter?.area ?? "",
		multiFilter?.sector ?? "",
	);

	const queryMacroContextDrivers = useCallback(async () => {
		const macroDrivers = await axiosExtract(marketApi.retrieveMacroContextDriver(assetClass, geography, sector));
		return macroDrivers
			.flatMap((x, i) => {
				const isInvalid = customObjectValuesFn(x).some((macroContext) => validateValue(macroContext) === false);

				if (isInvalid) {
					return [];
				}

				return [
					{
						...x!,
						// FIXME: why is the BE returning level/trend instead of null/undefined for the Market Sentiment?
						...(x.label === "Quantitative Market Sentiment" ? { level: undefined, trend: undefined } : {}),
						// FIXME: can we not depend on the label to do this?
						current:
							x.label === "Quantitative Market Sentiment"
								? sentimentList[Math.floor(Number(x.current) - 1)]
								: Number(x.current),
						color: colorGenerator(i, colors),
						returnVsPeers: Math.floor(x.returnVsPeers ?? 0) === 0 ? 1 : x.returnVsPeers,
						volatilityVsPeers: Math.floor(x.volatilityVsPeers ?? 0) === 0 ? 1 : x.volatilityVsPeers,
						diversificationVsPeers: Math.floor(x.diversificationVsPeers ?? 0) === 0 ? 1 : x.diversificationVsPeers,
					} as Omit<PerformanceIndicatorsProps, "current"> & { current: string | number },
				];
			})
			.sort((a, b) => (a.relevance > b.relevance ? -1 : 1));
	}, [assetClass, geography, marketApi, sector, sentimentList]);

	return (
		<div className="mb-4">
			<ReactQueryWrapper
				queryKey={["outlookSummary", assetClass, geography, sector]}
				queryFn={() => axiosExtract(outlookFocusApi.retrieveAcMetricsStandard(assetClass, geography, sector))}
			>
				{(data) => (
					<>
						<div className="mb-3">
							<ReactQueryWrapper
								queryKey={["VaR", assetClass, geography, sector]}
								queryFn={() => axiosExtract(marketApi.retrieveVarByAssetClass(assetClass, geography, sector))}
							>
								{(VaR) => <SummaryCard summary={data} VaR={VaR} />}
							</ReactQueryWrapper>
						</div>
						<div className="grid grid-cols-3 mb-3 gap-3">
							<CurrentPositioningCard summary={data} assetClass={assetClass} geography={geography} sector={sector} />
							<Card classList="col-span-2 max-h-[240px]" title="Comment">
								<CommentaryMDBlockContent
									fixedCommentary
									firstFilter={multiFilter.assetClass ?? ""}
									secondFilter={multiFilter.area ?? ""}
									thirdFilter={multiFilter.sector ?? ""}
								/>
							</Card>
						</div>
					</>
				)}
			</ReactQueryWrapper>
			<ReactQueryWrapper
				queryKey={["macroContextDrivers", assetClass, geography, sector]}
				queryFn={queryMacroContextDrivers}
			>
				{(data) => (
					<InteractiveMacroDriverCard
						performanceIndicators={data ?? []}
						assetClass={assetClass}
						geography={geography}
						sector={sector}
					/>
				)}
			</ReactQueryWrapper>
		</div>
	);
}

function InteractiveMacroDriverCard({
	performanceIndicators,
	assetClass,
	sector,
}: {
	performanceIndicators: Array<Omit<PerformanceIndicatorsProps, "current"> & { current: string | number }>;
	assetClass: "EQUITY" | "COMMODITIES" | "FIXED_INCOME" | "FIXEDINCOME";
	geography: "EU" | "JP" | "US" | "UK" | "EM" | "GLOBAL" | "EU_SMALL_MID_CAP" | "PACIFIC_EX_JP";
	sector: SectorVariants;
}) {
	const [selectedIndicatorIndex, setSelectedIndicatorIndex] = useState<number | null>(null);
	const [_hoveringIndicatorIndex, setHoveringIndicatorIndex] = useState<number | null>(null);
	const { value: hoveringIndicatorIndex } = useDebouncedMemo(() => _hoveringIndicatorIndex, [_hoveringIndicatorIndex], {
		debounceInterval: 30,
	});
	const maxRelevance = maxArrayLike(performanceIndicators, (indicator) => indicator.relevance ?? 0);
	const { returnsLimits, volatilityLimits } = useMemo(() => {
		if (assetClass !== "EQUITY") {
			const acScatterPlotLimits = ScatterPlotLimits[assetClass];
			return {
				returnsLimits: acScatterPlotLimits.RETURNS,
				volatilityLimits: acScatterPlotLimits.VOLATILITY,
			};
		}

		if (sector !== "ALL") {
			const acScatterPlotLimits = ScatterPlotLimits["EQUITYGEO"];
			return {
				returnsLimits: acScatterPlotLimits.RETURNS,
				volatilityLimits: acScatterPlotLimits.VOLATILITY,
			};
		}

		const acScatterPlotLimits = ScatterPlotLimits["EQUITYSECTORS"];
		return {
			returnsLimits: acScatterPlotLimits.RETURNS,
			volatilityLimits: acScatterPlotLimits.VOLATILITY,
		};
	}, [assetClass, sector]);

	const { formatNumber } = useLocaleFormatters();

	const performanceTodayScrollableAreaRef = useRef<HTMLDivElement | null>(null);
	const performanceCardContainerRef = useRef<HTMLDivElement | null>(null);

	const levelsLabel = {
		1: "Low",
		2: "Mid",
		3: "High",
	};

	const trendsLabel = {
		1: "Down",
		2: "Lateral",
		3: "Up",
	};

	function macroDynamicsColorGenerator(
		value: number,
		range: { min: number; max: number; intermediateMin: number; intermediateMax: number; invert?: boolean },
	) {
		const ranges = {
			strong_underweight: range.invert ? { min: range.max, max: Infinity } : { min: -Infinity, max: range.min },
			underweight: range.invert
				? { min: range.intermediateMax, max: range.max }
				: { min: range.min, max: range.intermediateMin },
			neutral: range.invert
				? { min: range.intermediateMin, max: range.intermediateMax }
				: { min: range.intermediateMin, max: range.intermediateMax },
			overweight: range.invert
				? { min: range.min, max: range.intermediateMin }
				: { min: range.intermediateMax, max: range.max },
			strong_overweight: range.invert ? { min: -Infinity, max: range.min } : { min: range.max, max: Infinity },
		};

		const colorsMap = {
			strong_underweight: themeCSSVars.palette_R500,
			underweight: themeCSSVars.palette_R300,
			neutral: themeCSSVars.palette_S300,
			overweight: themeCSSVars.palette_P300,
			strong_overweight: themeCSSVars.palette_P500,
		} satisfies Record<keyof typeof ranges, string>;

		const currentRange = customObjectEntriesFn(ranges)
			.reverse()
			.find(([_key, r]) => r.min <= value && r.max >= value)![0];

		return colorsMap[currentRange];
	}

	const ranges = {
		strong_underweight: { min: 0, max: 10 },
		underweight: { min: 10, max: 30 },
		neutral: { min: 30, max: 70 },
		overweight: { min: 70, max: 90 },
		strong_overweight: { min: 90, max: 100 },
	} as const;

	const threshold = [
		ranges.strong_underweight.min,
		ranges.strong_underweight.max,
		ranges.underweight.max,
		ranges.neutral.max,
		ranges.overweight.max,
		ranges.strong_overweight.max,
	] as const;

	function ordinal(n: number) {
		const stringified = n.toFixed(0);
		const lastDigit = stringified.slice(-1);
		if (lastDigit === "1") {
			return `${stringified}st`;
		}
		if (lastDigit === "2") {
			return `${stringified}nd`;
		}
		if (lastDigit === "3") {
			return `${stringified}rd`;
		}
		return `${stringified}th`;
	}

	function crossSectionalColorGenerator(value: number) {
		if (value >= ranges.strong_underweight.min && value < ranges.strong_underweight.max) {
			return themeCSSVars.palette_P500;
		}

		if (value >= ranges.underweight.min && value < ranges.underweight.max) {
			return themeCSSVars.palette_P300;
		}

		if (value >= ranges.neutral.min && value < ranges.neutral.max) {
			return themeCSSVars.palette_S300;
		}

		if (value >= ranges.overweight.min && value < ranges.overweight.max) {
			return themeCSSVars.palette_R300;
		}

		return themeCSSVars.palette_R500;
	}

	function syncScrollableAreaByIndex(index: number) {
		setSelectedIndicatorIndex(selectedIndicatorIndex === index ? null : index);
		const cardEl = performanceCardContainerRef.current?.querySelector(`[data-index="${index}"]`) as
			| HTMLElement
			| undefined;
		if (cardEl && performanceTodayScrollableAreaRef.current) {
			performanceTodayScrollableAreaRef.current.scrollLeft =
				cardEl.offsetLeft + cardEl.offsetWidth / 2 - performanceTodayScrollableAreaRef.current!.clientWidth / 2;
		}
	}

	return (
		<Card title="Driving macro context" classList="mb-3">
			<div
				className={`-mx-4 px-4 py-2 bg-[${themeCSSVars.palette_N20}] border-t border-b border-[color:${themeCSSVars.palette_N100}]`}
			>
				<Text as="div" type="Body/L/Bold">
					Relevant variables
				</Text>

				<ScrollWrapper direction="horizontal" innerRef={performanceTodayScrollableAreaRef}>
					<div className="flex gap-2 py-3" ref={performanceCardContainerRef}>
						<ForEach collection={performanceIndicators}>
							{({ item, index }) => {
								const htmlWidth = 150;
								const viewBoxWidth = 150;
								const diameter = mapBetweenRanges(item?.relevance, 0, maxRelevance, 0, 120);
								return (
									<div
										data-index={index}
										/* className={`border border-[${themeCSSVars.palette_N50}]`} */ className="relative"
									>
										<ClickableArea
											onMouseEnter={() => setHoveringIndicatorIndex(index)}
											onMouseLeave={() => setHoveringIndicatorIndex(null)}
											onClick={() => setSelectedIndicatorIndex(selectedIndicatorIndex === index ? null : index)}
											wrapperAppearance={{
												classList: {
													[`relative z-0 min-w-[300px] bg-white rounded-md p-2 border transition-transform`]: true,
													"shadow-md": !(hoveringIndicatorIndex === index || selectedIndicatorIndex === index),
													"-translate-y-1 shadow-lg":
														hoveringIndicatorIndex === index || selectedIndicatorIndex === index,

													[`border-[${themeCSSVars.palette_N50}]`]: selectedIndicatorIndex !== index,
													[`border-[${themeCSSVars.palette_N200}]`]: selectedIndicatorIndex === index,
												},
											}}
										>
											<div className="absolute z-20">
												<div className="flex items-center gap-2">
													<ColoredRectangle variant="vertical" color={item.color} />
													<Text type="Body/L/Bold">{item.label}</Text>
												</div>
											</div>
											<div className="flex items-center gap-2">
												<div className="flex-1 min-w-0 flex flex-col gap-4">
													<div>
														<div>
															<Text type="Body/S/Book">Current value</Text>
														</div>
														<div>
															{typeof item.current === "number" ? (
																<Text type="Body/M/Bold">{`${formatNumber(item.current)}%`}</Text>
															) : (
																<SentimentBadge
																	sentiment={
																		item.current as Exclude<SentimentType, "super-positive" | "super-negative">
																	}
																	indicator="driver"
																>
																	{item.current}
																</SentimentBadge>
															)}
														</div>
													</div>
													{item.level && item.trend && (
														<div>
															<div>
																<Text type="Body/S/Book">level - trend</Text>
															</div>
															<div>
																<Text type="Body/M/Bold" classList="uppercase">
																	{levelsLabel[item.level as unknown as keyof typeof levelsLabel]}-
																	{trendsLabel[item.trend as unknown as keyof typeof trendsLabel]}
																</Text>
															</div>
														</div>
													)}
												</div>
												<div className="relative z-0">
													<div className="absolute z-10 inset-x-0 flex justify-center top-12">
														<Text type="Body/S/Book" color={themeCSSVars.palette_N500}>
															Relevance
														</Text>
													</div>
													<Svg
														classList="relative z-0"
														style={{
															width: htmlWidth,
															height: htmlWidth,
														}}
														viewBox={{ width: viewBoxWidth, height: viewBoxWidth }}
													>
														<SmartBubbleFragment
															centerX={viewBoxWidth / 2}
															centerY={viewBoxWidth / 2}
															color={themeCSSVars.palette_N100}
															diameter={diameter}
															text={`${formatNumber(item.relevance, 0)}%`}
															textColor={themeCSSVars.palette_N700}
															preferredOverflowPosition="bottom"
														/>
													</Svg>
												</div>
											</div>
										</ClickableArea>
									</div>
									/* {item.tooltip && (
								<InfoTooltip classList="absolute right-2 top-2">{item.tooltip}</InfoTooltip>
							)} */
								);
							}}
						</ForEach>
					</div>
				</ScrollWrapper>
			</div>
			<div className="grid grid-cols-3 p-4 gap-4 min-w-0">
				<div className="h-[540px] flex min-w-0">
					<div className="flex flex-col flex-1 min-w-0">
						<Text as="div" type="Body/L/Bold">
							Relevant variables
						</Text>
						<DonutChartSvg
							onSectorClick={(_sector, index) => syncScrollableAreaByIndex(index)}
							onSectorMouseEnter={(_sector, index) => setHoveringIndicatorIndex(index)}
							onSectorMouseLeave={() => setHoveringIndicatorIndex(null)}
							hoveringSectorIndex={hoveringIndicatorIndex}
							selectedSectorIndex={selectedIndicatorIndex}
							classList="flex-1 min-w-0"
							innerText={
								selectedIndicatorIndex === null && hoveringIndicatorIndex === null
									? [{ text: "RELEVANCE" }]
									: [
											{
												text: `${
													performanceIndicators[(hoveringIndicatorIndex ?? selectedIndicatorIndex)!].relevance
												}%`,
												className: "font-semibold text-base",
											},
											{
												text: performanceIndicators[(hoveringIndicatorIndex ?? selectedIndicatorIndex)!].label,
											},
									  ]
							}
							sectors={performanceIndicators.map((indicator) => ({
								label: indicator.label,
								value: indicator.relevance,
								color: indicator.color,
							}))}
						/>
					</div>
					<div className={`h-full w-px bg-[${themeCSSVars.palette_N100}] -mt-4`} />
				</div>
				<div className="col-span-2 min-w-0">
					<Text as="div" type="Body/L/Bold" classList="mb-4">
						Performance in similar periods
					</Text>
					<div className="grid grid-cols-5">
						<div className={`col-span-2 min-w-0 border-r border-[color:${themeCSSVars.palette_N100}] border-solid`}>
							<div className="flex justify-center">
								<div className="flex flex-col items-center">
									<Text as="p" type="Body/M/Book" classList="!font-semibold uppercase w-fit text-center mb-2.5">
										RETURNS
									</Text>
									<div className={`h-px w-8 bg-[${themeCSSVars.palette_N100}]`} />
								</div>
							</div>
							<div className="flex h-[460px] min-w-0">
								<BubbleChartSvg
									boundsMode="unbounded"
									selectedBubbleIndex={selectedIndicatorIndex}
									hoveringBubbleIndex={hoveringIndicatorIndex}
									onCircleClick={(_, i) => syncScrollableAreaByIndex(i)}
									onCircleMouseEnter={(_, i) => setHoveringIndicatorIndex(i)}
									onCircleMouseLeave={() => setHoveringIndicatorIndex(null)}
									bubbles={performanceIndicators.map((indicator) => ({
										color: macroDynamicsColorGenerator(indicator.returnTimeSeries, {
											max: returnsLimits[returnsLimits.length - 1],
											min: returnsLimits[0],
											intermediateMax: returnsLimits[returnsLimits.length - 2],
											intermediateMin: returnsLimits[1],
										}),
										label: `${formatNumber(indicator.returnTimeSeries)}%`,
										size: indicator.relevance,
										value: indicator.returnTimeSeries,
										hide: indicator.label === "Quantitative Market Sentiment",
									}))}
									classList="flex-1 h-full min-w-0"
									bottomLabel="Time Series"
									labels={returnsLimits.map((range) => ({ text: `${range}%`, threshold: range }))}
								/>
								<BubbleChartSvg
									boundsMode="bounded"
									selectedBubbleIndex={selectedIndicatorIndex}
									hoveringBubbleIndex={hoveringIndicatorIndex}
									onCircleClick={(_, i) => syncScrollableAreaByIndex(i)}
									onCircleMouseEnter={(_, i) => setHoveringIndicatorIndex(i)}
									onCircleMouseLeave={() => setHoveringIndicatorIndex(null)}
									bubbles={performanceIndicators.map((indicator) => ({
										color: crossSectionalColorGenerator(indicator.returnVsPeers),
										label: ordinal(indicator.returnVsPeers),
										size: indicator.relevance,
										value: indicator.returnVsPeers,
									}))}
									bottomLabel="Cross sectional"
									flipY
									classList="flex-1 h-full min-w-0"
									labels={threshold.map((x) => ({ text: formatNumber(x, 0), threshold: x }))}
								/>
							</div>
						</div>
						<div className={`col-span-2 min-w-0 border-r border-[color:${themeCSSVars.palette_N100}] border-solid`}>
							<div className="flex justify-center">
								<div className="flex flex-col items-center">
									<Text as="p" type="Body/M/Book" classList="!font-semibold uppercase w-fit text-center mb-2.5">
										VOLATILITY
									</Text>
									<div className={`h-px w-8 bg-[${themeCSSVars.palette_N100}]`} />
								</div>
							</div>
							<div className="flex h-[460px] min-w-0">
								{/* // volatility should be inverted as less volatility is more is positive */}
								<BubbleChartSvg
									flipY
									boundsMode="unbounded"
									selectedBubbleIndex={selectedIndicatorIndex}
									hoveringBubbleIndex={hoveringIndicatorIndex}
									onCircleClick={(_, i) => syncScrollableAreaByIndex(i)}
									onCircleMouseEnter={(_, i) => setHoveringIndicatorIndex(i)}
									onCircleMouseLeave={() => setHoveringIndicatorIndex(null)}
									bubbles={performanceIndicators.map((indicator) => ({
										color: macroDynamicsColorGenerator(indicator.volatilityTimeSeries, {
											max: volatilityLimits[returnsLimits.length - 1],
											min: volatilityLimits[0],
											intermediateMax: volatilityLimits[returnsLimits.length - 2],
											intermediateMin: volatilityLimits[1],
											invert: true,
										}),
										label: `${formatNumber(indicator.volatilityTimeSeries)}%`,
										size: indicator.relevance,
										value: indicator.volatilityTimeSeries,
										hide: indicator.label === "Quantitative Market Sentiment",
									}))}
									bottomLabel="Time Series"
									classList="flex-1 h-full min-w-0"
									labels={volatilityLimits.map((range) => ({ text: `${range}%`, threshold: range }))}
								/>
								<BubbleChartSvg
									boundsMode="bounded"
									selectedBubbleIndex={selectedIndicatorIndex}
									hoveringBubbleIndex={hoveringIndicatorIndex}
									onCircleClick={(_, i) => syncScrollableAreaByIndex(i)}
									onCircleMouseEnter={(_, i) => setHoveringIndicatorIndex(i)}
									onCircleMouseLeave={() => setHoveringIndicatorIndex(null)}
									bubbles={performanceIndicators.map((indicator) => ({
										color: crossSectionalColorGenerator(indicator.volatilityVsPeers),
										label: ordinal(indicator.volatilityVsPeers),
										size: indicator.relevance,
										value: indicator.volatilityVsPeers,
									}))}
									bottomLabel="Cross sectional"
									flipY
									classList="flex-1 h-full min-w-0"
									labels={threshold.map((x) => ({ text: formatNumber(x, 0), threshold: x }))}
								/>
							</div>
						</div>
						<div className="col-span-1 min-w-0">
							<div className="flex justify-center">
								<div className="flex flex-col items-center">
									<Text as="p" type="Body/M/Book" classList="!font-semibold uppercase w-fit text-center mb-2.5">
										DIVERSIFICATION POWER
									</Text>
									<div className={`h-px w-8 bg-[${themeCSSVars.palette_N100}]`} />
								</div>
							</div>
							<div className="flex h-[460px] min-w-0">
								<BubbleChartSvg
									boundsMode="bounded"
									selectedBubbleIndex={selectedIndicatorIndex}
									hoveringBubbleIndex={hoveringIndicatorIndex}
									onCircleClick={(_, i) => syncScrollableAreaByIndex(i)}
									onCircleMouseEnter={(_, i) => setHoveringIndicatorIndex(i)}
									onCircleMouseLeave={() => setHoveringIndicatorIndex(null)}
									bubbles={performanceIndicators.map((indicator) => ({
										color: crossSectionalColorGenerator(indicator.diversificationVsPeers),
										label: ordinal(indicator.diversificationVsPeers),
										size: indicator.relevance,
										value: indicator.diversificationVsPeers,
									}))}
									bottomLabel="Cross sectional"
									flipY
									classList="flex-1 h-full min-w-0"
									labels={threshold.map((x) => ({ text: formatNumber(x, 0), threshold: x }))}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Card>
	);
}

function SummaryCard({ summary, VaR }: { summary: AssetClassSummary; VaR: Array<LabelValueV2> }) {
	const { formatNumber } = useLocaleFormatters();
	return (
		<Card>
			<div className="flex gap-4">
				<ForEach
					collection={
						[
							[
								"RETURNS",
								["Expected", summary.expectedReturn],
								[
									["YTD", summary.return?.returnYTD],
									["1M", summary.return?.return1M],
									["3M", summary.return?.return3M],
									["6M", summary.return?.return6M],
									["1Y", summary.return?.return1Y],
								],
							],
							[
								"VOLATILITY",
								["Ann. Expected", summary.expectedVolatility],
								[
									["YTD", summary.volatility?.volatilityYTD],
									["1M", summary.volatility?.volatility1M],
									["3M", summary.volatility?.volatility3M],
									["6M", summary.volatility?.volatility6M],
									["1Y", summary.volatility?.volatility1Y],
								],
							],
							["VaR", ["Expected 95% 1M", VaR[0]?.value?.value], [["95% 3Y", VaR[1]?.value?.value]]],
						] as const
					}
				>
					{({ item: [title, [highlightLabel, highlightValue], subItems] }) => (
						<div className="flex-1 flex gap-1.5">
							<div
								className={`px-2 py-1.5 bg-[color:${themeCSSVars.palette_N50}] rounded flex justify-center items-center`}
							>
								<Text as="div" type="Body/M/Bold" color={themeCSSVars.palette_N800}>
									{title}
								</Text>
							</div>
							<div>
								<div className="relative z-0 inline">
									<Text type="Body/S/Light-UPPERCASE" color={themeCSSVars.palette_N400}>
										{highlightLabel}
									</Text>
									&nbsp;
									<Text type="Body/L/Bold">{formatNumber(highlightValue)}%</Text>
									{/* <div
										className={`border-l border-l-[${themeCSSVars.palette_N100}] h-full inset-y-0 -right-3 absolute z-10`}
									/> */}
								</div>
								<div className="flex flex-row gap-4">
									<ForEach collection={subItems}>
										{({ item: [label, value] }) => (
											<div>
												<Text type="Body/S/Light-UPPERCASE" color={themeCSSVars.palette_N400}>
													{label}
												</Text>
												&nbsp;
												<Text type="Body/M/Bold">{formatNumber(value)}%</Text>
											</div>
										)}
									</ForEach>
								</div>
							</div>
						</div>
					)}
				</ForEach>
			</div>
		</Card>
	);
}

const colors = [
	getThemeCssVars(themeCSSVars.palette_S500),
	getThemeCssVars(themeCSSVars.palette_W500),
	getThemeCssVars(themeCSSVars.palette_R500),
	getThemeCssVars(themeCSSVars.palette_B500),
	getThemeCssVars(themeCSSVars.palette_A500),
	getThemeCssVars(themeCSSVars.palette_D500),
	getThemeCssVars(themeCSSVars.palette_V500),
	getThemeCssVars(themeCSSVars.palette_P500),
	getThemeCssVars(themeCSSVars.palette_S700),
	getThemeCssVars(themeCSSVars.palette_V700),
];

function Dot({ color }: { color: string }) {
	return (
		<Svg
			viewBox={{
				width: 8,
				height: 8,
			}}
		>
			<rect width="8" height="8" rx="4" fill={color} />,
		</Svg>
	);
}

function CurrentPositioningCard({
	summary,
	assetClass,
	geography,
	sector,
}: {
	summary: AssetClassSummary;
	assetClass?: string;
	geography?: string;
	sector?: string;
}) {
	const [triggerTimeSeriesModalCount, setTriggerTimeSeriesModalCount] = useState(0);
	const [cacheMarketOutlookSeries, setCacheMarketOutlookSeries] = useState<MarketOutlookTimeSeriesDTO | undefined>(
		undefined,
	);

	const marketApi = useApiGen(MarketControllerV2ApiFactory);

	function feelsConverter({ type, value }: Chip) {
		if (!type || !value) {
			return [];
		}

		const levelRiskMap = {
			high: { ratio: { min: 1, max: 1 }, label: "Low", trend: "positive", indicator: "regime" },
			mid: { ratio: { min: 2, max: 2 }, label: "Mid", trend: "neutral", indicator: "regime" },
			low: { ratio: { min: 3, max: 3 }, label: "High", trend: "negative", indicator: "regime" },
		} as const;

		const invertedLevelMap = {
			high: { ratio: { min: 1, max: 2 }, label: "High", trend: "negative", indicator: "driver" },
			mid: { ratio: { min: 3, max: 3 }, label: "Mid", trend: "neutral", indicator: "driver" },
			low: { ratio: { min: 4, max: 5 }, label: "Low", trend: "positive", indicator: "driver" },
		} as const;

		const levelsMap = {
			high: { ratio: { min: 4, max: 5 }, label: "High", trend: "positive", indicator: "driver" },
			mid: { ratio: { min: 3, max: 3 }, label: "Mid", trend: "neutral", indicator: "driver" },
			low: { ratio: { min: 1, max: 2 }, label: "Low", trend: "negative", indicator: "driver" },
		} as const;

		const level = customObjectValuesFn(
			type === "VOLATILITY" ? invertedLevelMap : type === "RISK" ? levelRiskMap : levelsMap,
		).find((x) => value >= x.ratio.min && value <= x.ratio.max);

		return [
			{
				label: `${level?.label} ${capitalizeString(type.toLowerCase())}`,
				sentiment: level!.trend!,
				indicator: level!.indicator!,
			},
		];
	}

	const { isFetching } = useQueryNoRefetch(["timeSeries", triggerTimeSeriesModalCount], {
		enabled: triggerTimeSeriesModalCount === 1,
		queryFn: () => {
			const fromDate = new Date();
			fromDate.setFullYear(fromDate.getFullYear() - 1);
			fromDate.setHours(0, 0, 0, 0);

			return axiosExtract(
				marketApi.retrievePositioningContinuousTimeSeries(
					assetClass as any,
					geography as any,
					sector as any,
					fromDate.toISOString().split("T")[0],
				),
			);
		},
		onSuccess: setCacheMarketOutlookSeries,
	});
	const timeSeries = useMemo(
		() =>
			cacheMarketOutlookSeries?.values
				?.filter(([_x, y]) => y ?? false)
				?.map(
					([dateSeconds, y]) =>
						[dateSeconds * 1000, y, cacheMarketOutlookSeries?.inverted ?? false] satisfies [number, number, boolean],
				),
		[cacheMarketOutlookSeries],
	);

	const assetClassLabel = useMemo(
		() => cacheMarketOutlookSeries?.type ?? assetClass ?? "-",
		[assetClass, cacheMarketOutlookSeries?.type],
	);

	return (
		<Card title="Current positioning">
			<div className="mb-2 flex items-center">
				{summary.exposureLabel?.[0]?.value && (
					<PositioningBadge
						positioning={
							positioningValueTypeMap[summary.exposureLabel[0].value as keyof typeof positioningValueTypeMap]
						}
						classList="flex-1 mr-4"
					/>
				)}

				<TimeSeriesModal
					assetClass={assetClassLabel}
					currentRegime={
						summary.exposureLabel?.[0]?.value
							? {
									label:
										positioningPresets[
											positioningValueTypeMap[summary.exposureLabel[0].value as keyof typeof positioningValueTypeMap]
										].label,
									value: summary.exposureLabel[0].value,
							  }
							: {
									label: "-",
							  }
					}
					previousRegime={
						summary.prevValue?.value
							? {
									label:
										positioningPresets[
											positioningValueTypeMap[summary.prevValue.value as keyof typeof positioningValueTypeMap]
										].label,
									value: summary.prevValue.value,
							  }
							: {
									label: "-",
							  }
					}
					isLoading={isFetching}
					series={
						timeSeries
							? {
									[assetClassLabel]: timeSeries,
							  }
							: undefined
					}
					onClick={() => setTriggerTimeSeriesModalCount((count) => count + 1)}
				/>
			</div>

			<div className="grid grid-cols-2 gap-4">
				<div>
					<Text as="div" type="Body/L/Bold" classList="mb-2">
						Drivers
					</Text>
					<div className="flex flex-col items-start gap-2">
						<ForEach collection={summary.driverLabel?.flatMap(feelsConverter) ?? []}>
							{({ item }) => (
								<SentimentBadge sentiment={item.sentiment} indicator={item.indicator}>
									{item.label}
								</SentimentBadge>
							)}
						</ForEach>
					</div>
				</div>
				<div>
					<Text as="div" type="Body/L/Bold" classList="mb-2">
						Regime
					</Text>
					<ForEach
						collection={
							summary.actualRegime?.flatMap((x) => feelsConverter({ value: x?.value ?? 1, type: "RISK" })) ?? []
						}
					>
						{({ item }) => (
							<SentimentBadge sentiment={item.sentiment} indicator={item.indicator}>
								{item.label}
							</SentimentBadge>
						)}
					</ForEach>
				</div>
			</div>
		</Card>
	);
}
