import { ForEach } from "$root/utils/react-extra";
import type { StylableProps } from "@mdotm/mdotui/components";
import { CircularProgressBar, LocalOverlay, Text } from "@mdotm/mdotui/components";
import type { UserCirclePalette, UserCircleSize } from "./UserCircle";
import { UserCircle, UserCircleWithTooltip } from "./UserCircle";
import { toClassListRecord, toClassName } from "@mdotm/mdotui/react-extensions";
import { useUserValue } from "../user";
import { useMemo } from "react";
import { sortByNumericMapping } from "$root/utils/collections";
import type { Roles } from "./checkers/all";
import { useAsync, type MaybePromise } from "@mdotm/mdotui/headless";

export type UserListEntryProps = {
	role: "OWNER" | "EDITOR" | "VIEWER";
	userId: string;
	firstName: string;
	lastName: string;
};

export type UsersListProps = {
	users: Array<UserListEntryProps>;
	/** excluding the owner */
	visibleSlots: number;
	size?: UserCircleSize;
	onAdd?(): MaybePromise<void>;
} & StylableProps;

const roleToSortingNumber = {
	OWNER: 0,
	EDITOR: 1,
	VIEWER: 2,
};
export const userCirclePaletteByRole: Record<
	"OWNER" | "EDITOR" | "VIEWER",
	(currentUserId: string, userId: string) => UserCirclePalette
> = {
	OWNER: (currentUserId, userId) => (currentUserId === userId ? "majestyLight" : "majestyDark"),
	EDITOR: (currentUserId, userId) => (currentUserId === userId ? "light" : "dark"),
	VIEWER: (currentUserId, userId) => (currentUserId === userId ? "light" : "dark"),
};
export function UsersList({
	users,
	visibleSlots,
	classList,
	style,
	size = "medium",
	onAdd,
}: UsersListProps): JSX.Element {
	const usersToShow = users.length <= visibleSlots ? Math.min(users.length, visibleSlots) : visibleSlots - 1;
	const extraUsers = users.length - visibleSlots;

	const sortedUsers = useMemo(
		() => users.slice().sort(sortByNumericMapping((user) => roleToSortingNumber[user.role])),
		[users],
	);

	console.assert(
		(sortedUsers.at(0)?.role ?? "OWNER") === "OWNER",
		"the first item of the sorted array of users must be the owner as every entity must have one",
	);

	const currentUserId = useUserValue().id;

	const { loading, run } = useAsync({
		asyncFn: async () => {
			await onAdd?.();
		},
	});

	const onAddClick = () => {
		run().catch(console.error);
	};

	// if visibleSlots is 0 or we have no user other than the owner
	if (usersToShow === 0 || sortedUsers.length === 1) {
		return (
			<div
				style={style}
				className={toClassName({
					"flex items-center gap-2": true,
					...toClassListRecord(classList),
				})}
			>
				{onAdd ? (
					<button type="button" onClick={onAddClick} data-qualifier="SharePermission/AddUser">
						<UserCircle palette="dashed" variant="add" size={size} />
					</button>
				) : null}
			</div>
		);
	}

	const owner = sortedUsers[0];
	return (
		<div
			style={style}
			className={toClassName({
				"flex items-center gap-2": true,
				...toClassListRecord(classList),
			})}
		>
			<button className="relative z-0" type="button" onClick={onAddClick} disabled={!onAdd || loading}>
				<LocalOverlay style={{ backgroundColor: "transparent" }} show={loading}>
					<CircularProgressBar
						value="indeterminate"
						innerDiameter={size === "medium" ? 20 : 12}
						outerDiameter={size === "medium" ? 26 : 18}
					/>
				</LocalOverlay>
				<UserCircleWithTooltip
					palette={userCirclePaletteByRole[owner.role](currentUserId, owner.userId)}
					variant="name"
					firstName={owner.firstName}
					lastName={owner.lastName}
					position="top"
					size={size}
				>
					{`${owner.firstName ?? ""} ${owner.lastName ?? ""}`}
					{owner.role && ` - ${humanReadableRole[owner.role]}`}
				</UserCircleWithTooltip>
			</button>
			<div
				className="w-0 border-r border-r-gray-300"
				style={{
					height: size === "small" ? 18 : 32,
				}}
			/>
			<ForEach collection={sortedUsers.slice(1, usersToShow + 1)}>
				{({ item }) => (
					<button type="button" onClick={onAddClick} disabled={!onAdd || loading}>
						<UserCircleWithTooltip
							palette={userCirclePaletteByRole[item.role](currentUserId, item.userId)}
							variant="name"
							firstName={item.firstName}
							lastName={item.lastName}
							position="top"
							size={size}
						>
							{`${item.firstName ?? ""} ${item.lastName ?? ""}`}
							{item.role && ` - ${humanReadableRole[item.role]}`}
						</UserCircleWithTooltip>
					</button>
				)}
			</ForEach>
			{extraUsers > 0 && (
				<button type="button" onClick={onAddClick} disabled={!onAdd || loading}>
					<UserCircleWithTooltip palette="dark" variant="more" count={extraUsers} position="top" size={size}>
						<div className="space-y-4">
							<ForEach collection={sortedUsers.slice(usersToShow + 1)}>
								{({ item }) => (
									<div className="flex items-center gap-2">
										<UserCircle
											palette={userCirclePaletteByRole[item.role](currentUserId, item.userId)}
											variant="name"
											firstName={item.firstName}
											lastName={item.lastName}
											size={size}
										/>
										<Text as="div" type="Body/M/Book">
											{`${item.firstName ?? ""} ${item.lastName ?? ""}`}
											{item.role && ` - ${humanReadableRole[item.role]}`}
										</Text>
									</div>
								)}
							</ForEach>
						</div>
					</UserCircleWithTooltip>
				</button>
			)}
		</div>
	);
}

const humanReadableRole: Record<Roles, string> = {
	EDITOR: "Editor",
	OWNER: "Owner",
	VIEWER: "Viewer",
};
