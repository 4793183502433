import type { InstrumentErrorMessages, InstrumentErrorMessagesMessageEnum, ReviewTicker } from "$root/api/api-gen";
import { IntegrationsControllerApiFactory, TemplateImportType } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { DropzoneArea } from "$root/components/DropzoneArea";
import UploadButton from "$root/components/UploadButton";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { builtInCaseInsensitiveSortFor } from "$root/utils/collections";
import { downloadContentDisposition } from "$root/utils/files";
import {
	ActionText,
	Banner,
	Button,
	Dialog,
	DialogFooter,
	Icon,
	LoadingButton,
	SubmitButton,
	TableV2,
} from "@mdotm/mdotui/components";
import { useAsync } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, unpromisify } from "@mdotm/mdotui/utils";
import type { ReactNode } from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import XLSX from "xlsx";
import EmptyImportBox from "../upload/box/EmptyImportBox";
import ErrorImportBox from "../upload/box/ErrorImportBox";
import SuccessImportBox from "../upload/box/SuccessImportBox";

const ACCEPTED_FILE = "*";

type ErrorData = {
	rowNumber: number;
	message: string;
};

const BoxMap = {
	empty: <EmptyImportBox />,
	error: <ErrorImportBox />,
	success: <SuccessImportBox />,
} as const;

const errorMap: Record<InstrumentErrorMessagesMessageEnum, string> = {
	NULL_EMPTY_ISIN: "Missing ISIN",
	INVALID_ISIN: "Invalid ISIN",
	// NULL_EMPTY_INSTRUMENT_NAME: "Missing instrument name",
	// NULL_EMPTY_INSTRUMENT_DESCR: "Missing instrument description",
};

export function UploadInstrumentDetailsButton(props: { children?: ReactNode; onUpload(): void }): JSX.Element {
	const [showUpdateDetailsModal, setShowUpdateDetailsModal] = useState(false);
	const { t } = useTranslation();

	const integrationsV2Api = useApiGen(IntegrationsControllerApiFactory);
	const [state, setState] = useState<
		| {
				file: null;
				errors?: undefined;
				formatError?: undefined;
				instruments?: undefined;
				reviewTickers?: undefined;
		  }
		| {
				file: File;
				errors: InstrumentErrorMessages[];
				formatError?: undefined;
				instruments?: undefined;
		  }
		| {
				file: File;
				errors?: undefined;
				formatError: boolean;
				instruments?: undefined;
		  }
		| {
				file: File;
				errors?: InstrumentErrorMessages[];
				formatError?: undefined;
				instruments: ReviewTicker[];
		  }
	>({ file: null });

	const handleFileChangeAsync = useAsync<void, File | null>({
		asyncFn: async ({ param: file }) => {
			if (!file) {
				setState({ file: null });
				return;
			}

			try {
				// TODO: api
				const { warningMessages, reviewTickers } = await axiosExtract(integrationsV2Api.importInstruments(file));
				// const errorMessages: InstrumentErrorMessages[] = [];
				// if (errorMessages && errorMessages.length > 0) {
				// 	setState({
				// 		file,
				// 		errors: errorMessages,
				// 	});
				// } else {
				setState({
					file,
					errors: warningMessages,
					instruments: reviewTickers!,
				});
				// }
			} catch (error) {
				setState({
					file,
					formatError: true,
				});
				reportPlatformError(error, "ERROR", "universe", "upload instrument details");
				throw error;
			}
		},
	});

	const handleDownloadTemplate = useCallback(
		() =>
			integrationsV2Api
				.getUploadTemplate(TemplateImportType.CustomInstrumentDescription, { responseType: "blob" })
				.then(downloadContentDisposition)
				.catch((e) => {
					reportPlatformError(e, "ERROR", "portfolio", "unable to download template");
					throw e;
				}),
		[integrationsV2Api],
	);

	const handleDownloadErrorReport = useCallback((report: Array<InstrumentErrorMessages>) => {
		const reportWS = XLSX.utils.json_to_sheet(
			report.map((x) => ({ ...x, message: x.message ? errorMap[x.message] : "" })),
		);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, reportWS, "Report Errors");
		XLSX.writeFile(wb, "Errors file.xlsx");
	}, []);

	const errorColumns = useMemo<TableV2.TableColumn<InstrumentErrorMessages>[]>(
		() => [
			{
				name: "rowNumber",
				header: "Row number",
				content: (row) => row.rowNumber,
				sortFn: builtInSortFnFor("rowNumber"),
				width: 128,
				align: "end",
			},
			{
				name: "message",
				header: "Error",
				content: (row) => row.message,
				sortFn: builtInCaseInsensitiveSortFor("message"),
				minWidth: 200,
			},
		],
		[],
	);

	const instrumentWithEmptyIsin = useMemo(
		() => (state.errors ?? []).filter((x) => x.message === "INVALID_ISIN" || x.message === "NULL_EMPTY_ISIN"),
		[state.errors],
	);

	return (
		<>
			<Dialog
				size="large"
				onAnimationStateChange={(s) => s === "hidden" && setState({ file: null })}
				show={showUpdateDetailsModal}
				onClose={handleFileChangeAsync.loading ? undefined : () => setShowUpdateDetailsModal(false)}
				onSubmitAsync={async () => {
					if (!state.instruments) {
						return;
					}
					try {
						// TODO:
						// await persistInfoSomewhere();
						await integrationsV2Api.updateInstrumentsByInputList(state.instruments);
						props.onUpload();
						setShowUpdateDetailsModal(false);
					} catch (err) {
						console.error(err);
						throw err;
					}
				}}
				footer={
					<DialogFooter
						neutralAction={
							<Button onClick={() => setShowUpdateDetailsModal(false)} palette="tertiary">
								Close
							</Button>
						}
						primaryAction={
							<SubmitButton disabled={!state.instruments || state.instruments.length === 0}>Generate</SubmitButton>
						}
					/>
				}
				header="Upload instruments details"
			>
				Follow these steps to update multiple instruments at once using an spreadsheet file:
				<ul className="list-disc pl-4 mb-4">
					<li>
						Download the <ActionText onClickAsync={handleDownloadTemplate}>template</ActionText>
					</li>
					<li>Add the description and ISIN</li>
					<li>Upload the edited template. Only files formatted like the template will be accepted.</li>
				</ul>
				<Banner severity="info" title="Overwrite operation" classList="mb-4">
					Please note that uploading instruments data will overwrite any existing information.
				</Banner>
				<DropzoneArea
					disabled={handleFileChangeAsync.loading}
					onChange={unpromisify(async (file: File | null) => {
						await handleFileChangeAsync.run(file);
					})}
					accept={ACCEPTED_FILE}
					childrenWrapperAppearance={{
						classList: {
							[`relative rounded flex flex-1 gap-4 p-4 justify-between items-center border-2 `]: true,
							[`bg-[color:${themeCSSVars.palette_N50}] border-dashed border-[color:${themeCSSVars.palette_N500}]`]:
								!state.file,
							[`bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_P600}]`]: Boolean(
								state.file && instrumentWithEmptyIsin.length === 0,
							),
							[`bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_D500}]`]: Boolean(
								instrumentWithEmptyIsin.length > 0,
							),
						},
					}}
				>
					<div className="flex gap-2 items-center flex-1">
						{state.file && instrumentWithEmptyIsin.length === 0 && !state.formatError ? (
							<>
								{BoxMap.success}
								<p className="font-semibold">{state.file.name}</p>
							</>
						) : instrumentWithEmptyIsin.length > 0 ? (
							<>
								{BoxMap.error}
								<p className="font-semibold">{state.file?.name}</p>
							</>
						) : state.formatError ? (
							<>
								{BoxMap.error}
								<p className="font-semibold">{state.file.name}</p>
							</>
						) : (
							<>
								{BoxMap.empty}
								<p className="font-semibold">Select a file or drag and drop here</p>
							</>
						)}
					</div>

					{state.file ? (
						<LoadingButton
							loading={handleFileChangeAsync.loading}
							palette="tertiary"
							size="small"
							onClick={() => setState({ file: null })}
						>
							<Icon icon="Delete" size={20} color={themeCSSVars.palette_N500} />
						</LoadingButton>
					) : (
						<UploadButton
							loading={handleFileChangeAsync.loading}
							size="small"
							label={t("BUTTON.SELECT")}
							onChange={unpromisify(async (file: File | null) => {
								await handleFileChangeAsync.run(file);
							})}
							accept={ACCEPTED_FILE}
						/>
					)}
				</DropzoneArea>
				{instrumentWithEmptyIsin.length > 0 && (
					<Banner severity="error" classList="mt-4" title="Please check the errors below and correct the file">
						<button
							type="button"
							className="underline cursor-pointer font-bold"
							onClick={() => handleDownloadErrorReport(instrumentWithEmptyIsin)}
						>
							{t("EXPORT_ERROR")}
						</button>
					</Banner>
				)}
				{state.formatError && (
					<Banner severity="error" classList="mt-4">
						The uploaded file is not compatible with the selected format. Please correct the file and upload it again.
					</Banner>
				)}
				{/* {state.errors && (
					<div className="mt-4">
						<TableV2.AutoSortTable columns={errorColumns} rows={state.errors} style={{ maxHeight: 300 }} />
					</div>
				)} */}
				{state.instruments && state.instruments.length > 0 && (
					<Banner severity="success" title="File uploaded correctly" classList="mt-4">
						{`I can generate ${state.instruments.length - instrumentWithEmptyIsin.length}/${
							state.instruments.length
						} descriptions`}
					</Banner>
				)}
			</Dialog>
			<Button palette="secondary" size="small" onClick={() => setShowUpdateDetailsModal(true)}>
				<Icon size={16} icon="Upload" />
				&nbsp;
				{props.children}
			</Button>
		</>
	);
}
