import { IntegrationsControllerApiFactory, ReferenceUniversesControllerApiFactory } from "$root/api/api-gen";
import { getApiGen } from "$root/api/factory";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { downloadContentDisposition } from "$root/utils/files";

export const exportUniverse = {
	async downloadComposition(uuid: string): Promise<void> {
		const response = await getApiGen(ReferenceUniversesControllerApiFactory).exportUniverseComposition(uuid, {
			responseType: "blob",
		});

		trackMixPanelEvent("Universe", {
			Type: "Download Composition",
			ID: uuid,
		});

		downloadContentDisposition(response);
	},

	async downloadUniverse(uuid: string): Promise<void> {
		const response = await getApiGen(IntegrationsControllerApiFactory).exportUniverse(uuid, {
			responseType: "blob",
		});

		trackMixPanelEvent("Universe", {
			Type: "Download Composition",
			ID: uuid,
		});

		downloadContentDisposition(response);
	},
};
