import type { CommentaryTemplateModel } from "$root/api/api-gen";
import { CommentaryTemplateControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import { spawnCreateDialog } from "$root/components/spawnable/entity-management/create-dialog";
import StoryfolioInner from "$root/functional-areas/storyfolio";
import { stableEmptyCommentaryTemplate } from "$root/functional-areas/storyfolio/common";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import type { Abortable } from "$root/utils/promise";
import { noop } from "@mdotm/mdotui/utils";

function StoryfolioCreation(): JSX.Element {
	const { push, replace } = useTypedNavigation();
	const commentaryTemplateApi = useApiGen(CommentaryTemplateControllerApiFactory);

	async function createStoryfolioTemplate(name: string, template: CommentaryTemplateModel) {
		try {
			console.log("submit")
			await commentaryTemplateApi.createTemplate({ ...template, name });

			platformToast({
				children: "Commentary template created successfully",
				icon: "Ask-ai",
				severity: "success",
			});
			push("Storyfolio/Studio", {});
		} catch (error) {
			platformToast({
				children: "Something went wrong please, try later",
				icon: "Ask-ai",
				severity: "error",
			});
			throw error;
		}
	}

	function checkIfNameIsAvailable(name: string, opts?: Abortable) {
		return axiosExtract(commentaryTemplateApi.isInvestmentNameAvailable1(name, opts));
	}
	return (
		<ReactQueryWrapper
			queryKey={["loadStoryfolioData"]}
			queryFn={async () => {
				const list = await axiosExtract(commentaryTemplateApi.getTemplateList());
				return { template: stableEmptyCommentaryTemplate, list };
			}}
		>
			{({ list, template }) => (
				<StoryfolioInner
					template={template}
					list={list}
					onSubmit={async (editedTemplate) => {
						await spawnCreateDialog({
							entityType: "template",
							onSubmitAsync: ({ param: name }) => createStoryfolioTemplate(name, editedTemplate),
							checkIfNameIsAvailable,
							placeholder: editedTemplate.name ?? "Untitled template",
							header: "Rename Template",
						}).catch(noop);
					}}
					onChangeTemplate={(uuid) => replace("Storyfolio/Details", { uuid: uuid! })}
				/>
			)}
		</ReactQueryWrapper>
	);
}

export default StoryfolioCreation;
