import { MarketControllerV2ApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import DataSeriesModal from "$root/components/DataSeriesModal";
import InfiniteLoader from "$root/components/InfiniteLoader";
import useOutlookComposition from "$root/hooks/useOutlookComposition";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { UniverseFiltersContext } from "$root/widgets-architecture/contexts/filters";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ERAHistoricalValuePercentile from "./ERAHistoricalValuePercentile";
import { useEventBus } from "$root/event-bus";
import { noop } from "@mdotm/mdotui/utils";
import useWidgetsData from "$root/hooks/useWidgetsData";
import ChartStepperLined from "$root/ui-lib/charts/ChartStepperLined";
import { Text } from "@mdotm/mdotui/components";

const ERAOutlookExpected = (props: ContextContent<typeof UniverseFiltersContext>) => {
	const { firstFilter, secondFilter, thirdFilter } = props;
	const { assetClass, geography, sector } = useOutlookComposition(firstFilter, secondFilter, thirdFilter);
	const { t } = useTranslation();
	const marketApi = useApiGen(MarketControllerV2ApiFactory);
	const { currentWidgetsData } = useWidgetsData();

	// Default widget configuration
	const defaultConfiguration = useMemo(
		() => ({
			outlook_type: "EXPECTED_RETURNS_ANALYSYS",
			header: {
				title: t("EXPECTED_RETURNS_ANALYSYS.TITLE"),
				subTitle: t("EXPECTED_RETURNS_ANALYSYS.DESCRIPTION"),
				actions: {
					tooltip: {
						toShow: true,
						title: t("EXPECTED_RETURNS_ANALYSYS.TOOLTIP"),
					},
					isDraggable: true,
				},
			},
		}),
		[t],
	);

	//Default Stepper Lined Graph conf.
	const DATA_OBJ = [
		{
			label: t("EXPECTED_RETURNS_ANALYSYS.EXPECTED_CONTRIBUTION.AXIS.LOW"),
			isPrev: false,
			isActual: false,
		},
		{
			label: t("EXPECTED_RETURNS_ANALYSYS.EXPECTED_CONTRIBUTION.AXIS.MID_LOW"),
			isPrev: false,
			isActual: false,
		},
		{
			label: t("EXPECTED_RETURNS_ANALYSYS.EXPECTED_CONTRIBUTION.AXIS.NEUTRAL"),
			isPrev: false,
			isActual: false,
		},
		{
			label: t("EXPECTED_RETURNS_ANALYSYS.EXPECTED_CONTRIBUTION.AXIS.MID_HIGH"),
			isPrev: false,
			isActual: false,
		},
		{
			label: t("EXPECTED_RETURNS_ANALYSYS.EXPECTED_CONTRIBUTION.AXIS.HIGH"),
			isPrev: false,
			isActual: false,
		},
	];

	const { data, isLoading, refetch } = useQueryNoRefetch(["expectedReturnData", assetClass, geography, sector], {
		queryFn: async () => {
			const { data: outlookByAssetClass } = await marketApi.retrieveOutlookFocusByAssetClass(
				assetClass,
				geography,
				sector,
				"RETURN",
			);
			const { currentValue, prevValue, elements } = outlookByAssetClass;

			return {
				chartStepperLinedData: {
					value: currentValue,
					prev_value: prevValue,
				},
				rows: elements,
			};
		},
		onError: (e) => console.warn(e),
	});

	const { chartStepperLinedData, rows } = data ?? {};
	const { prev_value, value } = chartStepperLinedData ?? {};

	useEventBus("market-update", () => {
		refetch().catch(noop);
	});

	useWidgetOptions(
		() => ({
			title: defaultConfiguration.header.title,
			isDraggable: defaultConfiguration.header.actions.isDraggable,
			subTitle: defaultConfiguration.header.subTitle ? defaultConfiguration.header.subTitle : false,
			actionHeader: defaultConfiguration.header.actions.tooltip.toShow ? (
				<InfoTooltip>{defaultConfiguration.header.actions.tooltip.title}</InfoTooltip>
			) : (
				<></>
			),
		}),
		[
			defaultConfiguration.header.title,
			defaultConfiguration.header.actions.isDraggable,
			defaultConfiguration.header.actions.tooltip.toShow,
			defaultConfiguration.header.actions.tooltip.title,
			defaultConfiguration.header.subTitle,
		],
	);

	return (
		<>
			{isLoading ? (
				<InfiniteLoader />
			) : (
				<>
					<div style={{ marginBottom: 12, marginTop: 20 }}>
						<div style={{ display: "flex" }}>
							<Text type="Body/L/Bold" style={{ paddingRight: 5 }}>
								{t("EXPECTED_RETURNS_ANALYSYS.EXPECTED_CONTRIBUTION.TITLE")}
							</Text>
							<InfoTooltip>{t("EXPECTED_RETURNS_ANALYSYS.EXPECTED_CONTRIBUTION.TOOLTIP")}</InfoTooltip>
							<DataSeriesModal
								firstFilter={firstFilter}
								secondFilter={secondFilter}
								thirdFilter={thirdFilter}
								indicator="RETURN"
								typeMode="OUTLOOK_FOCUS"
							/>
						</div>
						<div className="mt-1 mx-2.5">
							<ChartStepperLined
								key="ERAChartGraph"
								columnNumber={5}
								current={{
									value: value?.value ? value?.value - 1 : 0,
									tooltip: [
										{
											data: chartStepperLinedData?.value?.data,
											value: DATA_OBJ[value?.value ? Number(value?.value - 1) : 0].label,
											label: t("CURRENT"),
										},
									],
								}}
								previous={{
									value: prev_value?.value ? prev_value?.value - 1 : 0,
									tooltip: [
										{
											data: prev_value?.data,
											value: DATA_OBJ[prev_value?.value ? Number(prev_value?.value - 1) : 0].label,
											label: t("PREVIOUS"),
										},
									],
								}}
								labels={DATA_OBJ.map((d) => d.label)}
								title={t("EXPECTED_RETURNS_ANALYSYS.EXPECTED_CONTRIBUTION.TITLE")}
								showRect
								lastDashboardUpdate={currentWidgetsData.creation_time}
							/>
						</div>
					</div>
					<>
						<ERAHistoricalValuePercentile data={rows ?? []} />
					</>
				</>
			)}
		</>
	);
};

export default withContext(UniverseFiltersContext)(ERAOutlookExpected);
