import type { PortfolioMetric } from "$root/api/api-gen";
import { useLocaleFormatters } from "$root/localization/hooks";
import { TableV2 } from "@mdotm/mdotui/components";
import { useTranslation } from "react-i18next";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import { TinyTableDataCell, TinyTableHeadCell } from "$root/components/EvolvedPrint/components/table/tiny-table";

export function PortfolioMetrics({
	benchmark,
	list,
}: PrintableProps<{ benchmark: boolean }, PortfolioMetric>): JSX.Element {
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();

	const columns = [
		{
			name: "type",
			width: 330,
			header: (props) => <TinyTableHeadCell {...props}>METRICS</TinyTableHeadCell>,
			content: ({ type }, props) => (
				<TinyTableDataCell {...props}>{t(`PERFORMANCE_METRICS.METRICS.FULL.${type!}`) ?? "-"}</TinyTableDataCell>
			),
		},
		{
			name: "current",
			width: 124,
			cellClassList: "tabular-nums",
			header: (props) => <TinyTableHeadCell {...props}>Current</TinyTableHeadCell>,
			align: "end",
			content: ({ value, type }, props) => {
				const label = t(`PERFORMANCE_METRICS.METRICS.FULL.${type!}`) ?? "-";
				return (
					<TinyTableDataCell {...props}>{`${formatNumber(value)}${
						label.toLowerCase().includes("ratio") ? "" : "%"
					}`}</TinyTableDataCell>
				);
			},
		},
		{
			name: "benchmark",
			width: 124,
			cellClassList: "tabular-nums",
			header: (props) => <TinyTableHeadCell {...props}>Benchmark</TinyTableHeadCell>,
			align: "end",
			content: ({ benchmarkValue, type }, props) => {
				const label = t(`PERFORMANCE_METRICS.METRICS.FULL.${type!}`) ?? "-";
				return (
					<TinyTableDataCell {...props}>{`${formatNumber(benchmarkValue)}${
						label.toLowerCase().includes("ratio") ? "" : "%"
					}`}</TinyTableDataCell>
				);
			},
			hidden: !benchmark,
		},
		{
			name: "empty",
			minWidth: 0,
			header: (props) => <TinyTableHeadCell {...props}>{null}</TinyTableHeadCell>,
			content: (_, props) => <TinyTableDataCell {...props}>{null}</TinyTableDataCell>,
		},
	] satisfies TableV2.TableColumn<PortfolioMetric>[];

	return (
		<Card title="Portfolio metrics">
			<TableV2.BaseTable palette="uniform" columns={columns} rows={list} noDataText={t("COMPOSITION.NO_COMPOSITION")} />
		</Card>
	);
}
