import type { Chip } from "$root/api/api-gen";
import { MarketControllerV2ApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import DataSeriesModal from "$root/components/DataSeriesModal";
import InfiniteLoader from "$root/components/InfiniteLoader";
import { useEventBus } from "$root/event-bus";
import { SentimentBadge } from "$root/functional-areas/market-view/analysis/SentimentBadge";
import useOutlookComposition from "$root/hooks/useOutlookComposition";
import { useLocaleFormatters } from "$root/localization/hooks";
import { PaletteColors } from "$root/styles/themePalette";
import { customObjectValuesFn } from "$root/utils/experimental";
import type { ContextContent } from "$root/utils/react-extra";
import { ForEach, withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { capitalizeString } from "$root/utils/strings";
import { UniverseFiltersContext } from "$root/widgets-architecture/contexts/filters";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { Text } from "@mdotm/mdotui/components";
import { noop } from "@mdotm/mdotui/utils";
import { useTranslation } from "react-i18next";
import ACRHistoricalValuePercentile from "./ACRHistoricalValuePercentile";

const ACROutlookExpected = (props: ContextContent<typeof UniverseFiltersContext>) => {
	const { firstFilter, secondFilter, thirdFilter } = props;
	const { assetClass, geography, sector } = useOutlookComposition(firstFilter, secondFilter, thirdFilter);

	const { t } = useTranslation();
	const marketApi = useApiGen(MarketControllerV2ApiFactory);

	const { formatDate } = useLocaleFormatters();

	const pillsLabel = t(`ASSET_CLASS_REGIME.SCENARIO.PILLS`, { returnObjects: true });

	// Default widget configuration
	const defaultConfiguration = {
		outlook_type: "ASSET_CLASS_REGIME",
		header: {
			title: t("ASSET_CLASS_REGIME.TITLE"),
			subTitle: false,
			actions: {
				tooltip: {
					toShow: true,
					title: t("ASSET_CLASS_REGIME.TOOLTIP"),
				},
				isDraggable: true,
			},
		},
	};

	const { data, isLoading, refetch } = useQueryNoRefetch(["assetClassRegimeData", assetClass, geography, sector], {
		queryFn: async () => {
			const { data: marketRegimeWithElements } = await marketApi.retrieveMarketRegimeWithElements(
				assetClass,
				geography,
				sector,
			);
			const { comparables, regimePeriod, type, value } = marketRegimeWithElements;
			const typedRegime = regimePeriod as unknown as {
				from?: string;
				to?: string;
				serialized: number[];
			};
			const formatDateFn = (date?: string) => {
				if (!date) {
					return "";
				}
				const [day, month, year] = date.split("/");
				return `${year ?? ""}-${month ?? ""}-${day ?? ""}`;
			};

			const from = formatDate(new Date(formatDateFn(typedRegime.from)));
			const to = formatDate(new Date(formatDateFn(typedRegime.to)));

			return {
				scenarioDate: `${from} - ${to}`,
				rows: comparables,
				detectedScenario: { type, value },
			};
		},
		onError: (e) => console.warn(e),
	});

	const { detectedScenario, rows, scenarioDate } = data ?? {};

	useEventBus("market-update", () => {
		refetch().catch(noop);
	});

	useWidgetOptions(
		() => ({
			title: defaultConfiguration.header.title,
			isDraggable: defaultConfiguration.header.actions.isDraggable,
			subTitle: defaultConfiguration.header.subTitle ? defaultConfiguration.header.subTitle : false,
			actionHeader: defaultConfiguration.header.actions.tooltip.toShow ? (
				<InfoTooltip>{defaultConfiguration.header.actions.tooltip.title}</InfoTooltip>
			) : (
				<></>
			),
		}),
		[
			defaultConfiguration.header.actions.isDraggable,
			defaultConfiguration.header.actions.tooltip.title,
			defaultConfiguration.header.actions.tooltip.toShow,
			defaultConfiguration.header.subTitle,
			defaultConfiguration.header.title,
		],
	);
	// to make common feels converter
	function feelsConverter({ type, value }: Chip) {
		if (!type || !value) {
			return [];
		}

		const levelRiskMap = {
			high: { ratio: { min: 1, max: 1 }, label: "Low", trend: "positive", indicator: "regime" },
			mid: { ratio: { min: 2, max: 2 }, label: "Mid", trend: "neutral", indicator: "regime" },
			low: { ratio: { min: 3, max: 3 }, label: "High", trend: "negative", indicator: "regime" },
		} as const;

		const invertedLevelMap = {
			high: { ratio: { min: 1, max: 2 }, label: "High", trend: "negative", indicator: "driver" },
			mid: { ratio: { min: 3, max: 3 }, label: "Mid", trend: "neutral", indicator: "driver" },
			low: { ratio: { min: 4, max: 5 }, label: "Low", trend: "positive", indicator: "driver" },
		} as const;

		const levelsMap = {
			high: { ratio: { min: 4, max: 5 }, label: "High", trend: "positive", indicator: "driver" },
			mid: { ratio: { min: 3, max: 3 }, label: "Mid", trend: "neutral", indicator: "driver" },
			low: { ratio: { min: 1, max: 2 }, label: "Low", trend: "negative", indicator: "driver" },
		} as const;

		const level = customObjectValuesFn(
			type === "VOLATILITY" ? invertedLevelMap : type === "RISK" ? levelRiskMap : levelsMap,
		).find((x) => value >= x.ratio.min && value <= x.ratio.max);

		return [
			{
				label: `${level?.label} ${capitalizeString(type.toLowerCase())}`,
				sentiment: level!.trend!,
				indicator: level!.indicator!,
			},
		];
	}

	return (
		<>
			{isLoading ? (
				<InfiniteLoader />
			) : (
				<>
					<div className="mb-6 mt-3 flex flex-col space-y-4 border border-solid border-slate-300 p-2">
						<div className="flex justify-between items-center">
							<Text type="Body/M/Bold">{t("ASSET_CLASS_REGIME.SCENARIO.DETECTED_SCENARIO")}</Text>

							{detectedScenario?.value ? (
								<ForEach
									collection={[
										detectedScenario.value
											? { ...detectedScenario, type: "RISK" }
											: { type: undefined, value: undefined },
									].flatMap(feelsConverter)}
								>
									{({ item }) => (
										<SentimentBadge sentiment={item.sentiment} indicator={item.indicator}>
											{item.label}
										</SentimentBadge>
									)}
								</ForEach>
							) : (
								"Something went wrong"
							)}
						</div>
						<div className="flex justify-between items-center">
							<Text type="Body/M/Bold">{t("ASSET_CLASS_REGIME.SCENARIO.REGIME_PERIOD")}</Text>
							<Text>{scenarioDate}</Text>
						</div>
					</div>
					<div style={{ marginBottom: 12, marginTop: 10 }}>
						<div className="flex">
							<Text type="Body/XL/Bold" style={{ marginTop: 5 }}>
								{t("ASSET_CLASS_REGIME.SCENARIO.SCENARIO_DESCRIPTION")}
							</Text>
							<DataSeriesModal
								firstFilter={firstFilter}
								secondFilter={secondFilter}
								thirdFilter={thirdFilter}
								indicator="REGIME"
								typeMode="OUTLOOK_FOCUS"
							/>
						</div>
						<Text type="Body/L/Book" style={{ marginTop: 5 }}>
							{/* @ts-ignore */}
							{t(`ASSET_CLASS_REGIME.SCENARIO.DESCRIPTION.${detectedScenario?.value ?? 0}`)}
						</Text>
					</div>
					<>
						<ACRHistoricalValuePercentile data={rows ?? []} />
					</>
				</>
			)}
		</>
	);
};

export default withContext(UniverseFiltersContext)(ACROutlookExpected);
