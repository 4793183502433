import type { ReviewTicker, RichTicker } from "$root/api/api-gen";
import { DefaultTagLabels, ReferenceUniversesControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { CustomLabels } from "$root/components/CustomLabels";
import { IconWalls, WallOverlay } from "$root/components/IconWall";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { TagBadge } from "$root/components/tags/TagBadge";
import { useEventBus } from "$root/event-bus";
import {
	instrumentColumnsMetadata,
	useInstrumentColumnsTableV2,
	useSearchableInstrumentTable,
} from "$root/functional-areas/instruments/hooks";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import { exportUniverse } from "$root/functional-areas/universe/export";
import { useUserValue } from "$root/functional-areas/user";
import { formatNumber } from "$root/localization/formatters";
import { axiosExtract } from "$root/third-party-integrations/axios";
import colorGenerator from "$root/utils/chart/colorGenerator";
import { objMatchFn } from "$root/utils/objects";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { noop } from "$root/utils/runtime";
import { UniverseContext } from "$root/widgets-architecture/contexts/universe";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { OrderBy } from "@mdotm/mdotui/components";
import { ActionText, AutoTooltip, DropdownMenu, Icon, TableV2, TextInput } from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

function UniverseComposition({ universe: externalUniverse }: ContextContent<typeof UniverseContext>) {
	const universeUuid = externalUniverse?.uuid;
	const [setOrderBy] = useState<OrderBy[]>([]);

	const { t } = useTranslation();
	const referenceUniverseV4Api = useApiGen(ReferenceUniversesControllerApiFactory);
	const user = useUserValue();

	const universeQuery = useQueryNoRefetch(["universeCompositionWidget", externalUniverse], {
		enabled: Boolean(universeUuid),
		queryFn: async () => {
			if (!universeUuid) {
				throw new Error("unable to load a universe of undefined");
			}

			const { composition } = await axiosExtract(referenceUniverseV4Api.getUniverseComposition(universeUuid));
			return composition as ReviewTicker[];
		},
		keepPreviousData: true,
	});

	const { data: instrumentRows, isError, isLoading, refetch } = universeQuery;

	const { query, filtered, setQuery } = useSearchableInstrumentTable(instrumentRows ?? []);

	useEventBus("investment-update", {
		filter: objMatchFn({ uuid: universeUuid }),
		listener: () => {
			refetch().catch(noop);
		},
	});

	useWidgetOptions(
		() => ({
			isDraggable: false,
			title: t("UNIVERSE.READ_ONLY.NAME"),
			actionHeader: (
				<div className="flex space-x-2">
					<DropdownMenu
						position="bottom"
						align="startToEnd"
						trigger={({ innerRef, open, ...forward }) => (
							<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
								<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
							</button>
						)}
						actions={[
							{
								icon: "xls",
								onClickAsync: async () => {
									if (!universeUuid) {
										throw new Error("unable to load a universe of undefined");
									}
									await exportUniverse.downloadComposition(universeUuid);
								},
								label: "Instrument list",
							},
						]}
					/>
					<InfoTooltip>{t("UNIVERSE.READ_ONLY.DESCRIPTION")}</InfoTooltip>
				</div>
			),
		}),
		[t, universeUuid],
	);

	const mappedTags = useMemo(() => {
		const defaultLabels = Object.values(DefaultTagLabels) as string[];

		const tagsSet = new Set(
			defaultLabels.concat(
				instrumentRows?.filter(({ tagLabel }) => tagLabel).map((instument) => instument.tagLabel!) ?? [],
			),
		);

		return Array.from(tagsSet).reduce<{ [key: string]: string }>((acc, el, i) => {
			return {
				...acc,
				[el]: colorGenerator(i),
			};
		}, {});
	}, [instrumentRows]);

	const numberOfInstrumentTagged = useMemo(
		() => instrumentRows?.filter(({ tagLabel }) => tagLabel).length,
		[instrumentRows],
	);

	const commonCols = useInstrumentColumnsTableV2("UniverseComposition");
	const columns = useMemo<TableV2.TableColumn<RichTicker>[]>(
		() => [
			commonCols.instrument(),
			commonCols.identifier,
			commonCols.assetClass,
			commonCols.microAssetClass,
			{
				header: t("PORTFOLIOS.CONSTRAINTS_TARGETS.TABLE.TAGS"),
				content: ({ tagLabel }, props) => (
					<div
						style={props.style}
						className={overrideClassName("flex items-center", props.classList)}
						onClick={props.onClick}
					>
						{!tagLabel || mappedTags[tagLabel] === undefined ? (
							""
						) : (
							<TagBadge color={mappedTags[tagLabel]}>{tagLabel}</TagBadge>
						)}
					</div>
				),
				minWidth: 150,
				sortFn: builtInSortFnFor("tagLabel"),
				name: "tagLabel",
				footer: numberOfInstrumentTagged
					? `Total instruments tagged ${numberOfInstrumentTagged}`
					: "No instruments tagged",
			},
			{
				header: (props) => (
					<TableV2.TableHeadCell {...props}>
						{universeUuid ? <CustomLabels labelKey={`${universeUuid}_score1`} fallback={t("SCORE")} /> : t("SCORE")}
					</TableV2.TableHeadCell>
				),
				content: ({ score }) => (score ? formatNumber(score) : ""),
				sortFn: builtInSortFnFor("score"),
				name: "score",
				align: "end",
				minWidth: 150,
				cellClassList: "tabular-nums",
				hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
			},
			commonCols.commentary(),
		],
		[commonCols, t, numberOfInstrumentTagged, user, mappedTags, universeUuid],
	);

	return (
		<WallOverlay
			showOverlay={isLoading || isError}
			overlay={isError ? <IconWalls.ErrorData /> : <IconWalls.InfiniteProgress />}
		>
			<div className="flex justify-between mb-4">
				<div className="max-w-[500px] flex-1">
					<TextInput
						leftContent={<Icon icon="Search" />}
						value={query}
						onChangeText={setQuery}
						placeholder="Filter by instrument name, ISIN, asset class or micro asset class"
					/>
				</div>
				<div className="flex space-x-1 items-end">
					<ActionText
						color={themeCSSVars.palette_N400}
						href={typedUrlForRoute("PortfolioStudioSettings", {
							tab: PortfolioStudioSettingTabEnum.InstrumentsCustomisation,
							uuid: universeUuid,
						})}
					>
						Instruments customisation
					</ActionText>

					<AutoTooltip
						position="top"
						align="endToStart"
						mode="hover"
						overrideColor={themeCSSVars.palette_N400}
						trigger={({ innerRef }) => (
							<div ref={innerRef}>
								<Icon icon="info" color={themeCSSVars.palette_N400} size={18} />
							</div>
						)}
					>
						Explore all instruments used within your account, generate descriptions, and customize instrument details.
					</AutoTooltip>
				</div>
			</div>

			<TableV2.AutoSortTable
				style={{ maxHeight: "calc(100dvh - 180px)" }}
				rows={filtered}
				columns={columns}
				noDataText="No instrument find in your universe"
			/>
		</WallOverlay>
	);
}

export const ReadonlyUniverseComposition = withContext(UniverseContext)(UniverseComposition);
