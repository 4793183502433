import { AsyncButton, Banner, Button, Dialog } from "@mdotm/mdotui/components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import type { IndexTicker } from "$root/api/api-gen";
import { ProxyPreferencesControllerV4ApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { EditProxiedInstrumentSection } from "$root/functional-areas/proxies/EditProxiedInstrumentSection";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { Card } from "$root/widgets-architecture/layout/Card";
import { PageCtxConsumer } from "$root/components/PageCtx";
import { createPortal } from "react-dom";

const WorkspaceSettings = (): JSX.Element => {
	const { t } = useTranslation();
	const proxyLiveReferenceApi = useApiGen(ProxyPreferencesControllerV4ApiFactory);
	const { push } = useTypedNavigation();

	const proxyPreferencesQuery = useQueryNoRefetch(["proxiedInstruments"], {
		queryFn: () => axiosExtract(proxyLiveReferenceApi.getProxyPreferences()),
		onError: (err) => {
			console.error(err);
		},
	});

	const onSubmitEditedProxy = useCallback(
		async (proxyPreferences: IndexTicker[]) => {
			try {
				const userConfirm = spawn<boolean>(
					adaptAnimatedNodeProvider(({ onHidden, resolve, show }) => (
						<Dialog
							size="small"
							show={show}
							onAnimationStateChange={(state) => state === "hidden" && onHidden()}
							onClose={() => resolve(false)}
							header={t("WORKSPACE_SETTING.PROXY.MODAL.SAVE.TITLE")}
							footer={
								<div className="flex justify-between">
									<Button palette="tertiary" size="small" type="button" onClick={() => resolve(false)}>
										{t("CANCEL")}
									</Button>
									<Button
										palette="primary"
										size="small"
										onClick={() => {
											resolve(true);
											push("PortfoliosStudio", {});
										}}
									>
										{t("WORKSPACE_SETTING.PROXY.BUTTONS.APPLY")}
									</Button>
								</div>
							}
						>
							<p>{t("WORKSPACE_SETTING.PROXY.MODAL.SAVE.DESCRIPTION")}</p>
						</Dialog>
					)),
				);
				if (userConfirm) {
					await proxyLiveReferenceApi.editProxyPreferences({ proxyPreferences });
					await proxyPreferencesQuery.refetch();
				}
			} catch (error) {
				reportPlatformError(error, "ERROR", "proxy", "edit proxy live preferences");
			}
		},
		[proxyLiveReferenceApi, proxyPreferencesQuery, push, t],
	);

	return (
		<>
			<PageHeader
				title="Edit proxied instruments" // TODO: translate
				crumbs={[
					{
						children: "Portfolio studio", // TODO: translate
						href: typedUrlForRoute("PortfoliosStudio", {}),
					},
					{
						children: "Edit proxied instruments",
					},
				]}
			/>
			<div className="rounded bg-white px-4 py-6">
				<div className="grid gap-6 mb-2">
					<p>
						In this section, you can edit the instruments that have been proxied during an upload as long as Sphere is
						not actively calculating any portfolio.
						<br /> Please, be aware that the modification will impact all portfolios in which these instruments are
						allocated or present. Once you have completed the editing process, Sphere will update all portfolios,
						references and universes.
					</p>
				</div>
				<ReactQueryWrapperBase query={proxyPreferencesQuery}>
					{({ editable, proxyPreferences }) => (
						<Card
							classList="!px-0"
							actionHeader={
								<>
									{!editable && (
										<Banner
											severity="warning"
											variant="box"
											title={t("WORKSPACE_SETTING.PROXY.ALERT.EDIT_DISABLED.TITLE")}
										/>
									)}
								</>
							}
						>
							<EditProxiedInstrumentSection
								mode="edit-proxy"
								disabled={!editable}
								composition={proxyPreferences ?? []}
								onSaveAsync={onSubmitEditedProxy}
								onCancel={() => push("PortfoliosStudio", {})}
								actions={["edit"]}
								customActions={({ isSaveDisabled, onSaveAsync, onCancel }) => (
									<PageCtxConsumer>
										{(ctx) =>
											ctx.titleActionPortalTarget &&
											createPortal(
												<div className="flex items-center gap-2">
													<Button onClick={onCancel} size="small" palette="tertiary">
														Cancel
													</Button>
													<AsyncButton
														onClickAsync={onSaveAsync}
														disabled={isSaveDisabled}
														size="small"
														palette="primary"
													>
														Save
													</AsyncButton>
												</div>,
												ctx.titleActionPortalTarget,
											)
										}
									</PageCtxConsumer>
								)}
							/>
						</Card>
					)}
				</ReactQueryWrapperBase>
			</div>
		</>
	);
};

export default WorkspaceSettings;
