import type { ButtonProps } from "@mdotm/mdotui/components";
import { Button } from "@mdotm/mdotui/components";
import { toClassListRecord } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";

function RoundedButton(props: Omit<ButtonProps, "palette" | "unstyled">): JSX.Element {
	return (
		<Button
			{...props}
			unstyled
			classList={{
				...toClassListRecord(props.classList),
				[`!bg-[${themeCSSVars.palette_N20}] hover:!bg-[${themeCSSVars.palette_P100}] hover:!text-[${themeCSSVars.palette_P600}] !text-[color:${themeCSSVars.palette_N500}] rounded-full p-2`]:
					true,
			}}
		>
			{props.children}
		</Button>
	);
}

export default RoundedButton;
